<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1708.09 363.57" style="enable-background:new 0 0 1708.09 363.57;" xml:space="preserve">
<g id="Canteiro">
	<g>
		<polygon class="st_aquisition_0" points="867.51,110.66 867.51,136.8 825.44,136.8 825.44,110.75 830.13,110.86 830.13,115.31 862.82,115.31 
			862.82,111.05 		"/>
		<path d="M868.29,137.58h-43.63v-27.63l6.25,0.14v4.43h31.13v-4.19l6.25-0.53V137.58z M826.22,136.02h40.5v-24.5l-3.12,0.26v4.32
			h-34.26v-4.47l-3.12-0.07V136.02z"/>
	</g>
	<g>
		<polygon class="st_aquisition_1" points="427.07,106.3 408.45,106.3 408.45,98.48 411.58,98.48 411.58,103.17 427.07,103.17 		"/>
	</g>
	<g>
		<polyline class="st_aquisition_2" points="1265.06,104.74 1282.12,104.74 1282.12,98.48 		"/>
		<polygon class="st_aquisition_1" points="1283.68,106.3 1265.06,106.3 1265.06,103.17 1280.55,103.17 1280.55,98.48 1283.68,98.48 		"/>
	</g>
	<g>
		<polygon class="st_aquisition_0" points="868.04,110.66 868.04,136.8 825.97,136.8 825.97,110.75 830.65,110.86 863.35,111.05 		"/>
		<path d="M868.82,137.58h-43.63v-27.63l5.49,0.13l32.68,0.19l5.46-0.46V137.58z M826.75,136.02h40.5v-24.5l-3.84,0.32l-32.76-0.19
			l-3.9-0.09V136.02z"/>
	</g>
</g>
<g id="Sub_x5F_base">
	<polygon class="st_aquisition_3" points="893.3,136.77 893.3,136.99 893.1,136.99 	"/>
	<g>
		<polygon class="st_aquisition_4" points="716.36,121.97 558.07,118.84 400.86,121.97 409.51,107.11 558.07,103.98 707.71,107.11 		"/>
		<path d="M399.48,122.77l9.58-16.44l0.44-0.01l148.56-3.13l150.11,3.14l9.58,16.44l-159.69-3.16L399.48,122.77z M558.05,118.05
			l156.93,3.1l-7.73-13.28l-149.2-3.12l-148.08,3.12l-7.73,13.28L558.05,118.05z"/>
	</g>
	<g>
		<polygon class="st_aquisition_5" points="724.92,136.82 558.07,133.69 392.3,136.82 400.64,121.97 558.07,118.84 716.57,121.97 		"/>
		<path d="M390.95,137.63l9.22-16.44l0.45-0.01l157.43-3.13l158.98,3.14l9.23,16.44l-168.21-3.15L390.95,137.63z M558.05,132.91
			l165.51,3.1l-7.46-13.28l-158.06-3.12l-156.95,3.12l-7.45,13.28L558.05,132.91z"/>
	</g>
	<g>
		<polygon class="st_aquisition_4" points="1291.67,121.97 1133.37,118.84 976.16,121.97 984.82,107.11 1133.37,103.98 1283.01,107.11 		"/>
		<path d="M974.78,122.77l9.58-16.44l0.44-0.01l148.56-3.13l150.11,3.14l9.58,16.44l-159.69-3.16L974.78,122.77z M1133.36,118.05
			l156.93,3.1l-7.73-13.28l-149.2-3.12l-148.08,3.12l-7.73,13.28L1133.36,118.05z"/>
	</g>
	<g>
		<polygon class="st_aquisition_5" points="1300.22,136.82 1133.37,133.69 967.61,136.82 975.94,121.97 1133.37,118.84 1291.88,121.97 		"/>
		<path d="M966.26,137.63l9.22-16.44l0.45-0.01l157.43-3.13l158.98,3.14l9.23,16.44l-168.21-3.15L966.26,137.63z M1133.36,132.91
			l165.51,3.1l-7.46-13.28l-158.06-3.12l-156.95,3.12l-7.45,13.28L1133.36,132.91z"/>
	</g>
</g>
<g id="Land">
	<g>
		<polygon class="st_aquisition_5" points="1003.64,106.03 975.49,136.63 975.34,136.8 715.75,136.8 715.62,136.8 688.36,106.03 825.44,110.71 
			825.44,119.63 868.13,119.63 868.13,110.71 		"/>
		<path d="M975.68,137.58H715.26l-28.69-32.4l139.65,4.77v8.89h41.12v-8.89l0.75-0.03l137.38-4.75L975.68,137.58z M715.97,136.02
			h259.02l26.81-29.14l-132.89,4.59v8.94h-44.25v-8.94l-134.5-4.6L715.97,136.02z"/>
	</g>
</g>
<g id="Asphalt_x5F_and_components">
	<g>
		<polygon points="688.63,102.76 557.85,99.63 427.07,102.76 427.07,106.67 557.85,103.54 688.63,106.67 		"/>
	</g>
	<g>
		<polygon points="1265.26,102.76 1134.48,99.63 1003.7,102.76 1003.7,106.67 1134.48,103.54 1265.26,106.67 		"/>
	</g>
</g>
<g id="Arrows">
</g>
<g id="Text">
	<g>
		<text transform="matrix(1 0 0 1 838.1065 282.445)" class="st_aquisition_6 st_aquisition_7">{{ paramSelected.values_params.earthwork.avg_earthwork_width }} m</text>
	</g>
	<text transform="matrix(1 0 0 1 414.5897 341.0913)" class="st_aquisition_6 st_aquisition_7">{{paramSelected.values_params.others.aquisition_offset}} m</text>
	<text transform="matrix(1 0 0 1 1268.0427 341.0913)" class="st_aquisition_6 st_aquisition_7">{{paramSelected.values_params.others.aquisition_offset}} m</text>
	<text transform="matrix(1 0 0 1 63.7964 279.2208)" class="st_aquisition_6 st_aquisition_7">{{ paramSelected.values_params.others.aquisition_clearance }} m</text>
	<text transform="matrix(1 0 0 1 1619.4252 279.2208)" class="st_aquisition_6 st_aquisition_7">{{ paramSelected.values_params.others.aquisition_clearance }} m</text>
</g>
<g id="Metal_x5F_guard" class="st_aquisition_8">
	<g>
		<g class="st_aquisition_9">
			<g>
				<g>
					<path class="st_aquisition_0" d="M844.3,94.45h-12.18c-0.11,0-0.19-0.09-0.19-0.19v-1.6c0-0.11,0.09-0.19,0.19-0.19h12.18
						c0.11,0,0.19,0.09,0.19,0.19v1.6C844.49,94.37,844.4,94.45,844.3,94.45z"/>
				</g>
				<g>
					<path d="M843.5,94.7h-10.58c-0.68,0-1.24-0.56-1.24-1.24s0.56-1.24,1.24-1.24h10.58c0.68,0,1.24,0.56,1.24,1.24
						S844.18,94.7,843.5,94.7z M832.92,92.72c-0.41,0-0.74,0.33-0.74,0.74s0.33,0.74,0.74,0.74h10.58c0.41,0,0.74-0.33,0.74-0.74
						s-0.33-0.74-0.74-0.74H832.92z"/>
				</g>
			</g>
			<g>
				<g>
					<polygon class="st_aquisition_0" points="834.24,93.46 829.61,88.17 834.24,82.22 838.87,82.22 834.24,88.17 838.87,93.46 834.24,100.07 
						838.87,105.36 834.24,105.36 829.52,100.07 					"/>
				</g>
				<g>
					<path d="M839.42,105.61h-5.29l-4.93-5.52l4.73-6.62l-4.64-5.3l4.83-6.21h5.26l-4.81,6.19l4.62,5.28l-4.63,6.61L839.42,105.61z
					M834.35,105.12h3.97l-4.4-5.03l4.63-6.61l-4.64-5.3l4.44-5.71h-4l-4.43,5.69l4.62,5.28l-4.72,6.61L834.35,105.12z"/>
				</g>
			</g>
			<g>
				<g>
					<rect x="839.2" y="81.89" class="st_aquisition_10" width="3.31" height="28.44"/>
				</g>
				<g>
					<path d="M842.75,110.57h-3.8V81.64h3.8V110.57z M839.45,110.08h2.81V82.14h-2.81V110.08z"/>
				</g>
			</g>
		</g>
		<g class="st_aquisition_9">
			<g>
				<g>
					<path class="st_aquisition_0" d="M848.41,94.45h12.18c0.11,0,0.19-0.09,0.19-0.19v-1.6c0-0.11-0.09-0.19-0.19-0.19h-12.18
						c-0.11,0-0.19,0.09-0.19,0.19v1.6C848.21,94.37,848.3,94.45,848.41,94.45z"/>
				</g>
				<g>
					<path d="M847.97,93.46c0-0.68,0.56-1.24,1.24-1.24h10.58c0.68,0,1.24,0.56,1.24,1.24s-0.56,1.24-1.24,1.24h-10.58
						C848.52,94.7,847.97,94.14,847.97,93.46z M849.21,92.72c-0.41,0-0.74,0.33-0.74,0.74s0.33,0.74,0.74,0.74h10.58
						c0.41,0,0.74-0.33,0.74-0.74s-0.33-0.74-0.74-0.74H849.21z"/>
				</g>
			</g>
			<g>
				<g>
					<polygon class="st_aquisition_0" points="858.46,93.46 863.09,88.17 858.46,82.22 853.84,82.22 858.46,88.17 853.84,93.46 858.46,100.07 
						853.84,105.36 858.46,105.36 863.19,100.07 					"/>
				</g>
				<g>
					<path d="M858.15,100.06l-4.63-6.61l4.62-5.28l-4.81-6.19h5.26l4.83,6.21l-4.64,5.3l4.73,6.62l-4.93,5.52h-5.29L858.15,100.06z
					M862.87,100.06l-4.72-6.61l4.62-5.28l-4.43-5.69h-4l4.44,5.71l-4.64,5.3l4.63,6.61l-4.4,5.03h3.97L862.87,100.06z"/>
				</g>
			</g>
			<g>
				<g>
					
						<rect x="850.2" y="81.89" transform="matrix(-1 -4.510937e-11 4.510937e-11 -1 1703.7031 192.2121)" class="st_aquisition_10" width="3.31" height="28.44"/>
				</g>
				<g>
					<path d="M849.95,81.64h3.8v28.93h-3.8V81.64z M853.26,82.14h-2.81v27.94h2.81V82.14z"/>
				</g>
			</g>
		</g>
	</g>
	<g class="st_aquisition_9">
		<g>
			<g>
				<path class="st_aquisition_0" d="M407.42,90.54h12.18c0.11,0,0.19-0.09,0.19-0.19v-1.6c0-0.11-0.09-0.19-0.19-0.19h-12.18
					c-0.11,0-0.19,0.09-0.19,0.19v1.6C407.23,90.46,407.32,90.54,407.42,90.54z"/>
			</g>
			<g>
				<path d="M406.98,89.55c0-0.68,0.56-1.24,1.24-1.24h10.58c0.68,0,1.24,0.56,1.24,1.24s-0.56,1.24-1.24,1.24h-10.58
					C407.54,90.79,406.98,90.23,406.98,89.55z M408.22,88.81c-0.41,0-0.74,0.33-0.74,0.74s0.33,0.74,0.74,0.74h10.58
					c0.41,0,0.74-0.33,0.74-0.74s-0.33-0.74-0.74-0.74H408.22z"/>
			</g>
		</g>
		<g>
			<g>
				<polygon class="st_aquisition_0" points="417.48,89.55 422.11,84.26 417.48,78.31 412.85,78.31 417.48,84.26 412.85,89.55 417.48,96.16 
					412.85,101.45 417.48,101.45 422.2,96.16 				"/>
			</g>
			<g>
				<path d="M417.17,96.15l-4.63-6.61l4.62-5.28l-4.81-6.19h5.26l4.83,6.21l-4.64,5.3l4.73,6.62l-4.93,5.52h-5.29L417.17,96.15z
				M421.89,96.15l-4.72-6.61l4.62-5.28l-4.43-5.69h-4l4.44,5.71l-4.64,5.3l4.63,6.61l-4.4,5.03h3.97L421.89,96.15z"/>
			</g>
		</g>
		<g>
			<g>
				
					<rect x="409.21" y="77.98" transform="matrix(-1 -4.400892e-11 4.400892e-11 -1 821.7347 184.3925)" class="st_aquisition_10" width="3.31" height="28.44"/>
			</g>
			<g>
				<path d="M408.97,77.73h3.8v28.93h-3.8V77.73z M412.27,78.23h-2.81v27.94h2.81V78.23z"/>
			</g>
		</g>
	</g>
	<g class="st_aquisition_9">
		<g>
			<g>
				<path class="st_aquisition_0" d="M1284.69,90.54h-12.18c-0.11,0-0.19-0.09-0.19-0.19v-1.6c0-0.11,0.09-0.19,0.19-0.19h12.18
					c0.11,0,0.19,0.09,0.19,0.19v1.6C1284.88,90.46,1284.8,90.54,1284.69,90.54z"/>
			</g>
			<g>
				<path d="M1283.89,90.79h-10.58c-0.68,0-1.24-0.56-1.24-1.24s0.56-1.24,1.24-1.24h10.58c0.68,0,1.24,0.56,1.24,1.24
					S1284.58,90.79,1283.89,90.79z M1273.31,88.81c-0.41,0-0.74,0.33-0.74,0.74s0.33,0.74,0.74,0.74h10.58
					c0.41,0,0.74-0.33,0.74-0.74s-0.33-0.74-0.74-0.74H1273.31z"/>
			</g>
		</g>
		<g>
			<g>
				<polygon class="st_aquisition_0" points="1274.63,89.55 1270,84.26 1274.63,78.31 1279.26,78.31 1274.63,84.26 1279.26,89.55 1274.63,96.16 
					1279.26,101.45 1274.63,101.45 1269.91,96.16 				"/>
			</g>
			<g>
				<path d="M1279.81,101.7h-5.29l-4.93-5.52l4.73-6.62l-4.64-5.3l4.83-6.21h5.26l-4.81,6.19l4.62,5.28l-4.63,6.61L1279.81,101.7z
				M1274.74,101.21h3.97l-4.4-5.03l4.63-6.61l-4.64-5.3l4.44-5.71h-4l-4.43,5.69l4.62,5.28l-4.72,6.61L1274.74,101.21z"/>
			</g>
		</g>
		<g>
			<g>
				<rect x="1279.59" y="77.98" class="st_aquisition_10" width="3.31" height="28.44"/>
			</g>
			<g>
				<path d="M1283.15,106.66h-3.8V77.73h3.8V106.66z M1279.84,106.17h2.81V78.23h-2.81V106.17z"/>
			</g>
		</g>
	</g>
	<g class="st_aquisition_9">
		<g>
			<g>
				<path class="st_aquisition_0" d="M985.01,90.57h12.18c0.11,0,0.19-0.09,0.19-0.19v-1.6c0-0.11-0.09-0.19-0.19-0.19h-12.18
					c-0.11,0-0.19,0.09-0.19,0.19v1.6C984.82,90.48,984.9,90.57,985.01,90.57z"/>
			</g>
			<g>
				<path d="M984.57,89.58c0-0.68,0.56-1.24,1.24-1.24h10.58c0.68,0,1.24,0.56,1.24,1.24s-0.56,1.24-1.24,1.24h-10.58
					C985.13,90.82,984.57,90.26,984.57,89.58z M985.81,88.83c-0.41,0-0.74,0.33-0.74,0.74s0.33,0.74,0.74,0.74h10.58
					c0.41,0,0.74-0.33,0.74-0.74s-0.33-0.74-0.74-0.74H985.81z"/>
			</g>
		</g>
		<g>
			<g>
				<polygon class="st_aquisition_0" points="995.07,89.58 999.7,84.29 995.07,78.33 990.44,78.33 995.07,84.29 990.44,89.58 995.07,96.19 
					990.44,101.48 995.07,101.48 999.79,96.19 				"/>
			</g>
			<g>
				<path d="M994.75,96.17l-4.63-6.61l4.62-5.28l-4.81-6.19h5.26l4.83,6.21l-4.64,5.3l4.73,6.62l-4.93,5.52h-5.29L994.75,96.17z
				M999.48,96.17l-4.72-6.61l4.62-5.28l-4.43-5.69h-4l4.44,5.71l-4.64,5.3l4.63,6.61l-4.4,5.03h3.97L999.48,96.17z"/>
			</g>
		</g>
		<g>
			<g>
				
					<rect x="986.8" y="78" transform="matrix(-1 -4.373403e-11 4.373403e-11 -1 1976.9117 184.4417)" class="st_aquisition_10" width="3.31" height="28.44"/>
			</g>
			<g>
				<path d="M986.55,77.75h3.8v28.93h-3.8V77.75z M989.86,78.25h-2.81v27.94h2.81V78.25z"/>
			</g>
		</g>
	</g>
	<g class="st_aquisition_9">
		<g>
			<g>
				<path class="st_aquisition_0" d="M707.43,90.55h-12.18c-0.11,0-0.19-0.09-0.19-0.19v-1.6c0-0.11,0.09-0.19,0.19-0.19h12.18
					c0.11,0,0.19,0.09,0.19,0.19v1.6C707.63,90.46,707.54,90.55,707.43,90.55z"/>
			</g>
			<g>
				<path d="M706.63,90.8h-10.58c-0.68,0-1.24-0.56-1.24-1.24s0.56-1.24,1.24-1.24h10.58c0.68,0,1.24,0.56,1.24,1.24
					S707.32,90.8,706.63,90.8z M696.05,88.81c-0.41,0-0.74,0.33-0.74,0.74s0.33,0.74,0.74,0.74h10.58c0.41,0,0.74-0.33,0.74-0.74
					s-0.33-0.74-0.74-0.74H696.05z"/>
			</g>
		</g>
		<g>
			<g>
				<polygon class="st_aquisition_0" points="697.37,89.56 692.75,84.27 697.37,78.32 702,78.32 697.37,84.27 702,89.56 697.37,96.17 
					702,101.46 697.37,101.46 692.65,96.17 				"/>
			</g>
			<g>
				<path d="M702.55,101.71h-5.29l-4.93-5.52l4.73-6.62l-4.64-5.3l4.83-6.21h5.26l-4.81,6.19l4.62,5.28l-4.63,6.61L702.55,101.71z
				M697.49,101.21h3.97l-4.4-5.03l4.63-6.61l-4.64-5.3l4.44-5.71h-4l-4.43,5.69l4.62,5.28l-4.72,6.61L697.49,101.21z"/>
			</g>
		</g>
		<g>
			<g>
				<rect x="702.33" y="77.98" class="st_aquisition_10" width="3.31" height="28.44"/>
			</g>
			<g>
				<path d="M705.89,106.67h-3.8V77.74h3.8V106.67z M702.58,106.17h2.81V78.23h-2.81V106.17z"/>
			</g>
		</g>
	</g>
</g>
<g id="Jerseys">
	<g>
		<polygon class="st_aquisition_0" points="409,106.22 426.06,106.22 426.06,99.58 420.21,94.1 417.86,90.19 416.3,78.46 408.87,78.46 		"/>
		<path d="M426.84,107h-18.62l-0.13-29.32h8.9l1.63,12.25l2.21,3.68l6.01,5.63V107z M409.78,105.44h15.5v-5.53l-5.73-5.41
			l-2.43-4.05l-1.49-11.21h-5.96L409.78,105.44z"/>
	</g>
	<g>
		<polygon class="st_aquisition_0" points="1283.28,106.22 1266.22,106.22 1266.22,99.58 1272.07,94.1 1274.41,90.19 1275.98,78.46 
			1283.41,78.46 		"/>
		<path d="M1284.06,107h-18.62v-7.77l6.01-5.63l2.21-3.68l1.63-12.25h8.9L1284.06,107z M1267.01,105.44h15.5l0.12-26.2h-5.96
			l-1.5,11.21l-2.57,4.22l-5.6,5.24V105.44z"/>
	</g>
	<g class="st_aquisition_8">
		<polygon class="st_aquisition_11" points="863.07,103.49 863.07,110.13 830.25,110.13 830.25,103.49 836.09,98.01 838.43,94.1 840,82.37 
			853.32,82.37 854.88,94.1 857.22,98.01 		"/>
		<path class="st_aquisition_9" d="M863.85,110.91h-34.38v-7.77l6.01-5.63l2.21-3.68l1.63-12.25H854l1.63,12.25l2.21,3.68l6.01,5.63V110.91z
		M831.03,109.35h31.25v-5.53l-5.73-5.41l-2.43-4.05l-1.49-11.21h-11.95l-1.49,11.21l-2.57,4.22l-5.59,5.24V109.35z"/>
	</g>
	<g>
		<polygon class="st_aquisition_0" points="986.59,106.25 1003.64,106.25 1003.64,99.6 997.8,94.13 995.45,90.22 993.89,78.49 986.46,78.49 		
			"/>
		<path d="M1004.43,107.03h-18.62l-0.14-29.32h8.9l1.63,12.25l2.21,3.68l6.01,5.63V107.03z M987.37,105.46h15.49v-5.53l-5.73-5.41
			l-2.43-4.05l-1.5-11.21h-5.96L987.37,105.46z"/>
	</g>
	<g>
		<polygon class="st_aquisition_0" points="705.86,106.23 688.8,106.23 688.8,99.58 694.64,94.11 696.99,90.2 698.55,78.47 705.98,78.47 		"/>
		<path d="M706.63,107.01h-18.62v-7.77l6.01-5.63l2.21-3.68l1.63-12.25h8.9L706.63,107.01z M689.58,105.45h15.5l0.12-26.2h-5.96
			l-1.49,11.21l-2.57,4.22l-5.6,5.24V105.45z"/>
	</g>
</g>
<g id="Grass">
	<g>
		<path class="st_aquisition_12" d="M395.07,139.45l-118.19,38.4c0,0-1.55-5.25-1.8-6.12c0.31,1.04,1.76-1.15,5.31-1.57
			c1.56-0.94,3.97-0.34,6.21-1.4c1.8-2.56,5.37-0.26,7.79-2.72c1.51-0.22,3.92,2.39,4.43-1.62c0.23-4.98,3.12-1.13,4.86-1.34
			c2.19,1.31,2.97-2.33,5.16-1.32c0.5-4.74,2.88-3.21,5.34-1.55c3.42,4.92,4.55,1.97,5.19-2.73c1.6-1.45,3.81-0.89,5.69-1.51
			c1.22-2.86,3.26-3,5.66-1.95c2.04-0.17,3.82-1.24,6.14-0.51c2.59,1.67,3.32-2.96,6.23-0.2c2.23,0.47,2.87-4.41,5.42-2.78
			c1.9-0.55,3.27-2.88,5.34-2.73c2.34,1.06,4.09,0.21,5.7-0.98c3.28-2.23,6.12-5.48,10.59-2.56c3.85,0.82,6.57-1.52,9.2-3.28
			c2.28-2.95,5.65-1.3,7.9-2.33c1.55-3.42,4.93,0.5,6.09-1.8c3.56-0.38,5.11-2.23,5.31-1.57
			C402.41,137.15,395.07,139.45,395.07,139.45z"/>
		<path d="M276.54,178.49l-1.95-6.61c0,0,0,0,0,0l0.96-0.28c-0.02-0.03-0.13-0.1-0.18-0.08c0.11-0.01,0.46-0.21,0.74-0.36
			c0.87-0.49,2.18-1.22,4.11-1.46c0.85-0.48,1.85-0.57,2.91-0.67c1.04-0.1,2.11-0.2,3.13-0.65c1.05-1.39,2.56-1.44,4.02-1.5
			c1.38-0.05,2.68-0.1,3.74-1.18l0.12-0.12l0.17-0.02c0.57-0.08,1.17,0.13,1.75,0.33c0.63,0.22,1.22,0.43,1.59,0.21
			c0.32-0.2,0.56-0.8,0.68-1.72c0.07-1.53,0.41-2.43,1.06-2.81c0.8-0.47,1.74,0.01,2.64,0.48c0.59,0.3,1.2,0.61,1.6,0.56l0.17-0.02
			l0.15,0.09c0.78,0.47,1.24,0.13,2.06-0.55c0.63-0.53,1.4-1.17,2.43-1c0.24-1.56,0.74-2.47,1.54-2.84c1.31-0.61,3.01,0.52,4.5,1.53
			l0.13,0.13c1.58,2.27,2.35,2.38,2.62,2.31c0.69-0.17,1.25-1.79,1.66-4.82l0.02-0.18l0.14-0.12c1.13-1.02,2.49-1.15,3.81-1.27
			c0.65-0.06,1.27-0.12,1.86-0.28c0.56-1.22,1.32-2.02,2.26-2.39c1.05-0.42,2.31-0.3,3.85,0.35c0.6-0.06,1.18-0.2,1.79-0.35
			c1.35-0.32,2.74-0.66,4.42-0.13l0.12,0.06c0.86,0.56,1.39,0.25,2.23-0.35c0.91-0.65,2.15-1.52,3.96,0.1
			c0.59,0.05,1.07-0.5,1.75-1.38c0.78-1.01,1.85-2.37,3.5-1.47c0.63-0.23,1.22-0.69,1.84-1.17c1.02-0.79,2.08-1.62,3.47-1.51
			l0.17,0.04c2.02,0.92,3.54,0.29,5.2-0.93c0.51-0.35,1-0.71,1.49-1.07c2.74-2.03,5.56-4.14,9.59-1.55c3.14,0.63,5.51-1,7.79-2.58
			c0.29-0.2,0.58-0.4,0.87-0.59c1.58-1.99,3.61-2.02,5.4-2.05c0.91-0.01,1.77-0.03,2.48-0.3c1.03-2.04,2.75-1.64,4.02-1.34
			c1.2,0.28,1.68,0.33,2-0.3l0.12-0.24l0.27-0.03c1.97-0.21,3.31-0.9,4.12-1.31c0.61-0.31,0.97-0.5,1.34-0.34
			c0.11,0.05,0.2,0.12,0.27,0.21c0.85,0.45,1.26,1,1.21,1.64c-0.13,1.75-4.18,3.1-4.99,3.36L276.54,178.49z M275.8,172.42l1.41,4.8
			l117.71-38.25c2.03-0.64,4.24-1.79,4.3-2.48c0.02-0.21-0.28-0.48-0.8-0.74l-0.09-0.04c-0.13,0.06-0.31,0.15-0.48,0.24
			c-0.83,0.43-2.2,1.13-4.21,1.39c-0.73,1.11-1.97,0.81-2.98,0.58c-1.42-0.33-2.32-0.47-2.96,0.96l-0.08,0.17l-0.17,0.08
			c-0.93,0.43-2,0.44-3.04,0.46c-1.74,0.03-3.38,0.05-4.67,1.72l-0.12,0.11c-0.31,0.2-0.61,0.42-0.92,0.63
			c-2.37,1.63-5.05,3.48-8.66,2.72l-0.17-0.07c-3.49-2.28-5.82-0.55-8.53,1.47c-0.5,0.37-1,0.74-1.51,1.09
			c-1.57,1.16-3.53,2.19-6.1,1.06c-0.99-0.06-1.81,0.62-2.7,1.31c-0.73,0.56-1.48,1.15-2.37,1.41l-0.22,0.06l-0.19-0.12
			c-0.92-0.59-1.41-0.13-2.38,1.12c-0.73,0.94-1.56,2.01-2.87,1.73l-0.14-0.03l-0.1-0.1c-1.27-1.2-1.88-0.77-2.81-0.12
			c-0.82,0.58-1.84,1.31-3.29,0.41c-1.38-0.42-2.57-0.14-3.82,0.17c-0.66,0.16-1.34,0.32-2.06,0.38l-0.13,0.01l-0.12-0.05
			c-1.33-0.58-2.38-0.71-3.19-0.39c-0.75,0.3-1.34,0.97-1.81,2.07l-0.09,0.21l-0.21,0.07c-0.75,0.25-1.53,0.32-2.29,0.39
			c-1.15,0.11-2.23,0.21-3.1,0.9c-0.5,3.6-1.2,5.19-2.4,5.47c-1.03,0.25-2.18-0.59-3.62-2.64c-1.2-0.8-2.65-1.77-3.44-1.4
			c-0.53,0.25-0.89,1.16-1.05,2.64l-0.07,0.69l-0.63-0.29c-0.75-0.34-1.22,0.01-1.99,0.66c-0.73,0.61-1.72,1.44-3.07,0.72
			c-0.63,0.02-1.33-0.34-2-0.68c-0.63-0.32-1.35-0.69-1.68-0.5c-0.18,0.1-0.5,0.51-0.56,2.03c-0.17,1.33-0.53,2.11-1.15,2.49
			c-0.77,0.47-1.66,0.16-2.44-0.11c-0.4-0.14-0.81-0.28-1.13-0.29c-1.31,1.23-2.88,1.29-4.27,1.34c-1.37,0.05-2.55,0.1-3.32,1.19
			l-0.08,0.11l-0.12,0.06c-1.22,0.58-2.47,0.69-3.58,0.79c-1.03,0.1-1.92,0.18-2.58,0.58l-0.2,0.07c-1.79,0.21-3.03,0.9-3.85,1.36
			C276.29,172.19,276.03,172.33,275.8,172.42z"/>
	</g>
	<g>
		<path class="st_aquisition_12" d="M1305.72,139.45l118.19,38.4c0,0,1.55-5.25,1.8-6.12c-0.31,1.04-1.76-1.15-5.31-1.57
			c-1.56-0.94-3.97-0.34-6.21-1.4c-1.8-2.56-5.37-0.26-7.79-2.72c-1.51-0.22-3.92,2.39-4.43-1.62c-0.23-4.98-3.12-1.13-4.86-1.34
			c-2.19,1.31-2.97-2.33-5.16-1.32c-0.5-4.74-2.88-3.21-5.34-1.55c-3.42,4.92-4.55,1.97-5.19-2.73c-1.6-1.45-3.81-0.89-5.69-1.51
			c-1.22-2.86-3.26-3-5.66-1.95c-2.04-0.17-3.82-1.24-6.14-0.51c-2.59,1.67-3.32-2.96-6.23-0.2c-2.23,0.47-2.87-4.41-5.42-2.78
			c-1.9-0.55-3.27-2.88-5.34-2.73c-2.34,1.06-4.09,0.21-5.7-0.98c-3.28-2.23-6.12-5.48-10.59-2.56c-3.85,0.82-6.57-1.52-9.2-3.28
			c-2.28-2.95-5.65-1.3-7.9-2.33c-1.55-3.42-4.93,0.5-6.09-1.8c-3.56-0.38-5.11-2.23-5.31-1.57
			C1298.37,137.15,1305.72,139.45,1305.72,139.45z"/>
		<path d="M1424.24,178.49l-118.68-38.56c-0.81-0.25-4.86-1.61-4.99-3.36c-0.05-0.64,0.36-1.19,1.21-1.64
			c0.07-0.09,0.16-0.17,0.27-0.21c0.37-0.16,0.73,0.03,1.34,0.34c0.8,0.41,2.15,1.1,4.12,1.31l0.27,0.03l0.12,0.24
			c0.32,0.63,0.8,0.58,2,0.3c1.27-0.3,2.99-0.7,4.02,1.34c0.71,0.27,1.57,0.29,2.48,0.3c1.79,0.03,3.82,0.06,5.4,2.05
			c0.29,0.19,0.58,0.39,0.87,0.59c2.29,1.58,4.65,3.21,7.79,2.58c4.03-2.59,6.86-0.48,9.59,1.55c0.49,0.36,0.97,0.72,1.47,1.06
			c1.67,1.23,3.19,1.86,5.21,0.94l0.17-0.04c1.39-0.1,2.45,0.72,3.47,1.51c0.62,0.48,1.21,0.94,1.84,1.17
			c1.66-0.9,2.72,0.46,3.5,1.47c0.69,0.88,1.17,1.43,1.75,1.38c1.8-1.63,3.04-0.75,3.96-0.1c0.85,0.6,1.37,0.91,2.23,0.35l0.12-0.06
			c1.67-0.53,3.07-0.2,4.42,0.13c0.61,0.15,1.19,0.29,1.79,0.35c1.53-0.65,2.79-0.77,3.85-0.35c0.94,0.37,1.7,1.18,2.26,2.39
			c0.58,0.16,1.2,0.22,1.86,0.28c1.32,0.12,2.68,0.25,3.81,1.27l0.14,0.12l0.02,0.18c0.41,3.03,0.97,4.66,1.66,4.82
			c0.27,0.06,1.04-0.04,2.62-2.31l0.13-0.13c1.57-1.05,3.18-2.14,4.5-1.53c0.8,0.37,1.3,1.29,1.54,2.84c1.03-0.17,1.79,0.47,2.43,1
			c0.82,0.68,1.28,1.02,2.06,0.55l0.15-0.09l0.17,0.02c0.4,0.05,1.01-0.26,1.6-0.56c0.9-0.46,1.83-0.94,2.64-0.48
			c0.65,0.38,0.99,1.29,1.06,2.85c0.12,0.89,0.35,1.48,0.67,1.68c0.36,0.22,0.96,0.02,1.59-0.21c0.58-0.2,1.18-0.41,1.74-0.33
			l0.17,0.02l0.12,0.12c1.06,1.08,2.36,1.12,3.74,1.18c1.46,0.06,2.97,0.11,4.02,1.5c1.03,0.46,2.1,0.56,3.13,0.65
			c1.06,0.1,2.07,0.19,2.91,0.67c1.93,0.24,3.24,0.98,4.11,1.46c0.28,0.16,0.63,0.35,0.74,0.36c-0.07,0-0.14,0.04-0.18,0.08
			l0.95,0.28c0,0,0,0,0,0L1424.24,178.49z M1302.46,135.7l-0.09,0.04c-0.53,0.26-0.82,0.53-0.8,0.74c0.05,0.7,2.26,1.85,4.3,2.48
			l0,0l117.7,38.24l1.42-4.8c-0.23-0.09-0.49-0.23-0.8-0.4c-0.82-0.46-2.05-1.15-3.85-1.36l-0.2-0.07
			c-0.67-0.41-1.55-0.49-2.58-0.58c-1.11-0.1-2.36-0.22-3.58-0.79l-0.12-0.06l-0.08-0.11c-0.77-1.09-1.95-1.14-3.32-1.19
			c-1.39-0.05-2.96-0.11-4.27-1.34c-0.32,0.01-0.73,0.15-1.13,0.29c-0.79,0.27-1.67,0.59-2.44,0.11c-0.61-0.38-0.98-1.16-1.14-2.45
			c-0.07-1.56-0.39-1.97-0.57-2.07c-0.33-0.19-1.05,0.17-1.68,0.5c-0.66,0.34-1.38,0.7-2,0.68c-1.36,0.72-2.34-0.11-3.07-0.72
			c-0.78-0.65-1.25-1-2-0.66l-0.63,0.29l-0.07-0.69c-0.16-1.48-0.52-2.4-1.04-2.64c-0.78-0.36-2.24,0.6-3.44,1.4
			c-1.44,2.05-2.59,2.88-3.62,2.64c-1.19-0.29-1.89-1.88-2.4-5.47c-0.86-0.7-1.95-0.8-3.1-0.9c-0.75-0.07-1.54-0.14-2.29-0.39
			l-0.21-0.07l-0.09-0.21c-0.47-1.1-1.06-1.77-1.81-2.07c-0.82-0.32-1.87-0.2-3.2,0.39l-0.12,0.05l-0.13-0.01
			c-0.72-0.06-1.4-0.22-2.06-0.38c-1.25-0.3-2.44-0.58-3.82-0.17c-1.44,0.89-2.46,0.17-3.29-0.41c-0.93-0.65-1.54-1.09-2.81,0.12
			l-0.1,0.1l-0.14,0.03c-1.32,0.28-2.14-0.79-2.88-1.73c-0.97-1.25-1.46-1.7-2.38-1.12l-0.19,0.12l-0.22-0.06
			c-0.89-0.26-1.65-0.84-2.37-1.41c-0.89-0.69-1.74-1.35-2.7-1.31c-2.57,1.12-4.54,0.09-6.12-1.08c-0.49-0.34-0.99-0.71-1.49-1.08
			c-2.7-2.01-5.04-3.75-8.53-1.47l-0.17,0.07c-3.6,0.77-6.29-1.09-8.66-2.72c-0.31-0.21-0.62-0.43-0.92-0.63l-0.12-0.11
			c-1.29-1.67-2.93-1.7-4.67-1.72c-1.04-0.02-2.12-0.03-3.04-0.46l-0.17-0.08l-0.08-0.17c-0.65-1.43-1.55-1.3-2.96-0.96
			c-1.01,0.24-2.25,0.53-2.98-0.58c-2.02-0.26-3.38-0.96-4.21-1.39C1302.77,135.86,1302.59,135.76,1302.46,135.7z M1425.23,171.61
			C1425.23,171.61,1425.23,171.61,1425.23,171.61L1425.23,171.61L1425.23,171.61z"/>
	</g>
	<g>
		<path class="st_aquisition_12" d="M867.85,110.54l136.2-5.19c0,0-0.08-0.22,0.16-0.54c0.02,0.43-2.5-2.13-6.35-1.7
			c-1.94-0.09-4.26,0.52-6.95,0.5c-2.73-0.64-5.7,0.78-9.04,0.27c-1.65,0.17-3.31,1.51-5.17,0.13c-1.87-1.79-3.63,0.1-5.52,0.3
			c-1.86,0.84-3.87-0.37-5.83,0.36c-2.07-1.65-4.07-0.7-6.09,0.3c-1.96,2.36-4.11,1.46-6.32-0.15c-2.15-0.27-4.28,0.29-6.45,0.37
			c-2.22-0.85-4.4-0.57-6.56,0.21c-2.19,0.27-4.4,0.17-6.58,0.81c-2.16,1.03-4.45-0.54-6.58,0.94c-2.18,0.54-4.45-1.14-6.58-0.13
			c-2.17,0.11-4.36-0.52-6.47-0.13c-2.1,0.77-4.2,0.74-6.29,0.57c-4.16-0.28-8.2-1.01-11.91,0.79c-3.76,0.93-7.37,0.51-10.69,0.3
			c-3.35-0.71-6.33,0.45-9.03,0.43c-2.74-1-5,0.99-6.96,0.33c-3.85,0.44-6.08,0.02-6.07,0.29
			C859.41,110.89,867.85,110.54,867.85,110.54z"/>
		<path d="M865.46,110.79c-1.92,0-3.59-0.14-3.68-0.61c-0.07-0.32,0.35-0.54,0.84-0.74l0.01-0.02c0.13-0.13,0.37-0.13,1.26-0.12
			c1.01,0.02,2.69,0.06,4.91-0.2l0.04,0l0.04,0.01c0.83,0.28,1.76,0.04,2.75-0.22c1.24-0.32,2.64-0.68,4.19-0.13c0,0,0,0,0.01,0
			c0.97,0,1.97-0.15,3.04-0.31c1.89-0.28,3.84-0.58,5.99-0.12c0.36,0.02,0.76,0.05,1.15,0.08c3.03,0.21,6.17,0.43,9.45-0.37
			c3.15-1.53,6.51-1.25,10.07-0.94c0.63,0.05,1.27,0.11,1.91,0.15c1.99,0.17,4.11,0.21,6.21-0.56c1.28-0.24,2.57-0.12,3.82,0
			c0.88,0.08,1.79,0.16,2.68,0.12c1.21-0.56,2.46-0.3,3.68-0.04c1,0.21,1.94,0.41,2.87,0.19c1.2-0.82,2.44-0.72,3.64-0.63
			c1.02,0.08,1.99,0.15,2.94-0.3c1.5-0.45,3-0.55,4.46-0.64c0.72-0.05,1.46-0.1,2.19-0.19c2.49-0.9,4.61-0.97,6.62-0.21
			c0.78-0.03,1.57-0.13,2.34-0.22c1.34-0.16,2.73-0.33,4.1-0.15l0.05,0.01l0.04,0.03c2.01,1.47,4.13,2.5,6.05,0.19l0.06-0.05
			c1.9-0.94,4.04-2.01,6.21-0.35c0.96-0.33,1.93-0.22,2.86-0.12c1,0.11,1.95,0.21,2.85-0.2l0.06-0.02c0.57-0.06,1.16-0.29,1.72-0.52
			c1.27-0.51,2.59-1.03,3.96,0.27c1.21,0.9,2.32,0.55,3.5,0.18c0.49-0.15,1.01-0.31,1.52-0.37l0.03,0l0.02,0
			c1.62,0.25,3.15,0.03,4.64-0.18c1.54-0.22,3-0.42,4.41-0.09c1.31,0.01,2.55-0.14,3.75-0.29c1.13-0.14,2.2-0.27,3.17-0.22
			c2.8-0.32,4.95,0.96,5.87,1.51c0.13,0.08,0.27,0.16,0.35,0.2l0.28-0.37l0.03,0.66l-0.09,0.08c-0.11,0.18-0.08,0.29-0.08,0.29
			l0.06,0.24l-0.24,0.02l-136.2,5.19C867.07,110.77,866.25,110.79,865.46,110.79z M862.19,110.11c0.43,0.28,3.21,0.34,5.65,0.24h0
			l136-5.18c0.01-0.06,0.02-0.13,0.05-0.2c-0.09-0.05-0.21-0.12-0.35-0.2c-0.96-0.57-2.97-1.76-5.66-1.46
			c-0.97-0.04-2.02,0.08-3.14,0.22c-1.15,0.14-2.47,0.29-3.84,0.28c-1.39-0.32-2.81-0.12-4.31,0.09c-1.5,0.21-3.06,0.43-4.73,0.18
			c-0.46,0.05-0.92,0.19-1.41,0.35c-1.16,0.36-2.48,0.77-3.87-0.26c-1.21-1.15-2.3-0.71-3.56-0.21c-0.58,0.23-1.17,0.47-1.79,0.54
			c-0.99,0.44-2.03,0.32-3.02,0.22c-0.92-0.1-1.88-0.2-2.79,0.13l-0.1,0.04l-0.09-0.07c-1.99-1.59-3.95-0.63-5.85,0.3
			c-2.12,2.51-4.48,1.36-6.52-0.12c-1.31-0.16-2.6,0-3.96,0.16c-0.79,0.09-1.6,0.19-2.41,0.22l-0.04,0l-0.04-0.01
			c-1.93-0.74-3.97-0.67-6.43,0.21c-0.78,0.1-1.53,0.15-2.25,0.2c-1.43,0.1-2.91,0.19-4.34,0.62c-1.01,0.49-2.08,0.41-3.11,0.33
			c-1.2-0.09-2.32-0.18-3.42,0.59l-0.06,0.03c-1.03,0.25-2.07,0.03-3.08-0.18c-1.21-0.25-2.36-0.49-3.46,0.03l-0.07,0.02
			c-0.93,0.05-1.86-0.04-2.77-0.12c-1.27-0.12-2.48-0.23-3.68-0.01c-2.13,0.79-4.31,0.74-6.34,0.57c-0.64-0.04-1.28-0.1-1.91-0.15
			c-3.5-0.3-6.81-0.58-9.9,0.92c-3.38,0.84-6.55,0.61-9.61,0.4c-0.4-0.03-0.79-0.06-1.18-0.08c-2.11-0.45-4.03-0.16-5.88,0.12
			c-1.09,0.16-2.12,0.31-3.14,0.31l-0.07-0.01c-1.45-0.53-2.74-0.19-3.99,0.13c-1.03,0.26-2,0.51-2.93,0.22
			c-2.22,0.25-3.91,0.22-4.92,0.2c-0.39-0.01-0.76-0.02-0.92,0l0,0.03l-0.13,0.05C862.4,109.94,862.25,110.05,862.19,110.11z"/>
	</g>
	<g>
		<path class="st_aquisition_12" d="M824.99,110.54l-136.2-5.19c0,0,0.08-0.22-0.16-0.54c-0.02,0.43,2.5-2.13,6.35-1.7
			c1.94-0.09,4.26,0.52,6.95,0.5c2.73-0.64,5.7,0.78,9.04,0.27c1.65,0.17,3.31,1.51,5.17,0.13c1.87-1.79,3.63,0.1,5.52,0.3
			c1.86,0.84,3.87-0.37,5.83,0.36c2.07-1.65,4.07-0.7,6.09,0.3c1.96,2.36,4.11,1.46,6.32-0.15c2.15-0.27,4.28,0.29,6.45,0.37
			c2.22-0.85,4.4-0.57,6.56,0.21c2.19,0.27,4.4,0.17,6.58,0.81c2.16,1.03,4.45-0.54,6.58,0.94c2.18,0.54,4.45-1.14,6.58-0.13
			c2.17,0.11,4.36-0.52,6.47-0.13c2.1,0.77,4.2,0.74,6.29,0.57c4.16-0.28,8.2-1.01,11.91,0.79c3.76,0.93,7.37,0.51,10.69,0.3
			c3.35-0.71,6.33,0.45,9.03,0.43c2.74-1,5,0.99,6.96,0.33c3.85,0.44,6.08,0.02,6.07,0.29
			C833.42,110.89,824.99,110.54,824.99,110.54z"/>
		<path d="M827.37,110.79c-0.78,0-1.61-0.02-2.39-0.06l-136.47-5.2l0.09-0.25l0,0c0,0,0.02-0.1-0.08-0.28l-0.08-0.05l-0.01-0.16
			l0.03-0.55l0.28,0.37c0.09-0.05,0.22-0.12,0.35-0.2c0.92-0.55,3.08-1.83,5.91-1.51c0.94-0.05,2.01,0.08,3.14,0.22
			c1.2,0.14,2.45,0.29,3.79,0.28c1.37-0.33,2.83-0.12,4.37,0.09c1.48,0.21,3.02,0.42,4.64,0.18l0.02,0l0.02,0
			c0.51,0.05,1.02,0.21,1.52,0.37c1.18,0.37,2.29,0.72,3.52-0.2c1.35-1.29,2.67-0.76,3.94-0.26c0.56,0.22,1.14,0.46,1.72,0.52
			l0.06,0.02c0.9,0.41,1.85,0.31,2.85,0.2c0.93-0.1,1.9-0.21,2.86,0.12c2.17-1.66,4.32-0.59,6.21,0.35l0.06,0.05
			c1.93,2.32,4.05,1.28,6.05-0.19l0.04-0.03l0.05-0.01c1.38-0.17,2.76-0.01,4.1,0.15c0.77,0.09,1.56,0.19,2.34,0.22
			c2.01-0.76,4.12-0.69,6.66,0.22c0.68,0.08,1.43,0.13,2.15,0.18c1.45,0.1,2.96,0.2,4.43,0.63c0.98,0.46,1.94,0.39,2.97,0.31
			c1.2-0.09,2.44-0.18,3.64,0.63c0.93,0.22,1.87,0.02,2.87-0.19c1.21-0.25,2.46-0.52,3.68,0.04c0.89,0.04,1.8-0.04,2.68-0.12
			c1.25-0.12,2.53-0.24,3.79,0c2.13,0.78,4.25,0.73,6.23,0.57c0.64-0.04,1.28-0.1,1.91-0.15c3.56-0.3,6.92-0.59,10.11,0.96
			c3.25,0.8,6.38,0.57,9.42,0.36c0.4-0.03,0.79-0.06,1.18-0.08c2.12-0.45,4.07-0.16,5.97,0.12c1.06,0.16,2.07,0.31,3.04,0.31
			c0,0,0,0,0.01,0c1.55-0.55,2.95-0.19,4.19,0.13c0.98,0.25,1.92,0.49,2.75,0.22l0.04-0.01l0.04,0c2.21,0.25,3.9,0.22,4.91,0.2
			c0.89-0.02,1.13-0.02,1.26,0.12l0.02,0.02c0.48,0.19,0.9,0.41,0.83,0.73C830.95,110.65,829.29,110.79,827.37,110.79z
			M688.99,105.16l136.01,5.18c2.44,0.1,5.22,0.04,5.65-0.24c-0.06-0.05-0.21-0.16-0.63-0.32l-0.13-0.05l0-0.03
			c-0.16-0.02-0.54-0.01-0.92,0c-1.01,0.02-2.7,0.06-4.92-0.2c-0.93,0.29-1.9,0.04-2.93-0.22c-1.25-0.32-2.54-0.65-3.99-0.13
			l-0.07,0.01c-1.02-0.01-2.04-0.15-3.14-0.31c-1.86-0.28-3.77-0.56-5.85-0.12c-0.42,0.03-0.81,0.06-1.21,0.08
			c-3.06,0.22-6.23,0.44-9.57-0.38c-3.13-1.51-6.44-1.23-9.94-0.93c-0.63,0.05-1.27,0.11-1.91,0.15c-2.03,0.17-4.2,0.22-6.37-0.58
			c-1.17-0.21-2.37-0.1-3.65,0.02c-0.91,0.08-1.84,0.17-2.77,0.12l-0.07-0.02c-1.11-0.53-2.25-0.28-3.46-0.03
			c-1.01,0.21-2.05,0.43-3.08,0.18l-0.06-0.03c-1.09-0.76-2.22-0.68-3.42-0.59c-1.03,0.08-2.09,0.16-3.13-0.34
			c-1.4-0.41-2.88-0.51-4.32-0.61c-0.72-0.05-1.47-0.1-2.21-0.19c-2.5-0.89-4.54-0.96-6.47-0.22l-0.04,0.01l-0.04,0
			c-0.81-0.03-1.62-0.13-2.41-0.22c-1.36-0.16-2.65-0.32-3.96-0.16c-2.04,1.48-4.4,2.64-6.52,0.12c-1.89-0.94-3.85-1.9-5.85-0.3
			l-0.09,0.07l-0.1-0.04c-0.91-0.34-1.87-0.23-2.79-0.13c-1,0.11-2.03,0.22-3.02-0.22c-0.62-0.07-1.21-0.31-1.79-0.54
			c-1.26-0.5-2.36-0.94-3.55,0.19c-1.4,1.05-2.72,0.64-3.88,0.27c-0.49-0.15-0.95-0.3-1.41-0.35c-1.67,0.25-3.22,0.03-4.73-0.18
			c-1.5-0.21-2.93-0.41-4.27-0.1c-1.43,0.02-2.73-0.14-3.88-0.28c-1.11-0.13-2.16-0.26-3.11-0.22c-2.73-0.3-4.73,0.89-5.69,1.46
			c-0.15,0.09-0.26,0.15-0.35,0.2C688.97,105.03,688.98,105.1,688.99,105.16z"/>
	</g>
</g>
<g id="Lines">
</g>
<g id="Car">
	<g>
		<g>
			<path class="st_aquisition_13" d="M512.18,98.11c0,1.68-1.38,3.06-3.06,3.06h-2.96c-1.68,0-3.06-1.38-3.06-3.06V85.57
				c0-1.68,1.38-3.06,3.06-3.06h2.96c1.68,0,3.06,1.38,3.06,3.06V98.11z"/>
			<path class="st_aquisition_13" d="M572.32,98.11c0,1.68-1.38,3.06-3.06,3.06h-2.96c-1.68,0-3.06-1.38-3.06-3.06V85.57
				c0-1.68,1.38-3.06,3.06-3.06h2.96c1.68,0,3.06,1.38,3.06,3.06V98.11z"/>
		</g>
		<g>
			<path class="st_aquisition_14" d="M568.72,69.99c0,0-5.31-14.3-8.99-17.56c-3.47-3.08-11.66-4.35-21.15-4.48v-0.01c-0.29,0-0.57,0-0.86,0.01
				c-0.29,0-0.57-0.01-0.86-0.01v0.01c-9.49,0.13-17.68,1.4-21.15,4.48c-3.68,3.27-8.99,17.56-8.99,17.56
				c-8.06,0-2.29,19.34-0.49,24.84c0.28,0.86,1.09,1.45,2,1.46l28.63,0.24v0.01l0.86-0.01l0.86,0.01v-0.01l28.63-0.24
				c0.91-0.01,1.71-0.59,2-1.46C571.01,89.33,576.78,69.99,568.72,69.99z"/>
			<g>
				<path class="st_aquisition_15" d="M555.85,54.27c-4.6-3.37-18.13-3.68-18.13-3.68s-13.54,0.31-18.13,3.68c-4.6,3.37-10.11,15.42-10.11,15.42
					h28.24h28.24C565.96,69.69,560.45,57.64,555.85,54.27z"/>
				<g>
					<g>
						<g>
							<path class="st_aquisition_2" d="M516.99,75.89c0,1.56-1.04,2.83-2.32,2.83h-6.18c-1.28,0-2.32-1.27-2.32-2.83l0,0
								c0-1.56,1.04-2.83,2.32-2.83h6.18C515.95,73.06,516.99,74.32,516.99,75.89L516.99,75.89z"/>
							<path class="st_aquisition_2" d="M569.28,75.89c0,1.56-1.04,2.83-2.32,2.83h-6.18c-1.28,0-2.32-1.27-2.32-2.83l0,0
								c0-1.56,1.04-2.83,2.32-2.83h6.18C568.24,73.06,569.28,74.32,569.28,75.89L569.28,75.89z"/>
						</g>
						<g>
							<path class="st_aquisition_16" d="M516.99,80.05c0,0.3-1.04,0.54-2.32,0.54h-6.18c-1.28,0-2.32-0.24-2.32-0.54l0,0
								c0-0.3,1.04-0.54,2.32-0.54h6.18C515.95,79.52,516.99,79.76,516.99,80.05L516.99,80.05z"/>
							<path class="st_aquisition_16" d="M569.28,80.05c0,0.3-1.04,0.54-2.32,0.54h-6.18c-1.28,0-2.32-0.24-2.32-0.54l0,0
								c0-0.3,1.04-0.54,2.32-0.54h6.18C568.24,79.52,569.28,79.76,569.28,80.05L569.28,80.05z"/>
						</g>
						<path class="st_aquisition_17" d="M554.88,79.36c0,2.42-1.38,4.4-3.06,4.4h-28.18c-1.68,0-3.06-1.98-3.06-4.4s1.38-4.4,3.06-4.4h28.18
							C553.5,74.96,554.88,76.94,554.88,79.36z"/>
						<rect x="527.92" y="86.51" class="st_aquisition_2" width="19.61" height="4.93"/>
						<rect x="528.56" y="87.08" class="st_aquisition_18" width="1.02" height="3.75"/>
					</g>
					<g>
						<path class="st_aquisition_2" d="M514.89,92.87c0,0.34-0.28,0.61-0.61,0.61h-4.19c-0.34,0-0.61-0.28-0.61-0.61v-2.06
							c0-0.34,0.28-0.61,0.61-0.61h4.19c0.34,0,0.61,0.28,0.61,0.61V92.87z"/>
						<path class="st_aquisition_2" d="M566.57,92.87c0,0.34-0.28,0.61-0.61,0.61h-4.19c-0.34,0-0.61-0.28-0.61-0.61v-2.06
							c0-0.34,0.28-0.61,0.61-0.61h4.19c0.34,0,0.61,0.28,0.61,0.61V92.87z"/>
					</g>
				</g>
				<g>
					<path class="st_aquisition_13" d="M509.12,68.9c0,0.67-0.55,1.23-1.23,1.23h-4.48c-0.67,0-1.23-0.55-1.23-1.23v-0.87
						c0-0.67,0.55-1.23,1.23-1.23h4.48c0.67,0,1.23,0.55,1.23,1.23V68.9z"/>
					<path class="st_aquisition_13" d="M573.23,68.9c0,0.67-0.55,1.23-1.23,1.23h-4.48c-0.67,0-1.23-0.55-1.23-1.23v-0.87
						c0-0.67,0.55-1.23,1.23-1.23H572c0.67,0,1.23,0.55,1.23,1.23V68.9z"/>
				</g>
			</g>
		</g>
	</g>
	<g>
		<g>
			<line class="st_aquisition_19" x1="0.5" y1="251.89" x2="151.96" y2="251.89"/>
			<g>
				<rect x="0" y="248.64" width="1" height="6.5"/>
			</g>
			<g>
				<rect x="151.46" y="248.64" width="1" height="6.5"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<line class="st_aquisition_19" x1="1556.13" y1="251.89" x2="1707.59" y2="251.89"/>
			<g>
				<rect x="1555.63" y="248.64" width="1" height="6.5"/>
			</g>
			<g>
				<rect x="1707.09" y="248.64" width="1" height="6.5"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<line class="st_aquisition_19" x1="0.5" y1="303.4" x2="853.54" y2="303.4"/>
			<g>
				<rect x="0" y="300.15" width="1" height="6.5"/>
			</g>
			<g>
				<rect x="853.04" y="300.15" width="1" height="6.5"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<line class="st_aquisition_19" x1="853.36" y1="303.4" x2="1707.59" y2="303.4"/>
			<g>
				<rect x="852.86" y="300.15" width="1" height="6.5"/>
			</g>
			<g>
				<rect x="1707.09" y="300.15" width="1" height="6.5"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<line class="st_aquisition_19" x1="276.3" y1="249.77" x2="1424.49" y2="249.77"/>
			<g>
				<rect x="275.8" y="246.52" width="1" height="6.5"/>
			</g>
			<g>
				<rect x="1423.99" y="246.52" width="1" height="6.5"/>
			</g>
		</g>
	</g>
	<polygon class="st_aquisition_20" points="1708.09,222.17 0,222.17 0,178.49 277.21,177.22 399.21,136.49 580.87,133.64 805.13,136.99 
		893.3,136.77 1087.65,133.02 1301.57,136.49 1423.57,177.22 1708.09,177.22 	"/>
</g>
</svg>

</template>
<script>
export default {
  name: "SvgPlatormAquisition2LaneJersey2",
  props: {
    paramSelected: undefined,
  },
};
</script>
<style scoped>
	.st_aquisition_0{fill:#686868;}
	.st_aquisition_1{fill:#7C7C7C;}
	.st_aquisition_2{fill:#FFFFFF;}
	.st_aquisition_3{fill:#2D190F;stroke:#000000;stroke-width:2;stroke-miterlimit:10;}
	.st_aquisition_4{fill:#DD8F64;}
	.st_aquisition_5{fill:#7C503A;}
	.st_aquisition_6{font-family:'MyriadPro-Regular';}
	.st_aquisition_7{font-size:12px;}
	.st_aquisition_8{display:none;}
	.st_aquisition_9{display:inline;}
	.st_aquisition_10{fill:#72502F;}
	.st_aquisition_11{display:inline;fill:#686868;}
	.st_aquisition_12{fill:#8FCE57;}
	.st_aquisition_13{fill:#42454C;}
	.st_aquisition_14{fill:#2B2B2B;}
	.st_aquisition_15{fill:#383847;}
	.st_aquisition_16{fill:#F9E873;}
	.st_aquisition_17{fill:#595959;}
	.st_aquisition_18{fill:#174EFF;}
	.st_aquisition_19{fill:none;stroke:#000000;stroke-miterlimit:10;}
	.st_aquisition_20{fill:#4C3125;stroke:#000000;stroke-miterlimit:10;}
</style>
