<template>
  <div style="overflow-x: hidden; overflow-y: hidden">
    <v-row>
      <v-col style="font-size: 20px; text-align: center;" id="ValuesParamsPlatformSVG1Title">
        <strong> {{ selectedLanguage['platform1Lane_component_text1'] }} </strong>
      </v-col>
    </v-row>
    <br />
    <SvgPlatformStructure2Lane  v-if="params.central_barrier==this.selectedLanguage['platform1Lane_component_text2'] && params.side_barrier==this.selectedLanguage['platform1Lane_component_text2']" :paramSelected="paramSelected" id="ValuesParamsPlatformSVG1"/>
    <SvgPlatformStructure2LaneJersey  v-else-if="currentCalc.values_params.platform.median_width <= 5 && params.central_barrier==this.selectedLanguage['platform1Lane_component_text3'] && params.side_barrier==this.selectedLanguage['platform1Lane_component_text3']" :paramSelected="paramSelected" id="ValuesParamsPlatformSVG1"/>
    <SvgPlatformStructure2LaneMetalGuard  v-else-if="currentCalc.values_params.platform.median_width <= 5 && params.central_barrier==this.selectedLanguage['platform1Lane_component_text4'] && params.side_barrier==this.selectedLanguage['platform1Lane_component_text4']" :paramSelected="paramSelected" id="ValuesParamsPlatformSVG1"/>
    <SvgPlatformStructure2LaneJersey2  v-else-if="currentCalc.values_params.platform.median_width > 5 && params.central_barrier==this.selectedLanguage['platform1Lane_component_text3'] && params.side_barrier==this.selectedLanguage['platform1Lane_component_text3']" :paramSelected="paramSelected" id="ValuesParamsPlatformSVG1"/>
    <SvgPlatformStructure2LaneMetalGuard2  v-else-if="currentCalc.values_params.platform.median_width > 5 && params.central_barrier==this.selectedLanguage['platform1Lane_component_text4'] && params.side_barrier==this.selectedLanguage['platform1Lane_component_text4']" :paramSelected="paramSelected" id="ValuesParamsPlatformSVG1"/>

    <SvgPlatformStructure_2_lanes_N_M  v-else-if="currentCalc.values_params.platform.median_width <= 5 && params.central_barrier==this.selectedLanguage['platform1Lane_component_text4'] && params.side_barrier==this.selectedLanguage['platform1Lane_component_text2']" :paramSelected="paramSelected" id="ValuesParamsPlatformSVG1"/>
    <SvgPlatformStructure_2_lanes_N_J  v-else-if="currentCalc.values_params.platform.median_width <= 5 && params.central_barrier==this.selectedLanguage['platform1Lane_component_text3'] && params.side_barrier==this.selectedLanguage['platform1Lane_component_text2']" :paramSelected="paramSelected" id="ValuesParamsPlatformSVG1"/>
    <SvgPlatformStructure_2_lanes_N_M_2  v-else-if="currentCalc.values_params.platform.median_width > 5 && params.central_barrier==this.selectedLanguage['platform1Lane_component_text4'] && params.side_barrier==this.selectedLanguage['platform1Lane_component_text2']" :paramSelected="paramSelected" id="ValuesParamsPlatformSVG1"/>
    <SvgPlatformStructure_2_lanes_N_J_2  v-else-if="currentCalc.values_params.platform.median_width > 5 && params.central_barrier==this.selectedLanguage['platform1Lane_component_text3'] && params.side_barrier==this.selectedLanguage['platform1Lane_component_text2']" :paramSelected="paramSelected" id="ValuesParamsPlatformSVG1"/>

    <SvgPlatformStructure_2_lanes_J_M  v-else-if="currentCalc.values_params.platform.median_width <= 5 && params.central_barrier==this.selectedLanguage['platform1Lane_component_text4'] && params.side_barrier==this.selectedLanguage['platform1Lane_component_text3']" :paramSelected="paramSelected" id="ValuesParamsPlatformSVG1"/>
    <SvgPlatformStructure_2_lanes_M_J  v-else-if="currentCalc.values_params.platform.median_width <= 5 && params.central_barrier==this.selectedLanguage['platform1Lane_component_text3'] && params.side_barrier==this.selectedLanguage['platform1Lane_component_text4']" :paramSelected="paramSelected" id="ValuesParamsPlatformSVG1"/>
    <SvgPlatformStructure_2_lanes_J_M_2  v-else-if="currentCalc.values_params.platform.median_width > 5 && params.central_barrier==this.selectedLanguage['platform1Lane_component_text4'] && params.side_barrier==this.selectedLanguage['platform1Lane_component_text3']" :paramSelected="paramSelected" id="ValuesParamsPlatformSVG1"/>
    <SvgPlatformStructure_2_lanes_M_J_2  v-else-if="currentCalc.values_params.platform.median_width > 5 && params.central_barrier==this.selectedLanguage['platform1Lane_component_text3'] && params.side_barrier==this.selectedLanguage['platform1Lane_component_text4']" :paramSelected="paramSelected" id="ValuesParamsPlatformSVG1"/>

    <SvgPlatformStructure_2_lanes_J_N  v-else-if="params.central_barrier==this.selectedLanguage['platform1Lane_component_text2'] && params.side_barrier==this.selectedLanguage['platform1Lane_component_text3']" :paramSelected="paramSelected" id="ValuesParamsPlatformSVG1"/>
    <SvgPlatformStructure_2_lanes_M_N  v-else-if="params.central_barrier==this.selectedLanguage['platform1Lane_component_text2'] && params.side_barrier==this.selectedLanguage['platform1Lane_component_text4']" :paramSelected="paramSelected" id="ValuesParamsPlatformSVG1"/>

    <v-row>
      <v-col class="formCardStyle">
        <v-row id="ValuesParamsPlatformSholder">
          <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_component_text5'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                paramSelected.values_params.platform.sholder
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
        <v-row id="ValuesParamsSuperstructureRevestmentDensity">
          <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_component_text6'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="paramSelected.values_params.superstructure.revestment_density"
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> t/m³ </v-col>
        </v-row>
        <v-row id="ValuesParamsPlatformLane">
          <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_component_text7'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                paramSelected.values_params.platform.lane
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row id="ValuesParamsSuperstructureFirstAsphaltLayerDepth">
      <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_component_text8'] }} </v-col>
      <v-col style="align-self: center">
        <v-text-field
          dense
          hide-details
          class="mb-2"
          clearable
          outlined
          v-model.number="
            paramSelected.values_params.superstructure.first_asphalt_layer_depth
          "
          style="border-radius: 10px !important"
        />
      </v-col>
      <v-col style="align-self: center"> m </v-col>
    </v-row>
    <v-row id="ValuesParamsSuperstructureSecondAsphaltLayerDepth">
      <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_component_text9'] }} </v-col>
      <v-col style="align-self: center">
        <v-text-field
          dense
          hide-details
          class="mb-2"
          clearable
          outlined
          v-model.number="
            paramSelected.values_params.superstructure.second_asphalt_layer_depth
          "
          style="border-radius: 10px !important"
        />
      </v-col>
      <v-col style="align-self: center"> m </v-col>
    </v-row>
    <v-row id="ValuesParamsSuperstructureBaseHeight">
      <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_component_text10'] }} </v-col>
      <v-col style="align-self: center">
        <v-text-field
          dense
          hide-details
          class="mb-2"
          clearable
          outlined
          v-model.number="
            paramSelected.values_params.superstructure.base_height
          "
          style="border-radius: 10px !important"
        />
      </v-col>
      <v-col style="align-self: center"> m </v-col>
    </v-row>
    <v-row id="ValuesParamsSuperstructureSubbaseHeight">
      <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_component_text11'] }} </v-col>
      <v-col style="align-self: center">
        <v-text-field
          dense
          hide-details
          class="mb-2"
          clearable
          outlined
          v-model.number="
            paramSelected.values_params.superstructure.subbase_height
          "
          style="border-radius: 10px !important"
        />
      </v-col>
      <v-col style="align-self: center"> m </v-col>
    </v-row>
    <v-row id="ValuesParamsPlatformMedianWidth">
      <v-col style="align-self: center"> {{ selectedLanguage['platform1Lane_component_text12'] }} </v-col>
      <v-col style="align-self: center">
        <v-text-field
          dense
          hide-details
          class="mb-2"
          clearable
          outlined
          v-model.number="
            paramSelected.values_params.platform.median_width
          "
          style="border-radius: 10px !important"
          @onchange="callWatcher()"
        />
      </v-col>
      <v-col style="align-self: center"> m </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SvgPlatformStructure2Lane from "@/components/SvgComponents/roadwayDefaults/platformStructure/SvgPlatformStructure2Lane.vue";
import SvgPlatformStructure2LaneJersey from "@/components/SvgComponents/roadwayDefaults/platformStructure/SvgPlatformStructure2LaneJersey.vue";
import SvgPlatformStructure2LaneMetalGuard from "@/components/SvgComponents/roadwayDefaults/platformStructure/SvgPlatformStructure2LaneMetalGuard.vue";
import SvgPlatformStructure2LaneJersey2 from "@/components/SvgComponents/roadwayDefaults/platformStructure/SvgPlatformStructure2LaneJersey2.vue";
import SvgPlatformStructure2LaneMetalGuard2 from "@/components/SvgComponents/roadwayDefaults/platformStructure/SvgPlatformStructure2LaneMetalGuard2.vue";
import SvgPlatformStructure_2_lanes_M_J from "@/components/SvgComponents/roadwayDefaults/platformStructure/SvgPlatformStructure_2_lanes_M_J.vue";
import SvgPlatformStructure_2_lanes_J_M from "@/components/SvgComponents/roadwayDefaults/platformStructure/SvgPlatformStructure_2_lanes_J_M.vue";
import SvgPlatformStructure_2_lanes_M_J_2 from "@/components/SvgComponents/roadwayDefaults/platformStructure/SvgPlatformStructure_2_lanes_M_J_2.vue";
import SvgPlatformStructure_2_lanes_J_M_2 from "@/components/SvgComponents/roadwayDefaults/platformStructure/SvgPlatformStructure_2_lanes_J_M_2.vue";
import SvgPlatformStructure_2_lanes_N_J from "@/components/SvgComponents/roadwayDefaults/platformStructure/SvgPlatformStructure_2_lanes_N_J.vue";
import SvgPlatformStructure_2_lanes_N_M from "@/components/SvgComponents/roadwayDefaults/platformStructure/SvgPlatformStructure_2_lanes_N_M.vue";
import SvgPlatformStructure_2_lanes_N_J_2 from "@/components/SvgComponents/roadwayDefaults/platformStructure/SvgPlatformStructure_2_lanes_N_J_2.vue";
import SvgPlatformStructure_2_lanes_N_M_2 from "@/components/SvgComponents/roadwayDefaults/platformStructure/SvgPlatformStructure_2_lanes_N_M_2.vue";
import SvgPlatformStructure_2_lanes_M_N from "@/components/SvgComponents/roadwayDefaults/platformStructure/SvgPlatformStructure_2_lanes_M_N.vue";
import SvgPlatformStructure_2_lanes_J_N from "@/components/SvgComponents/roadwayDefaults/platformStructure/SvgPlatformStructure_2_lanes_J_N.vue";
import { portuguese } from "../../../../languages/pt-br";
import { english } from "../../../../languages/en";

export default {
  props: {
    params: {
      type: Object,
    }
  },
  components: {
    SvgPlatformStructure2Lane,
    SvgPlatformStructure2LaneJersey,
    SvgPlatformStructure2LaneMetalGuard,
    SvgPlatformStructure2LaneJersey2,
    SvgPlatformStructure2LaneMetalGuard2,
    SvgPlatformStructure_2_lanes_M_J,
    SvgPlatformStructure_2_lanes_J_M,
    SvgPlatformStructure_2_lanes_M_J_2,
    SvgPlatformStructure_2_lanes_J_M_2,
    SvgPlatformStructure_2_lanes_N_J,
    SvgPlatformStructure_2_lanes_N_M,
    SvgPlatformStructure_2_lanes_N_J_2,
    SvgPlatformStructure_2_lanes_N_M_2,
    SvgPlatformStructure_2_lanes_M_N,
    SvgPlatformStructure_2_lanes_J_N,
  },
  computed: {
    ...mapGetters(["paramSelected","profile"]),
    selectedLanguage() {
        if (this.profile['language'] === 'en') {
          return english;
        } else {
          return portuguese;
        }
      },
    platformWidth: function () {
      let slope_width =
        this.paramSelected.values_params.superstructure.left_rail_ballast_height *
        this.paramSelected.values_params.superstructure.ballast_slope;
      return (
        slope_width * 4 +
        this.paramSelected.values_params.superstructure.ballast_width * 2 +
        this.paramSelected.values_params.platform.leftover * 2 +
        this.paramSelected.values_params.platform.median_width
      ).toFixed(2);
    },
  },
  data() {
    return {
      side: ["Both", "Right", "Left"],
      side_selected: "Both",
    };
  },
  methods: {
    callWatcher(){
      this.$emit('callWatcher')
    }
  },
};
</script>

<style scoped>
.cls-1 {
  fill: #c69c6d;
}
.cls-2 {
  fill: #ccc;
}
.cls-3 {
  fill: #666;
}
.cls-4 {
  fill: #3d3d3d;
}
.cls-5 {
  fill: none;
}
.cls-5,
.cls-7 {
  stroke: #000;
  stroke-miterlimit: 10;
  stroke-width: 0.5px;
}
.cls-6 {
  font-size: 12px;
  font-family: Roboto-Bold, Roboto Bold;
  font-weight: 700;
}
</style>