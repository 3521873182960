<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 309.63 104.38" style="enable-background:new 0 0 309.63 104.38;" xml:space="preserve">

<g id="Estrutura">
	<path class="st_bridge_0" d="M303.49,76.3"/>
	<path class="st_bridge_0" d="M10.21,33.92"/>
	<polygon class="st_bridge_1" points="14.37,56.99 14.37,56.99 42.53,76.47 42.53,76.47 155.81,76.25 155.81,76.25 270,76.25 270,76.25 
		296.37,58.25 296.37,58.25 296.33,34.2 296.33,34.2 162.3,34.13 148.56,34.13 14.37,34.06 14.37,34.06 	"/>
</g>
<g id="Medidas">
	<line class="st_bridge_2" x1="14.37" y1="88.66" x2="14.37" y2="97.88"/>
	<line class="st_bridge_2" x1="296.87" y1="88.66" x2="296.87" y2="97.42"/>
	<text transform="matrix(1 0 0 1 143.8332 91.1274)" class="st_bridge_3 st_bridge_4">{{paramSelected.values_params.structure.bridge_2lanes_width}}m</text>
	<g>
		<g>
			<line class="st_bridge_2" x1="15.94" y1="93.86" x2="295.3" y2="93.24"/>
			<g>
				<polygon points="16.94,94.71 16.09,93.86 16.93,93.01 16.21,93.01 15.37,93.86 16.22,94.71 				"/>
			</g>
			<g>
				<polygon points="294.3,94.09 295.15,93.24 294.3,92.39 295.02,92.39 295.87,93.24 295.02,94.09 				"/>
			</g>
		</g>
	</g>
</g>
<g id="Canteiro">
	<g>
		<polygon class="st_bridge_5" points="22.2,35.31 14.37,35.31 14.37,32.03 15.68,32.03 15.68,34 22.2,34 		"/>
	</g>
	<g>
		<polygon class="st_bridge_5" points="296.33,35.31 288.5,35.31 288.5,34 295.02,34 295.02,32.03 296.33,32.03 		"/>
	</g>
</g>
<g id="Sub_x5F_base">
</g>
<g id="Land">
</g>
<g id="Asphalt_x5F_and_components">
	<rect x="22.2" y="33.8" width="266.31" height="1.88"/>
</g>
<g id="Grass">
</g>
<g id="Car">
</g>
<g id="Metal_x5F_guard" class="st_bridge_6">
	<g>
		<g class="st_bridge_7">
			<g>
				<g>
					<path class="st_bridge_8" d="M153.54,28.89h-4.46c-0.23,0-0.41-0.18-0.41-0.41v-0.02c0-0.23,0.18-0.41,0.41-0.41h4.46
						c0.23,0,0.41,0.18,0.41,0.41v0.02C153.95,28.71,153.76,28.89,153.54,28.89z"/>
				</g>
				<g>
					<path d="M153.53,29h-4.44c-0.29,0-0.52-0.23-0.52-0.52s0.23-0.52,0.52-0.52h4.44c0.29,0,0.52,0.23,0.52,0.52
						S153.82,29,153.53,29z M149.09,28.17c-0.17,0-0.31,0.14-0.31,0.31s0.14,0.31,0.31,0.31h4.44c0.17,0,0.31-0.14,0.31-0.31
						s-0.14-0.31-0.31-0.31H149.09z"/>
				</g>
			</g>
			<g>
				<g>
					<polygon class="st_bridge_8" points="149.64,28.48 147.7,26.26 149.64,23.76 151.59,23.76 149.64,26.26 151.59,28.48 149.64,31.25 
						151.59,33.47 149.64,33.47 147.66,31.25 					"/>
				</g>
				<g>
					<path d="M151.82,33.58h-2.22l-2.07-2.32l1.98-2.78l-1.95-2.22l2.03-2.61h2.21l-2.02,2.6l1.94,2.22l-1.94,2.78L151.82,33.58z
					M149.69,33.37h1.67l-1.85-2.11l1.94-2.78l-1.95-2.22l1.87-2.4h-1.68l-1.86,2.39l1.94,2.22l-1.98,2.78L149.69,33.37z"/>
				</g>
			</g>
			<g>
				<g>
					<rect x="151.73" y="23.62" class="st_bridge_9" width="1.39" height="11.94"/>
				</g>
				<g>
					<path d="M153.22,35.66h-1.6V23.52h1.6V35.66z M151.83,35.45h1.18V23.72h-1.18V35.45z"/>
				</g>
			</g>
		</g>
		<g class="st_bridge_7">
			<g>
				<g>
					<path class="st_bridge_8" d="M155.92,28.89h4.46c0.23,0,0.41-0.18,0.41-0.41v-0.02c0-0.23-0.18-0.41-0.41-0.41h-4.46
						c-0.23,0-0.41,0.18-0.41,0.41v0.02C155.51,28.71,155.69,28.89,155.92,28.89z"/>
				</g>
				<g>
					<path d="M155.41,28.48c0-0.29,0.23-0.52,0.52-0.52h4.44c0.29,0,0.52,0.23,0.52,0.52S160.66,29,160.37,29h-4.44
						C155.64,29,155.41,28.77,155.41,28.48z M155.93,28.17c-0.17,0-0.31,0.14-0.31,0.31s0.14,0.31,0.31,0.31h4.44
						c0.17,0,0.31-0.14,0.31-0.31s-0.14-0.31-0.31-0.31H155.93z"/>
				</g>
			</g>
			<g>
				<g>
					<polygon class="st_bridge_8" points="159.81,28.48 161.76,26.26 159.81,23.76 157.87,23.76 159.81,26.26 157.87,28.48 159.81,31.25 
						157.87,33.47 159.81,33.47 161.8,31.25 					"/>
				</g>
				<g>
					<path d="M159.68,31.25l-1.94-2.78l1.94-2.22l-2.02-2.6h2.21l2.03,2.61l-1.95,2.22l1.98,2.78l-2.07,2.32h-2.22L159.68,31.25z
					M161.66,31.25l-1.98-2.78l1.94-2.22l-1.86-2.39h-1.68l1.87,2.4L158,28.49l1.94,2.78l-1.85,2.11h1.67L161.66,31.25z"/>
				</g>
			</g>
			<g>
				<g>
					
						<rect x="156.34" y="23.62" transform="matrix(-1 -4.521284e-11 4.521284e-11 -1 314.0745 59.1769)" class="st_bridge_9" width="1.39" height="11.94"/>
				</g>
				<g>
					<path d="M156.24,23.52h1.6v12.14h-1.6V23.52z M157.63,23.72h-1.18v11.73h1.18V23.72z"/>
				</g>
			</g>
		</g>
	</g>
	<g class="st_bridge_7">
		<g>
			<g>
				<path class="st_bridge_8" d="M14.42,28.24h4.46c0.23,0,0.41-0.18,0.41-0.41v-0.02c0-0.23-0.18-0.41-0.41-0.41h-4.46
					c-0.23,0-0.41,0.18-0.41,0.41v0.02C14.01,28.05,14.2,28.24,14.42,28.24z"/>
			</g>
			<g>
				<path d="M13.91,27.82c0-0.29,0.23-0.52,0.52-0.52h4.44c0.29,0,0.52,0.23,0.52,0.52s-0.23,0.52-0.52,0.52h-4.44
					C14.14,28.34,13.91,28.11,13.91,27.82z M14.43,27.51c-0.17,0-0.31,0.14-0.31,0.31s0.14,0.31,0.31,0.31h4.44
					c0.17,0,0.31-0.14,0.31-0.31s-0.14-0.31-0.31-0.31H14.43z"/>
			</g>
		</g>
		<g>
			<g>
				<polygon class="st_bridge_8" points="18.32,27.82 20.26,25.6 18.32,23.1 16.37,23.1 18.32,25.6 16.37,27.82 18.32,30.6 16.37,32.82 
					18.32,32.82 20.3,30.6 				"/>
			</g>
			<g>
				<path d="M18.18,30.59l-1.94-2.78l1.94-2.22L16.16,23h2.21l2.03,2.61l-1.95,2.22l1.98,2.78l-2.07,2.32h-2.22L18.18,30.59z
				M20.17,30.59l-1.98-2.78l1.94-2.22l-1.86-2.39h-1.68l1.87,2.4l-1.95,2.22l1.94,2.78l-1.85,2.11h1.67L20.17,30.59z"/>
			</g>
		</g>
		<g>
			<g>
				
					<rect x="14.85" y="22.96" transform="matrix(-1 -4.586832e-11 4.586832e-11 -1 31.0795 57.8639)" class="st_bridge_9" width="1.39" height="11.94"/>
			</g>
			<g>
				<path d="M14.74,22.86h1.6V35h-1.6V22.86z M16.13,23.07h-1.18V34.8h1.18V23.07z"/>
			</g>
		</g>
	</g>
	<g class="st_bridge_7">
		<g>
			<g>
				<path class="st_bridge_8" d="M296.21,28.24h-4.46c-0.23,0-0.41-0.18-0.41-0.41v-0.02c0-0.23,0.18-0.41,0.41-0.41h4.46
					c0.23,0,0.41,0.18,0.41,0.41v0.02C296.62,28.05,296.43,28.24,296.21,28.24z"/>
			</g>
			<g>
				<path d="M296.2,28.34h-4.44c-0.29,0-0.52-0.23-0.52-0.52s0.23-0.52,0.52-0.52h4.44c0.29,0,0.52,0.23,0.52,0.52
					S296.49,28.34,296.2,28.34z M291.76,27.51c-0.17,0-0.31,0.14-0.31,0.31s0.14,0.31,0.31,0.31h4.44c0.17,0,0.31-0.14,0.31-0.31
					s-0.14-0.31-0.31-0.31H291.76z"/>
			</g>
		</g>
		<g>
			<g>
				<polygon class="st_bridge_8" points="292.31,27.82 290.37,25.6 292.31,23.1 294.26,23.1 292.31,25.6 294.26,27.82 292.31,30.6 
					294.26,32.82 292.31,32.82 290.33,30.6 				"/>
			</g>
			<g>
				<path d="M294.49,32.92h-2.22l-2.07-2.32l1.98-2.78l-1.95-2.22l2.03-2.61h2.21l-2.02,2.6l1.94,2.22l-1.94,2.78L294.49,32.92z
				M292.36,32.71h1.67l-1.85-2.11l1.94-2.78l-1.95-2.22l1.87-2.4h-1.68l-1.86,2.39l1.94,2.22l-1.98,2.78L292.36,32.71z"/>
			</g>
		</g>
		<g>
			<g>
				<rect x="294.4" y="22.96" class="st_bridge_9" width="1.39" height="11.94"/>
			</g>
			<g>
				<path d="M295.89,35h-1.6V22.86h1.6V35z M294.5,34.8h1.18V23.07h-1.18V34.8z"/>
			</g>
		</g>
	</g>
	<g class="st_bridge_7">
		<g>
			<g>
				<path class="st_bridge_8" d="M156.11,28.51h4.46c0.23,0,0.41-0.18,0.41-0.41v-0.02c0-0.23-0.18-0.41-0.41-0.41h-4.46
					c-0.23,0-0.41,0.18-0.41,0.41v0.02C155.7,28.33,155.89,28.51,156.11,28.51z"/>
			</g>
			<g>
				<path d="M155.6,28.09c0-0.29,0.23-0.52,0.52-0.52h4.44c0.29,0,0.52,0.23,0.52,0.52c0,0.29-0.23,0.52-0.52,0.52h-4.44
					C155.83,28.61,155.6,28.38,155.6,28.09z M156.12,27.78c-0.17,0-0.31,0.14-0.31,0.31s0.14,0.31,0.31,0.31h4.44
					c0.17,0,0.31-0.14,0.31-0.31s-0.14-0.31-0.31-0.31H156.12z"/>
			</g>
		</g>
		<g>
			<g>
				<polygon class="st_bridge_8" points="160,28.09 161.95,25.87 160,23.37 158.06,23.37 160,25.87 158.06,28.09 160,30.87 158.06,33.09 
					160,33.09 161.99,30.87 				"/>
			</g>
			<g>
				<path d="M159.87,30.86l-1.94-2.78l1.94-2.22l-2.02-2.6h2.21l2.03,2.61l-1.95,2.22l1.98,2.78l-2.07,2.32h-2.22L159.87,30.86z
				M161.85,30.86l-1.98-2.78l1.94-2.22l-1.86-2.39h-1.68l1.87,2.4l-1.95,2.22l1.94,2.78l-1.85,2.11h1.67L161.85,30.86z"/>
			</g>
		</g>
		<g>
			<g>
				
					<rect x="156.53" y="23.24" transform="matrix(-1 -4.586832e-11 4.586832e-11 -1 314.4577 58.4087)" class="st_bridge_9" width="1.39" height="11.94"/>
			</g>
			<g>
				<path d="M156.43,23.13h1.6v12.14h-1.6V23.13z M157.82,23.34h-1.18v11.73h1.18V23.34z"/>
			</g>
		</g>
	</g>
	<g class="st_bridge_7">
		<g>
			<g>
				<path class="st_bridge_8" d="M154.52,28.51h-4.46c-0.23,0-0.41-0.18-0.41-0.41v-0.02c0-0.23,0.18-0.41,0.41-0.41h4.46
					c0.23,0,0.41,0.18,0.41,0.41v0.02C154.93,28.33,154.74,28.51,154.52,28.51z"/>
			</g>
			<g>
				<path d="M154.51,28.61h-4.44c-0.29,0-0.52-0.23-0.52-0.52c0-0.29,0.23-0.52,0.52-0.52h4.44c0.29,0,0.52,0.23,0.52,0.52
					C155.03,28.38,154.8,28.61,154.51,28.61z M150.07,27.78c-0.17,0-0.31,0.14-0.31,0.31s0.14,0.31,0.31,0.31h4.44
					c0.17,0,0.31-0.14,0.31-0.31s-0.14-0.31-0.31-0.31H150.07z"/>
			</g>
		</g>
		<g>
			<g>
				<polygon class="st_bridge_8" points="150.62,28.09 148.68,25.87 150.62,23.37 152.57,23.37 150.62,25.87 152.57,28.09 150.62,30.87 
					152.57,33.09 150.62,33.09 148.64,30.87 				"/>
			</g>
			<g>
				<path d="M152.8,33.19h-2.22l-2.07-2.32l1.98-2.78l-1.95-2.22l2.03-2.61h2.21l-2.02,2.6l1.94,2.22l-1.94,2.78L152.8,33.19z
				M150.67,32.99h1.67l-1.85-2.11l1.94-2.78l-1.95-2.22l1.87-2.4h-1.68l-1.86,2.39l1.94,2.22l-1.98,2.78L150.67,32.99z"/>
			</g>
		</g>
		<g>
			<g>
				<rect x="152.71" y="23.24" class="st_bridge_9" width="1.39" height="11.94"/>
			</g>
			<g>
				<path d="M154.2,35.28h-1.6V23.13h1.6V35.28z M152.81,35.07h1.18V23.34h-1.18V35.07z"/>
			</g>
		</g>
	</g>
</g>
<g id="Jerseys">
	<g>
		<polygon class="st_bridge_8" points="14.76,34.82 21.92,34.82 21.92,32.03 19.46,29.73 18.48,28.09 17.82,23.17 14.7,23.17 		"/>
		<path d="M22.24,35.15h-7.81l-0.06-12.31h3.74l0.69,5.14l0.93,1.54l2.52,2.36V35.15z M15.08,34.49h6.5v-2.32l-2.41-2.27l-1.02-1.7
			l-0.63-4.71h-2.5L15.08,34.49z"/>
	</g>
	<g>
		<polygon class="st_bridge_8" points="295.94,34.82 288.78,34.82 288.78,32.03 291.24,29.73 292.22,28.09 292.88,23.17 296,23.17 		"/>
		<path d="M296.27,35.15h-7.81v-3.26l2.52-2.36l0.93-1.54l0.69-5.14h3.74L296.27,35.15z M289.11,34.49h6.5l0.05-11h-2.5l-0.63,4.71
			l-1.08,1.77l-2.35,2.2V34.49z"/>
	</g>
	<g class="st_bridge_6">
		<polygon class="st_bridge_10" points="161.74,32.69 161.74,35.48 147.97,35.48 147.97,32.69 150.42,30.39 151.41,28.75 152.06,23.82 
			157.65,23.82 158.31,28.75 159.29,30.39 		"/>
		<path class="st_bridge_7" d="M162.07,35.8h-14.43v-3.26l2.52-2.36l0.93-1.54l0.69-5.14h6.17l0.69,5.14l0.93,1.54l2.52,2.36V35.8z
		M148.3,35.15h13.12v-2.32l-2.41-2.27l-1.02-1.7l-0.63-4.71h-5.01l-0.63,4.71l-1.08,1.77l-2.35,2.2V35.15z"/>
	</g>
	<g>
		<polygon class="st_bridge_8" points="156.44,35.09 163.6,35.09 163.6,32.3 161.15,30 160.17,28.36 159.51,23.44 156.39,23.44 		"/>
		<path d="M163.93,35.42h-7.81l-0.06-12.31h3.74l0.69,5.14l0.93,1.54l2.52,2.36V35.42z M156.77,34.76h6.5v-2.32l-2.41-2.27
			l-1.02-1.7l-0.63-4.71h-2.5L156.77,34.76z"/>
	</g>
	<g>
		<polygon class="st_bridge_8" points="154.25,35.09 147.09,35.09 147.09,32.3 149.55,30 150.53,28.36 151.19,23.44 154.31,23.44 		"/>
		<path d="M154.58,35.42h-7.81v-3.26l2.52-2.36l0.93-1.54l0.69-5.14h3.74L154.58,35.42z M147.42,34.76h6.5l0.05-11h-2.5l-0.63,4.71
			l-1.08,1.77l-2.35,2.2V34.76z"/>
	</g>
</g>
</svg>


</template>
<script>
export default {
  name: "SvgPlatformBridge1LaneJerseyComponent",
  props: {
    paramSelected: undefined,
  },
};
</script>
<style scoped>
	.st_bridge_0{fill:none;stroke:#000000;stroke-miterlimit:10;}
	.st_bridge_1{fill:#666666;}
	.st_bridge_2{fill:none;stroke:#000000;stroke-width:0.5;stroke-miterlimit:10;}
	.st_bridge_3{font-family:'Roboto-Bold';}
	.st_bridge_4{font-size:14px;}
	.st_bridge_5{fill:#7C7C7C;}
	.st_bridge_6{display:none;}
	.st_bridge_7{display:inline;}
	.st_bridge_8{fill:#686868;}
	.st_bridge_9{fill:#72502F;}
	.st_bridge_10{display:inline;fill:#686868;}
</style>
