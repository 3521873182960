<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 482.35 218.16" style="enable-background:new 0 0 482.35 218.16;" xml:space="preserve">
<g id="Terreno">
	<line class="st_fill_0" x1="-8.07" y1="218.05" x2="409.09" y2="148.8"/>
</g>
<g id="Aterro">
	<path class="st_fill_1" d="M481.96,149.42c0,0-0.11-0.13-0.32-0.39c-0.21-0.25,0.48-2.63,0.07-3.12c-0.41-0.57-0.91-1.03-1.49-1.8
		c-0.59-1.19-1.26-2.23-2.01-2.43c-0.19,0.18-0.38-0.94-0.58-0.7c-0.2-0.62-0.4,0.49-0.61-0.73c-0.21-0.45-0.42,0.17-0.64-0.95
		c-0.22,0.48-0.44-0.4-0.66-0.62c-0.23-0.18-0.46,0.15-0.69-0.79c-0.23-0.8-0.47-1.14-0.72-1.91c-0.24-0.69-0.49-1.14-0.74-1.57
		c-0.25-0.29-0.51-0.36-0.77-0.25c-0.52,0.12-1.05-1.51-1.6-1.93c-0.55-2.54-1.11-1.53-1.69-2.04c-0.58-1.83-1.17-1.52-1.78-2.15
		c-0.61,0.49-1.23-1.01-1.86-2.24c-0.63-0.84-1.27-1.59-1.93-2.33c-0.65-1.48-1.32-1.42-2-2.41c-0.68-1.15-1.36-1.19-2.06-2.49
		c-0.7-0.6-1.4-1.59-2.12-2.55c-0.71-0.67-1.44-2.02-2.17-2.62c-0.73-0.85-1.47-1.7-2.21-2.67c-0.74-0.68-1.49-1.63-2.25-2.72
		c-0.76-0.91-1.52-1.84-2.29-2.76c-0.77-0.73-1.54-1.44-2.31-2.79c-0.78-1.1-1.55-2.17-2.34-2.82c-0.78-0.6-1.57-1.71-2.35-2.84
		c-0.79-0.41-1.57-2.1-2.36-2.85c-0.79-0.82-1.58-0.91-2.37-1.86c-0.79-0.92-1.58-1.71-2.37-2.86c-0.79-1.02-1.58-1.81-2.36-2.85
		c-0.79-0.83-4.57-5.76-5.35-6.84c-0.78-1.18-1.56-2-2.34-2.82c-0.78-0.65-1.55-1.96-2.31-2.79c-0.77-0.85-1.53-1.88-2.29-2.76
		c-0.76-0.69-1.51-2.02-2.25-2.72c-0.74-0.43-1.48-1.36-2.21-2.67c-0.73-1.28-1.45-2-2.17-2.62c-0.71-1.1-1.42-2.05-2.12-2.55
		c-0.7-1.52-1.38-1.86-2.06-2.49c-0.68,0.16-1.34-1.97-2-2.41c-0.65-1.01-1.3-1.63-1.93-2.33c-0.63-0.75-1.25-1.56-1.86-2.24
		c-0.61-0.38-1.2-1.34-1.78-2.15c-0.58-0.93-1.14-1.68-1.69-2.04c-0.55-0.41-1.08-1.36-1.6-1.93c-0.52,0.17-1.02-1.09-1.51-1.82
		c-0.49-1.01-0.95-0.47-1.41-1.7c-0.45-0.2-0.88-1.14-1.3-1.57c-0.41,0.16-0.81-0.19-1.19-1.43c-0.38-0.91-0.73-0.45-1.07-1.29
		c-0.34-0.64-0.65-0.29-0.94-1.14c-0.29-0.2-1.56-1.48-1.81-1.98c-0.25-0.13-0.48-0.07-0.68-0.82c-0.2-0.4-0.38-0.45-0.54-0.65
		c-0.16,0.12,0.71,0.09,0.61,0.53c-0.11-0.13-0.19-0.12-0.24-0.29c-0.05,0.17-0.08,0.13-0.08-0.1c0,0.48-0.03,0.02-0.1,0
		c-0.07-0.05-0.17,0.71-0.3,0c-0.13,0.09-0.3,0.16-0.49,0c-0.2,0.1,0.58,0.21,0.32,0c-0.26,0.05,0.45-0.04,0.14,0
		c-0.32-0.16-0.67-0.16-1.04,0c-0.38,0.07-0.78-0.29-1.21,0c-0.43,0.07-0.89-0.07-1.38,0c-0.49,0.04-1-0.06-1.54,0
		c-0.54,0.06-1.1,0.05-1.69,0c-0.59,0.22-1.2-0.2-1.84,0c-0.64-0.12-1.3-0.12-1.98,0c-0.68-0.12-1.39-0.18-2.12,0
		c-0.73-0.19-1.48,0.03-2.25,0c-0.77,0.01-1.56,0.03-2.37,0c-0.81-0.17-1.64-0.05-2.49,0c-0.85,0.15-1.72-0.1-2.61,0
		c-0.89-0.04-1.79-0.13-2.71,0c-0.92-0.2-1.86,0.07-2.82,0c-0.96,0.07-1.93-0.18-2.91,0c-0.99-0.3-1.99-0.29-3.01,0
		c-1.02-0.04-2.05-0.26-3.09,0c-1.04-0.31-2.1-0.26-3.17,0c-1.07,0.85-2.15-0.23-3.24,0c-1.09-0.91-2.2,0.34-3.31,0
		c-1.11,0.85-2.24-0.33-3.38,0c-1.13,0.07-2.28,0.6-3.43,0c-1.15-0.07-2.31-0.38-3.48,0c-1.17,0.12-2.35-0.4-3.53,0
		c-1.18,0.05-2.37,0.09-3.57,0c-1.2,0.19-2.4-0.06-3.6,0c-1.21-0.22-2.42,15-3.63,15c-1.21-0.3-2.43,0.09-3.65,0
		c-1.22-0.2-2.45-0.38-3.67,0c-1.23,0.38-2.45,0.24-3.68,0c-1.23-0.14-2.46-0.26-3.69,0c-1.23-0.25-2.46,0.19-3.69,0
		c-1.23-0.02-2.46-0.15-3.68,0c-1.23,0.09-2.45,0.32-3.67,0c-1.22-0.33-2.44,0.23-3.65,0c-1.21-0.05-2.43-14.83-3.63-15
		c-1.21-0.05-2.41,0.11-3.6,0c-1.2,0.22-2.39,0.13-3.57,0c-1.18,0.16-2.36-0.22-3.53,0c-1.17,0.29-2.33-0.6-3.48,0
		c-1.15-0.42-2.3-0.46-3.43,0c-1.13-0.45-2.26,0.07-3.38,0c-1.11-0.48-2.22,0.56-3.31,0c-1.09-0.11-2.17,0.1-3.24,0
		c-1.07,0-2.13-0.2-3.17,0c-1.04-0.21-2.07,0.01-3.09,0c-1.02-0.37-2.02-0.22-3.01,0c-0.99,0.06-1.96,0.05-2.91,0
		c-0.96,0.43-1.89,0-2.82,0s-1.83,0-2.71,0c-0.89-0.05-1.76-0.31-2.61,0c-0.85-0.02-1.68-0.29-2.49,0c-0.81,0.18-1.6,0-2.37,0
		c-0.77-0.22-1.52,0-2.25,0c-0.73,0-1.43,0-2.12,0c-0.68-0.22-1.34,0.67-1.98,0c-0.64-0.67-1.25-1.16-1.84,0
		c-0.59-0.12-1.15,0.14-1.69,0c-0.54-0.11-1.05,0.03-1.54,0c-0.49,0.46-2.95,0.69-3.38,1c-0.43-0.18,0.16,0.66-0.21,0
		c-0.38,0.05,1.28-1,0.96-1c-0.32,0.16,0.39,0.36,0.14,0c-0.26,0.2-0.49-0.08-0.68,0c-0.2-0.53-0.36,0-0.49,0
		c-0.13-0.03-0.23-0.13-0.3,0c-0.07,0.18-0.1,0.21-0.1,0c0,0.12-0.03-0.17-0.08,0.09c-0.05,1.08-0.13,0.03-0.24,0.28
		c-0.11-0.16-0.24,0.77-0.39,0.46c-0.16-0.24-0.34,0-0.54,0.63c-0.2,0.23-0.43,0.71-0.68,0.79c-0.25,1.18-0.52,1.12-0.82,0.95
		c-0.29,1.09,1.39,0.64,1.05,0.1c-0.34-0.01-0.69-0.27-1.07,1.25c-0.38-0.09-1.77,0.68-2.19,1.39c-0.42-0.34-0.85,1.14-1.3,1.52
		c-0.45,0.73-0.92,0.9-1.41,1.65c-0.49,0.29-0.99,1.71-1.51,1.77c-0.52,0.56-1.06,0.24-1.61,1.88c-0.55,1.26-1.12,1.97-1.7,1.99
		c-0.58,0.78-1.18,1.17-1.79,2.09c-0.61,0.78-1.23,1.5-1.87,2.18c-0.64,1.3-1.28,1.16-1.94,2.27c-0.66,0.85-1.33,1.5-2.01,2.35
		c-0.68,0.69-1.37,1.32-2.08,2.42c-0.7,1.15-1.41,1.35-2.14,2.49c-0.72,0.53-1.45,1.81-2.19,2.55c-0.74,0.89-1.48,1.37-2.24,2.61
		c-0.75,0.45-1.51,1.56-2.28,2.65c-0.77,0.7-1.54,2.15-2.31,2.7c-0.78,0.74-1.56,1.88-2.34,2.73c-0.79,0.27-1.58,0.85-2.37,2.76
		c-0.79,1.36-5.59,6.08-6.39,6.78c-0.8,1.63-0.6,3.69-1.4,3.8c-0.8,1-1.61-0.31-2.41,0.81c-0.8,1.19-1.61,1.9-2.41,2.81
		c-0.8,0.7-1.61,1.89-2.41,2.81c-0.8,0.96-1.6,1.71-2.4,2.8c-0.8,0.99-1.59,1.56-2.39,2.78c-0.79,1.24-1.58,1.98-2.37,2.76
		c-0.79,0.57-1.57,1.98-2.34,2.73c-0.78,0.95-1.55,2.05-2.31,2.7c-0.77,1-1.52,3.71-2.28,4.65c-0.75,0.64-1.5,1.48-2.24,2.61
		c-0.74,0.93-1.47,1.1-2.19,1.55c-0.72,0.93-1.43,1.62-2.14,2.49c-0.7,0.89-1.4,1.4-2.08,2.42c-0.68,0.54-1.35,1.59-2.01,2.35
		c-0.66,0.29-1.31,1.14-1.94,2.27c-0.64,0.38-1.26,0.19-1.87,2.18c-0.61,0.8-1.21,0.91-1.79,2.09c-0.58,0.31-1.15,1.54-1.7,1.99
		c-0.55,1.11-1.09,1.45-1.61,1.88c-0.52,0.81-1.03,0.88-1.51,1.77c-0.49,0.91-0.96,1.19-1.41,1.65c-0.45,0.75-0.89,3.98-1.3,3.52
		c-0.42,0.27-0.81-1.13-1.19-0.61c-0.38,1.24-0.73,0.07-1.07,2.25c-0.34,0.13-0.65,1.3-0.95,1.1c-0.29-0.28-0.57,0.88-0.82,0.95
		c-0.25,0.36-0.48,0.66-0.68,0.79c-0.2,0.06-0.38-0.37-0.54-0.37c-0.16-0.67-0.29-0.41-0.39,1.46c-0.11,0.94-0.19,0.26-0.24,0.28
		c-0.05,0.81-0.08-0.56-0.08,0.09c0-0.25-0.11-0.63-0.33-0.02c-0.22-0.35-0.53-1.28-0.94-0.06c-0.41-0.7-0.91-0.35-1.49-0.1
		c-0.58-0.75-1.24,0.6-1.98-0.13c-0.73-0.63-1.54-0.16-2.4-0.16c-0.86,0.01-1.78,0.58-2.75,0.82c-0.97-0.11-1.99-0.96-3.04-1.2
		c-1.06,0.25-2.15,0.94-3.27,0.79c-1.12,0.16-2.27-1.31-3.43-1.22c-1.16,0.28-2.34,0.52-3.53-0.23c-1.19,0.42-2.38-0.68-3.56-0.23
		c-1.19,0.26-2.37-0.28-3.53-0.23c-1.16,0.38-2.31-0.42-3.43-0.22c-1.12-0.33-2.21,0.11-3.27-0.21c-1.06-0.16-2.07-0.1-3.04-0.2
		c-0.97,0.44-1.89-0.77-2.75-0.18c-0.86-0.11-1.66,1.64-2.4-0.16c-0.73-1.06-1.4-0.25-1.98-0.13c-0.58,0.12-1.08-1.34-1.49-1.1
		c-0.41-0.3-0.73-0.75-0.94-0.06c-0.22-0.86-0.33-0.44-0.33-0.02c0,0.08-0.04-0.16-0.12,0.13c-0.08,0.5-0.2,0.59-0.35,0.37
		c-0.16-0.21-0.35,1.11-0.58,0.6c-0.23,0.13-0.49,0.35-0.79,0.82c-0.3,0.46-0.62,0.24-0.98,1.02c-0.36,0.49-0.75,1.06-1.17,1.22
		c-0.42,0.4-0.87,0-1.34,1.4c-0.48,0.47-0.98,1.35-1.51,1.57c-0.53,0.13-1.08,1.05-1.66,1.72c-0.58,1.19-1.17,1.58-1.79,1.87
		c-0.62,1.3-1.26,1.33-1.92,2c-0.66,1.05-1.34,0.99-2.03,2.12c-0.7,0.61-1.41,1.22-2.14,2.22c-0.73,0.84-1.47,1.49-2.23,2.32
		c-0.76,1.14-1.52,2.25-2.3,2.4c-0.78,0.56-1.57,1.27-2.37,2.47c-0.8,0.38-1.61,1.59-2.42,2.52c-0.82,1.41-1.64,1.96-2.46,2.57
		c-0.83,0.67-1.66,1.83-2.49,2.6c-0.84,0.3-1.67,2.23-2.51,2.62c-0.84,1.45-1.68,1.28-2.52,2.62c-0.84,0.73-1.68,1.3-2.51,2.62
		c-0.84,0.97-1.67,1.22-2.49,2.6c-0.83,1.18-1.65,1.53-2.46,2.57c-0.82,0.75-1.62,1.61-2.42,2.52c-0.8,1.07-1.59,1.7-2.37,2.47
		c-0.78,0.67-1.55,2.23-2.3,2.4c-0.76,0.9-1.5,0.89-2.23,2.32c-0.73,1.21-1.44,1.19-2.14,2.22c-0.7,0.86-1.37,1.43-2.03,2.12
		c-0.66,0.64-1.3,1.65-1.92,2c-0.62,0.37-1.22,1.1-1.79,1.87c-0.58,0.89-1.13,1.04-1.66,1.72c-0.53,0.55-1.03,0.45-1.51,1.57
		c-0.48,0.67-0.92,0.72-1.34,1.4c-0.42,0.31-0.81,0.77-1.17,1.22c-0.36,0.22-0.69,3.72-0.98,4.02c-1.18,1.23-1.84,1.91-1.84,1.91
		L481.96,149.42z"/>
	<polygon class="st_fill_2" points="481.96,149.42 390.3,38.77 361.38,36.82 245.67,37.11 213.22,38.77 118.3,149.42 68.41,146.16 
		-0.46,217.87 	"/>
</g>
<g id="Canaleta" class="st_fill_3">
	<g class="st_fill_4">
		<rect x="224.16" y="26.76" class="st_fill_5" width="6.44" height="3.7"/>
	</g>
	<g class="st_fill_4">
		<polygon class="st_fill_6" points="231.05,26.76 231.05,30.98 223.82,30.98 223.82,26.76 224.59,26.76 224.59,30.2 230.28,30.2 
			230.28,26.76 		"/>
	</g>
	<g class="st_fill_4">
		<rect x="292.37" y="26.76" class="st_fill_5" width="6.44" height="3.7"/>
	</g>
	<g class="st_fill_4">
		<polygon class="st_fill_6" points="299.2,26.76 299.2,30.98 291.97,30.98 291.97,26.76 292.74,26.76 292.74,30.2 298.43,30.2 
			298.43,26.76 		"/>
	</g>
</g>
<g id="Medidas">
	<text transform="matrix(1 0 0 1 25.2161 97.0225)" class="st_fill_7 st_fill_8">{{ paramSelected.values_params.earthwork.fill_height }}m</text>
	<text transform="matrix(1 0 0 1 82.9207 171.1367)" class="st_fill_9 st_fill_7 st_fill_8">{{ paramSelected.values_params.earthwork.fill_enbankment_width }}m</text>
	<text transform="matrix(1 0 0 1 433.158 64.0215)" class="st_fill_7 st_fill_8">{{ Math.round(
              (1 / paramSelected.values_params.earthwork.fill_factor) * 10
            ) / 10 }}</text>
	<text transform="matrix(1 0 0 1 451.3669 87.791)" class="st_fill_7 st_fill_8">1.5</text>
	<text transform="matrix(1 0 0 1 81.1589 134.8301)" class="st_fill_7 st_fill_8">{{ paramSelected.values_params.earthwork.fill_enbankment_slope }}%</text>
	<path class="st_fill_10" d="M448.36,96.17V68.78l-21.15-0.05L448.36,96.17z"/>
	<rect x="53.12" y="149.19" width="6.69" height="0.46"/>
	<rect x="53.12" y="38.54" width="6.69" height="0.46"/>
	<rect x="68.82" y="140.96" width="48.96" height="0.46"/>
	<polygon points="116.86,141.97 117.64,141.19 116.86,140.4 117.52,140.4 118.3,141.19 117.52,141.97 	"/>
	<g>
		<g>
			<line class="st_fill_11" x1="70.34" y1="152.48" x2="117.36" y2="152.55"/>
			<g>
				<polygon class="st_fill_9" points="71.34,153.33 70.49,152.48 71.34,151.63 70.62,151.63 69.77,152.48 70.62,153.33 				"/>
			</g>
			<g>
				<polygon class="st_fill_9" points="116.36,153.4 117.21,152.55 116.37,151.7 117.09,151.7 117.93,152.55 117.08,153.4 				"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<line class="st_fill_12" x1="56.43" y1="148.48" x2="56.43" y2="39.62"/>
			<g>
				<polygon points="57.28,147.48 56.43,148.33 55.58,147.48 55.58,148.2 56.43,149.05 57.28,148.2 				"/>
			</g>
			<g>
				<polygon points="57.28,40.62 56.43,39.77 55.58,40.62 55.58,39.9 56.43,39.05 57.28,39.9 				"/>
			</g>
		</g>
	</g>
	<rect x="117.85" y="149.13" class="st_fill_9" width="0.46" height="6.69"/>
	<rect x="68.85" y="149.13" class="st_fill_9" width="0.46" height="6.69"/>
</g>
<g id="Canteiro">
	<g>
		<polygon class="st_fill_13" points="305.89,33.48 305.89,38.56 297.71,38.56 297.71,33.49 298.62,33.52 298.62,34.38 304.98,34.38 
			304.98,33.55 		"/>
		<path d="M306.04,38.71h-8.48v-5.37l1.22,0.03v0.86h6.05v-0.82l1.22-0.1V38.71z M297.86,38.41h7.88v-4.77l-0.61,0.05v0.84h-6.66
			v-0.87l-0.61-0.01V38.41z"/>
	</g>
	<g>
		<polygon class="st_fill_14" points="220.24,32.63 216.62,32.63 216.62,31.11 217.23,31.11 217.23,32.02 220.24,32.02 		"/>
	</g>
	<g>
		<polyline class="st_fill_9" points="383.2,32.33 386.52,32.33 386.52,31.11 		"/>
		<polygon class="st_fill_14" points="386.82,32.63 383.2,32.63 383.2,32.02 386.22,32.02 386.22,31.11 386.82,31.11 		"/>
	</g>
	<g>
		<polygon class="st_fill_13" points="306,33.48 306,38.56 297.82,38.56 297.82,33.49 298.73,33.52 305.08,33.55 		"/>
		<path d="M306.15,38.71h-8.48v-5.37l1.07,0.02l6.36,0.04l1.06-0.09V38.71z M297.97,38.41h7.88v-4.77l-0.75,0.06l-6.37-0.04
			l-0.76-0.02V38.41z"/>
	</g>
</g>
<g id="Sub_x5F_base">
	<g>
		<polygon class="st_fill_15" points="310.14,39.08 310.14,39.13 310.1,39.13 		"/>
	</g>
	<g>
		<polygon class="st_fill_16" points="276.5,35.68 245.72,35.07 215.14,35.68 216.83,32.79 245.72,32.18 274.82,32.79 		"/>
		<path d="M214.88,35.83l1.86-3.2l0.09,0l28.89-0.61l29.19,0.61l1.86,3.2l-31.05-0.61L214.88,35.83z M245.71,34.92l30.52,0.6
			l-1.5-2.58l-29.01-0.61l-28.8,0.61l-1.5,2.58L245.71,34.92z"/>
	</g>
	<g>
		<polygon class="st_fill_17" points="278.16,38.56 245.72,37.96 213.48,38.56 215.1,35.68 245.72,35.07 276.54,35.68 		"/>
		<path d="M213.22,38.72l1.79-3.2l0.09,0l30.61-0.61l30.92,0.61l1.8,3.2l-32.71-0.61L213.22,38.72z M245.71,37.8l32.19,0.6
			l-1.45-2.58l-30.74-0.61l-30.52,0.61l-1.45,2.58L245.71,37.8z"/>
	</g>
	<g>
		<polygon class="st_fill_16" points="388.38,35.68 357.59,35.07 327.02,35.68 328.71,32.79 357.59,32.18 386.69,32.79 		"/>
		<path d="M326.75,35.83l1.86-3.2l0.09,0l28.89-0.61l29.19,0.61l1.86,3.2l-31.05-0.61L326.75,35.83z M357.59,34.92l30.52,0.6
			l-1.5-2.58l-29.01-0.61l-28.8,0.61l-1.5,2.58L357.59,34.92z"/>
	</g>
	<g>
		<polygon class="st_fill_17" points="390.04,38.56 357.59,37.96 325.36,38.56 326.98,35.68 357.59,35.07 388.42,35.68 		"/>
		<path d="M325.1,38.72l1.79-3.2l0.09,0l30.62-0.61l30.92,0.61l1.8,3.2l-32.71-0.61L325.1,38.72z M357.59,37.8l32.19,0.6l-1.45-2.58
			l-30.74-0.61l-30.52,0.61l-1.45,2.58L357.59,37.8z"/>
	</g>
</g>
<g id="Land">
	<g>
		<polygon class="st_fill_17" points="332.37,32.58 326.89,38.53 326.86,38.56 276.38,38.56 276.36,38.56 271.06,32.58 297.71,33.49 
			297.71,35.22 306.01,35.22 306.01,33.49 		"/>
		<path d="M326.93,38.71h-50.64l-5.58-6.3l27.16,0.93v1.73h8v-1.73l0.15-0.01l26.72-0.92L326.93,38.71z M276.42,38.41h50.37
			l5.21-5.67l-25.84,0.89v1.74h-8.61v-1.74l-26.16-0.89L276.42,38.41z"/>
	</g>
</g>
<g id="Asphalt_x5F_and_components">
	<polygon class="st_fill_18" points="-4.07,218.05 482.93,218.05 482.93,149.05 	"/>
	<g>
		<polygon points="271.11,31.94 245.67,31.33 220.24,31.94 220.24,32.7 245.67,32.09 271.11,32.7 		"/>
	</g>
	<g>
		<polygon points="383.24,31.94 357.81,31.33 332.38,31.94 332.38,32.7 357.81,32.09 383.24,32.7 		"/>
	</g>
</g>
<g id="Metal_x5F_guard" class="st_fill_3">
	<g class="st_fill_4">
		<g>
			<g>
				<g>
					<path class="st_fill_13" d="M301.22,30.33h-2.06c-0.11,0-0.19-0.09-0.19-0.19l0,0c0-0.11,0.09-0.19,0.19-0.19h2.06
						c0.11,0,0.19,0.09,0.19,0.19l0,0C301.42,30.24,301.33,30.33,301.22,30.33z"/>
				</g>
				<g>
					<path d="M301.22,30.37h-2.06c-0.13,0-0.24-0.11-0.24-0.24s0.11-0.24,0.24-0.24h2.06c0.13,0,0.24,0.11,0.24,0.24
						S301.36,30.37,301.22,30.37z M299.17,29.99c-0.08,0-0.14,0.06-0.14,0.14c0,0.08,0.06,0.14,0.14,0.14h2.06
						c0.08,0,0.14-0.06,0.14-0.14c0-0.08-0.06-0.14-0.14-0.14H299.17z"/>
				</g>
			</g>
			<g>
				<g>
					<polygon class="st_fill_13" points="299.42,30.13 298.52,29.1 299.42,27.95 300.32,27.95 299.42,29.1 300.32,30.13 299.42,31.42 
						300.32,32.45 299.42,32.45 298.5,31.42 					"/>
				</g>
				<g>
					<path d="M300.43,32.5h-1.03l-0.96-1.07l0.92-1.29l-0.9-1.03l0.94-1.21h1.02l-0.94,1.2l0.9,1.03l-0.9,1.29L300.43,32.5z
						M299.45,32.4h0.77l-0.86-0.98l0.9-1.29l-0.9-1.03l0.86-1.11h-0.78l-0.86,1.11l0.9,1.03l-0.92,1.29L299.45,32.4z"/>
				</g>
			</g>
			<g>
				<g>
					<rect x="300.39" y="27.88" class="st_fill_19" width="0.64" height="5.53"/>
				</g>
				<g>
					<path d="M301.08,33.46h-0.74v-5.63h0.74V33.46z M300.44,33.36h0.55v-5.43h-0.55V33.36z"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<g>
					<path class="st_fill_13" d="M302.33,30.33h2.06c0.11,0,0.19-0.09,0.19-0.19l0,0c0-0.11-0.09-0.19-0.19-0.19h-2.06
						c-0.11,0-0.19,0.09-0.19,0.19l0,0C302.14,30.24,302.23,30.33,302.33,30.33z"/>
				</g>
				<g>
					<path d="M302.09,30.13c0-0.13,0.11-0.24,0.24-0.24h2.06c0.13,0,0.24,0.11,0.24,0.24s-0.11,0.24-0.24,0.24h-2.06
						C302.2,30.37,302.09,30.27,302.09,30.13z M302.33,29.99c-0.08,0-0.14,0.06-0.14,0.14c0,0.08,0.06,0.14,0.14,0.14h2.06
						c0.08,0,0.14-0.06,0.14-0.14c0-0.08-0.06-0.14-0.14-0.14H302.33z"/>
				</g>
			</g>
			<g>
				<g>
					<polygon class="st_fill_13" points="304.13,30.13 305.03,29.1 304.13,27.95 303.23,27.95 304.13,29.1 303.23,30.13 304.13,31.42 
						303.23,32.45 304.13,32.45 305.05,31.42 					"/>
				</g>
				<g>
					<path d="M304.07,31.42l-0.9-1.29l0.9-1.03l-0.94-1.2h1.02l0.94,1.21l-0.9,1.03l0.92,1.29l-0.96,1.07h-1.03L304.07,31.42z
						M304.99,31.42l-0.92-1.29l0.9-1.03l-0.86-1.11h-0.78l0.86,1.11l-0.9,1.03l0.9,1.29l-0.86,0.98h0.77L304.99,31.42z"/>
				</g>
			</g>
			<g>
				<g>
					
						<rect x="302.53" y="27.88" transform="matrix(-1 -4.526125e-11 4.526125e-11 -1 605.6967 61.2937)" class="st_fill_19" width="0.64" height="5.53"/>
				</g>
				<g>
					<path d="M302.48,27.83h0.74v5.63h-0.74V27.83z M303.12,27.93h-0.55v5.43h0.55V27.93z"/>
				</g>
			</g>
		</g>
	</g>
	<g class="st_fill_4">
		<g>
			<g>
				<path class="st_fill_13" d="M216.58,29.57h2.06c0.11,0,0.19-0.09,0.19-0.19l0,0c0-0.11-0.09-0.19-0.19-0.19h-2.06
					c-0.11,0-0.19,0.09-0.19,0.19l0,0C216.38,29.48,216.47,29.57,216.58,29.57z"/>
			</g>
			<g>
				<path d="M216.34,29.37c0-0.13,0.11-0.24,0.24-0.24h2.06c0.13,0,0.24,0.11,0.24,0.24s-0.11,0.24-0.24,0.24h-2.06
					C216.44,29.61,216.34,29.51,216.34,29.37z M216.58,29.23c-0.08,0-0.14,0.06-0.14,0.14c0,0.08,0.06,0.14,0.14,0.14h2.06
					c0.08,0,0.14-0.06,0.14-0.14c0-0.08-0.06-0.14-0.14-0.14H216.58z"/>
			</g>
		</g>
		<g>
			<g>
				<polygon class="st_fill_13" points="218.38,29.37 219.28,28.34 218.38,27.19 217.48,27.19 218.38,28.34 217.48,29.37 218.38,30.66 
					217.48,31.69 218.38,31.69 219.3,30.66 				"/>
			</g>
			<g>
				<path d="M218.32,30.65l-0.9-1.29l0.9-1.03l-0.94-1.2h1.02l0.94,1.21l-0.9,1.03l0.92,1.29l-0.96,1.07h-1.03L218.32,30.65z
					M219.23,30.65l-0.92-1.29l0.9-1.03l-0.86-1.11h-0.78l0.86,1.11l-0.9,1.03l0.9,1.29l-0.86,0.98h0.77L219.23,30.65z"/>
			</g>
		</g>
		<g>
			<g>
				
					<rect x="216.77" y="27.12" transform="matrix(-1 -4.384683e-11 4.384683e-11 -1 434.1836 59.7731)" class="st_fill_19" width="0.64" height="5.53"/>
			</g>
			<g>
				<path d="M216.72,27.07h0.74v5.63h-0.74V27.07z M217.37,27.17h-0.55v5.43h0.55V27.17z"/>
			</g>
		</g>
	</g>
	<g class="st_fill_4">
		<g>
			<g>
				<path class="st_fill_13" d="M386.87,29.57h-2.06c-0.11,0-0.19-0.09-0.19-0.19l0,0c0-0.11,0.09-0.19,0.19-0.19h2.06
					c0.11,0,0.19,0.09,0.19,0.19l0,0C387.06,29.48,386.97,29.57,386.87,29.57z"/>
			</g>
			<g>
				<path d="M386.87,29.61h-2.06c-0.13,0-0.24-0.11-0.24-0.24s0.11-0.24,0.24-0.24h2.06c0.13,0,0.24,0.11,0.24,0.24
					S387,29.61,386.87,29.61z M384.81,29.23c-0.08,0-0.14,0.06-0.14,0.14c0,0.08,0.06,0.14,0.14,0.14h2.06
					c0.08,0,0.14-0.06,0.14-0.14c0-0.08-0.06-0.14-0.14-0.14H384.81z"/>
			</g>
		</g>
		<g>
			<g>
				<polygon class="st_fill_13" points="385.07,29.37 384.16,28.34 385.07,27.19 385.97,27.19 385.07,28.34 385.97,29.37 385.07,30.66 
					385.97,31.69 385.07,31.69 384.15,30.66 				"/>
			</g>
			<g>
				<path d="M386.07,31.74h-1.03l-0.96-1.07l0.92-1.29l-0.9-1.03l0.94-1.21h1.02l-0.94,1.2l0.9,1.03l-0.9,1.29L386.07,31.74z
					M385.09,31.64h0.77L385,30.66l0.9-1.29l-0.9-1.03l0.86-1.11h-0.78l-0.86,1.11l0.9,1.03l-0.92,1.29L385.09,31.64z"/>
			</g>
		</g>
		<g>
			<g>
				<rect x="386.03" y="27.12" class="st_fill_19" width="0.64" height="5.53"/>
			</g>
			<g>
				<path d="M386.72,32.7h-0.74v-5.63h0.74V32.7z M386.08,32.6h0.55v-5.43h-0.55V32.6z"/>
			</g>
		</g>
	</g>
	<g class="st_fill_4">
		<g>
			<g>
				<path class="st_fill_13" d="M328.9,29.57h2.06c0.11,0,0.19-0.09,0.19-0.19l0,0c0-0.11-0.09-0.19-0.19-0.19h-2.06
					c-0.11,0-0.19,0.09-0.19,0.19l0,0C328.71,29.48,328.79,29.57,328.9,29.57z"/>
			</g>
			<g>
				<path d="M328.66,29.38c0-0.13,0.11-0.24,0.24-0.24h2.06c0.13,0,0.24,0.11,0.24,0.24s-0.11,0.24-0.24,0.24h-2.06
					C328.77,29.62,328.66,29.51,328.66,29.38z M328.9,29.23c-0.08,0-0.14,0.06-0.14,0.14s0.06,0.14,0.14,0.14h2.06
					c0.08,0,0.14-0.06,0.14-0.14s-0.06-0.14-0.14-0.14H328.9z"/>
			</g>
		</g>
		<g>
			<g>
				<polygon class="st_fill_13" points="330.7,29.38 331.6,28.35 330.7,27.19 329.8,27.19 330.7,28.35 329.8,29.38 330.7,30.66 
					329.8,31.69 330.7,31.69 331.62,30.66 				"/>
			</g>
			<g>
				<path d="M330.64,30.66l-0.9-1.29l0.9-1.03l-0.94-1.2h1.02l0.94,1.21l-0.9,1.03l0.92,1.29l-0.96,1.07h-1.03L330.64,30.66z
					M331.56,30.66l-0.92-1.29l0.9-1.03l-0.86-1.11h-0.78l0.86,1.11l-0.9,1.03l0.9,1.29l-0.86,0.98h0.77L331.56,30.66z"/>
			</g>
		</g>
		<g>
			<g>
				
					<rect x="329.09" y="27.13" transform="matrix(-1 -4.384683e-11 4.384683e-11 -1 658.8265 59.7826)" class="st_fill_19" width="0.64" height="5.53"/>
			</g>
			<g>
				<path d="M329.04,27.08h0.74v5.63h-0.74V27.08z M329.69,27.17h-0.55v5.43h0.55V27.17z"/>
			</g>
		</g>
	</g>
	<g class="st_fill_4">
		<g>
			<g>
				<path class="st_fill_13" d="M274.61,29.57h-2.06c-0.11,0-0.19-0.09-0.19-0.19l0,0c0-0.11,0.09-0.19,0.19-0.19h2.06
					c0.11,0,0.19,0.09,0.19,0.19l0,0C274.8,29.48,274.71,29.57,274.61,29.57z"/>
			</g>
			<g>
				<path d="M274.61,29.61h-2.06c-0.13,0-0.24-0.11-0.24-0.24s0.11-0.24,0.24-0.24h2.06c0.13,0,0.24,0.11,0.24,0.24
					S274.74,29.61,274.61,29.61z M272.55,29.23c-0.08,0-0.14,0.06-0.14,0.14c0,0.08,0.06,0.14,0.14,0.14h2.06
					c0.08,0,0.14-0.06,0.14-0.14c0-0.08-0.06-0.14-0.14-0.14H272.55z"/>
			</g>
		</g>
		<g>
			<g>
				<polygon class="st_fill_13" points="272.81,29.37 271.91,28.34 272.81,27.19 273.71,27.19 272.81,28.34 273.71,29.37 272.81,30.66 
					273.71,31.69 272.81,31.69 271.89,30.66 				"/>
			</g>
			<g>
				<path d="M273.81,31.74h-1.03l-0.96-1.07l0.92-1.29l-0.9-1.03l0.94-1.21h1.02l-0.94,1.2l0.9,1.03l-0.9,1.29L273.81,31.74z
					M272.83,31.64h0.77l-0.86-0.98l0.9-1.29l-0.9-1.03l0.86-1.11h-0.78l-0.86,1.11l0.9,1.03l-0.92,1.29L272.83,31.64z"/>
			</g>
		</g>
		<g>
			<g>
				<rect x="273.77" y="27.12" class="st_fill_19" width="0.64" height="5.53"/>
			</g>
			<g>
				<path d="M274.46,32.7h-0.74v-5.63h0.74V32.7z M273.82,32.6h0.55v-5.43h-0.55V32.6z"/>
			</g>
		</g>
	</g>
</g>
<g id="Jerseys">
	<g>
		<polygon class="st_fill_13" points="216.73,32.61 220.05,32.61 220.05,31.32 218.91,30.26 218.45,29.5 218.15,27.22 216.7,27.22 		"/>
		<path d="M220.2,32.77h-3.62l-0.03-5.7h1.73l0.32,2.38l0.43,0.72l1.17,1.09V32.77z M216.88,32.46h3.01v-1.07l-1.11-1.05l-0.47-0.79
			l-0.29-2.18h-1.16L216.88,32.46z"/>
	</g>
	<g>
		<polygon class="st_fill_13" points="386.75,32.61 383.43,32.61 383.43,31.32 384.57,30.26 385.02,29.5 385.33,27.22 386.77,27.22 		"/>
		<path d="M386.9,32.77h-3.62v-1.51l1.17-1.09l0.43-0.72l0.32-2.38h1.73L386.9,32.77z M383.58,32.46h3.01l0.02-5.09h-1.16
			l-0.29,2.18l-0.5,0.82l-1.09,1.02V32.46z"/>
	</g>
	<g>
		<polygon class="st_fill_13" points="305.03,32.08 305.03,33.37 298.65,33.37 298.65,32.08 299.78,31.02 300.24,30.26 300.54,27.98 
			303.13,27.98 303.44,30.26 303.89,31.02 		"/>
		<path d="M305.18,33.53h-6.69v-1.51l1.17-1.09l0.43-0.72l0.32-2.38h2.86l0.32,2.38l0.43,0.72l1.17,1.09V33.53z M298.8,33.22h6.08
			v-1.07l-1.11-1.05l-0.47-0.79L303,28.13h-2.32l-0.29,2.18l-0.5,0.82l-1.09,1.02V33.22z"/>
	</g>
	<g class="st_fill_3">
		<polygon class="st_fill_20" points="329.05,32.62 332.37,32.62 332.37,31.33 331.23,30.26 330.77,29.5 330.47,27.22 329.03,27.22 		"/>
		<path class="st_fill_4" d="M332.52,32.77h-3.62l-0.03-5.7h1.73l0.32,2.38l0.43,0.72l1.17,1.09V32.77z M329.2,32.47h3.01v-1.07
			l-1.11-1.05l-0.47-0.79l-0.29-2.18h-1.16L329.2,32.47z"/>
	</g>
	<g class="st_fill_3">
		<polygon class="st_fill_20" points="274.46,32.62 271.14,32.62 271.14,31.32 272.28,30.26 272.73,29.5 273.04,27.22 274.48,27.22 		"/>
		<path class="st_fill_4" d="M274.61,32.77h-3.62v-1.51l1.17-1.09l0.43-0.72l0.32-2.38h1.73L274.61,32.77z M271.29,32.46h3.01l0.02-5.09
			h-1.16l-0.29,2.18l-0.5,0.82l-1.09,1.02V32.46z"/>
	</g>
</g>
<g id="Arrows">
</g>
<g id="Text">
</g>
<g id="Grass">
	<g>
		<path class="st_fill_21" d="M305.96,33.45l26.49-1.01c0,0-0.02-0.04,0.03-0.11c0,0.08-0.49-0.41-1.23-0.33c-0.38-0.02-0.83,0.1-1.35,0.1
			c-0.53-0.12-1.11,0.15-1.76,0.05c-0.32,0.03-0.64,0.29-1,0.02c-0.36-0.35-0.71,0.02-1.07,0.06c-0.36,0.16-0.75-0.07-1.13,0.07
			c-0.4-0.32-0.79-0.14-1.18,0.06c-0.38,0.46-0.8,0.28-1.23-0.03c-0.42-0.05-0.83,0.06-1.25,0.07c-0.43-0.16-0.86-0.11-1.28,0.04
			c-0.43,0.05-0.86,0.03-1.28,0.16c-0.42,0.2-0.86-0.11-1.28,0.18c-0.42,0.1-0.87-0.22-1.28-0.03c-0.42,0.02-0.85-0.1-1.26-0.03
			c-0.41,0.15-0.82,0.14-1.22,0.11c-0.81-0.06-1.59-0.2-2.32,0.15c-0.73,0.18-1.43,0.1-2.08,0.06c-0.65-0.14-1.23,0.09-1.76,0.08
			c-0.53-0.19-0.97,0.19-1.35,0.07c-0.75,0.09-1.18,0-1.18,0.06C304.32,33.52,305.96,33.45,305.96,33.45z"/>
		<path d="M305.5,33.5c-0.37,0-0.7-0.03-0.72-0.12c-0.01-0.06,0.07-0.11,0.16-0.14l0,0c0.03-0.03,0.07-0.03,0.24-0.02
			c0.2,0,0.52,0.01,0.95-0.04l0.01,0l0.01,0c0.16,0.05,0.34,0.01,0.53-0.04c0.24-0.06,0.51-0.13,0.81-0.02c0,0,0,0,0,0
			c0.19,0,0.38-0.03,0.59-0.06c0.37-0.06,0.75-0.11,1.17-0.02c0.07,0,0.15,0.01,0.22,0.01c0.59,0.04,1.2,0.08,1.84-0.07
			c0.61-0.3,1.27-0.24,1.96-0.18c0.12,0.01,0.25,0.02,0.37,0.03c0.39,0.03,0.8,0.04,1.21-0.11c0.25-0.05,0.5-0.02,0.74,0
			c0.17,0.02,0.35,0.03,0.52,0.02c0.24-0.11,0.48-0.06,0.71-0.01c0.19,0.04,0.38,0.08,0.56,0.04c0.23-0.16,0.47-0.14,0.71-0.12
			c0.2,0.01,0.39,0.03,0.57-0.06c0.29-0.09,0.58-0.11,0.87-0.13c0.14-0.01,0.28-0.02,0.43-0.04c0.48-0.17,0.9-0.19,1.29-0.04
			c0.15-0.01,0.31-0.02,0.45-0.04c0.26-0.03,0.53-0.06,0.8-0.03l0.01,0l0.01,0.01c0.39,0.29,0.8,0.49,1.18,0.04l0.01-0.01
			c0.37-0.18,0.79-0.39,1.21-0.07c0.19-0.06,0.38-0.04,0.56-0.02c0.19,0.02,0.38,0.04,0.55-0.04l0.01,0
			c0.11-0.01,0.22-0.06,0.33-0.1c0.25-0.1,0.5-0.2,0.77,0.05c0.23,0.17,0.45,0.11,0.68,0.04c0.1-0.03,0.2-0.06,0.29-0.07l0,0l0,0
			c0.32,0.05,0.61,0.01,0.9-0.03c0.3-0.04,0.58-0.08,0.86-0.02c0.26,0,0.5-0.03,0.73-0.06c0.22-0.03,0.43-0.05,0.62-0.04
			c0.55-0.06,0.96,0.19,1.14,0.29c0.03,0.02,0.05,0.03,0.07,0.04l0.05-0.07l0.01,0.13l-0.02,0.02c-0.02,0.03-0.02,0.06-0.02,0.06
			l0.01,0.05l-0.05,0l-26.49,1.01C305.81,33.5,305.65,33.5,305.5,33.5z M304.86,33.37c0.08,0.05,0.62,0.07,1.1,0.05h0l26.45-1.01
			c0-0.01,0-0.02,0.01-0.04c-0.02-0.01-0.04-0.02-0.07-0.04c-0.19-0.11-0.58-0.34-1.1-0.28c-0.19-0.01-0.39,0.02-0.61,0.04
			c-0.22,0.03-0.48,0.06-0.75,0.06c-0.27-0.06-0.55-0.02-0.84,0.02c-0.29,0.04-0.59,0.08-0.92,0.04c-0.09,0.01-0.18,0.04-0.28,0.07
			c-0.23,0.07-0.48,0.15-0.75-0.05c-0.23-0.22-0.45-0.14-0.69-0.04c-0.11,0.04-0.23,0.09-0.35,0.11c-0.19,0.08-0.39,0.06-0.59,0.04
			c-0.18-0.02-0.36-0.04-0.54,0.03l-0.02,0.01l-0.02-0.01c-0.39-0.31-0.77-0.12-1.14,0.06c-0.41,0.49-0.87,0.26-1.27-0.02
			c-0.26-0.03-0.51,0-0.77,0.03c-0.15,0.02-0.31,0.04-0.47,0.04l-0.01,0l-0.01,0c-0.37-0.14-0.77-0.13-1.25,0.04
			c-0.15,0.02-0.3,0.03-0.44,0.04c-0.28,0.02-0.57,0.04-0.84,0.12c-0.2,0.09-0.4,0.08-0.6,0.06c-0.23-0.02-0.45-0.03-0.66,0.11
			l-0.01,0.01c-0.2,0.05-0.4,0.01-0.6-0.03c-0.24-0.05-0.46-0.1-0.67,0.01l-0.01,0c-0.18,0.01-0.36-0.01-0.54-0.02
			c-0.25-0.02-0.48-0.04-0.72,0c-0.42,0.15-0.84,0.14-1.23,0.11c-0.12-0.01-0.25-0.02-0.37-0.03c-0.68-0.06-1.32-0.11-1.93,0.18
			c-0.66,0.16-1.27,0.12-1.87,0.08c-0.08-0.01-0.15-0.01-0.23-0.02c-0.41-0.09-0.78-0.03-1.14,0.02c-0.21,0.03-0.41,0.06-0.61,0.06
			l-0.01,0c-0.28-0.1-0.53-0.04-0.78,0.02c-0.2,0.05-0.39,0.1-0.57,0.04c-0.43,0.05-0.76,0.04-0.96,0.04c-0.08,0-0.15,0-0.18,0l0,0
			l-0.03,0.01C304.9,33.34,304.87,33.36,304.86,33.37z"/>
	</g>
	<g>
		<path class="st_fill_21" d="M297.62,33.45l-26.49-1.01c0,0,0.02-0.04-0.03-0.11c0,0.08,0.49-0.41,1.23-0.33c0.38-0.02,0.83,0.1,1.35,0.1
			c0.53-0.12,1.11,0.15,1.76,0.05c0.32,0.03,0.64,0.29,1,0.02c0.36-0.35,0.71,0.02,1.07,0.06c0.36,0.16,0.75-0.07,1.13,0.07
			c0.4-0.32,0.79-0.14,1.18,0.06c0.38,0.46,0.8,0.28,1.23-0.03c0.42-0.05,0.83,0.06,1.25,0.07c0.43-0.16,0.86-0.11,1.28,0.04
			c0.43,0.05,0.86,0.03,1.28,0.16c0.42,0.2,0.86-0.11,1.28,0.18c0.42,0.1,0.87-0.22,1.28-0.03c0.42,0.02,0.85-0.1,1.26-0.03
			c0.41,0.15,0.82,0.14,1.22,0.11c0.81-0.06,1.59-0.2,2.32,0.15c0.73,0.18,1.43,0.1,2.08,0.06c0.65-0.14,1.23,0.09,1.76,0.08
			c0.53-0.19,0.97,0.19,1.35,0.07c0.75,0.09,1.18,0,1.18,0.06C299.26,33.52,297.62,33.45,297.62,33.45z"/>
		<path d="M298.09,33.5c-0.15,0-0.31,0-0.46-0.01l-26.54-1.01l0.02-0.05l0,0c0,0,0-0.02-0.01-0.05l-0.02-0.01l0-0.03l0.01-0.11
			l0.05,0.07c0.02-0.01,0.04-0.02,0.07-0.04c0.18-0.11,0.6-0.35,1.15-0.29c0.18-0.01,0.39,0.02,0.61,0.04
			c0.23,0.03,0.48,0.06,0.74,0.05c0.27-0.06,0.55-0.02,0.85,0.02c0.29,0.04,0.59,0.08,0.9,0.03l0,0l0,0c0.1,0.01,0.2,0.04,0.29,0.07
			c0.23,0.07,0.45,0.14,0.68-0.04c0.26-0.25,0.52-0.15,0.77-0.05c0.11,0.04,0.22,0.09,0.33,0.1l0.01,0
			c0.18,0.08,0.36,0.06,0.55,0.04c0.18-0.02,0.37-0.04,0.56,0.02c0.42-0.32,0.84-0.12,1.21,0.07l0.01,0.01
			c0.37,0.45,0.79,0.25,1.18-0.04l0.01-0.01l0.01,0c0.27-0.03,0.54,0,0.8,0.03c0.15,0.02,0.3,0.04,0.45,0.04
			c0.39-0.15,0.8-0.13,1.3,0.04c0.13,0.02,0.28,0.03,0.42,0.03c0.28,0.02,0.57,0.04,0.86,0.12c0.19,0.09,0.38,0.08,0.58,0.06
			c0.23-0.02,0.47-0.04,0.71,0.12c0.18,0.04,0.36,0,0.56-0.04c0.24-0.05,0.48-0.1,0.71,0.01c0.17,0.01,0.35-0.01,0.52-0.02
			c0.24-0.02,0.49-0.05,0.74,0c0.41,0.15,0.83,0.14,1.21,0.11c0.12-0.01,0.25-0.02,0.37-0.03c0.69-0.06,1.35-0.11,1.97,0.19
			c0.63,0.15,1.24,0.11,1.83,0.07c0.08-0.01,0.15-0.01,0.23-0.02c0.41-0.09,0.79-0.03,1.16,0.02c0.21,0.03,0.4,0.06,0.59,0.06
			c0,0,0,0,0,0c0.3-0.11,0.57-0.04,0.81,0.02c0.19,0.05,0.37,0.1,0.53,0.04l0.01,0l0.01,0c0.43,0.05,0.76,0.04,0.95,0.04
			c0.17,0,0.22,0,0.24,0.02l0,0c0.09,0.04,0.17,0.08,0.16,0.14C298.78,33.48,298.46,33.5,298.09,33.5z M271.18,32.41l26.45,1.01
			c0.47,0.02,1.01,0.01,1.1-0.05c-0.01-0.01-0.04-0.03-0.12-0.06l-0.03-0.01l0,0c-0.03,0-0.1,0-0.18,0c-0.2,0-0.52,0.01-0.96-0.04
			c-0.18,0.06-0.37,0.01-0.57-0.04c-0.24-0.06-0.49-0.13-0.78-0.02l-0.01,0c-0.2,0-0.4-0.03-0.61-0.06
			c-0.36-0.05-0.73-0.11-1.14-0.02c-0.08,0.01-0.16,0.01-0.23,0.02c-0.6,0.04-1.21,0.09-1.86-0.07c-0.61-0.29-1.25-0.24-1.93-0.18
			c-0.12,0.01-0.25,0.02-0.37,0.03c-0.39,0.03-0.82,0.04-1.24-0.11c-0.23-0.04-0.46-0.02-0.71,0c-0.18,0.02-0.36,0.03-0.54,0.02
			l-0.01,0c-0.22-0.1-0.44-0.06-0.67-0.01c-0.2,0.04-0.4,0.08-0.6,0.03l-0.01-0.01c-0.21-0.15-0.43-0.13-0.66-0.11
			c-0.2,0.01-0.41,0.03-0.61-0.07c-0.27-0.08-0.56-0.1-0.84-0.12c-0.14-0.01-0.29-0.02-0.43-0.04c-0.49-0.17-0.88-0.19-1.26-0.04
			l-0.01,0l-0.01,0c-0.16-0.01-0.32-0.02-0.47-0.04c-0.26-0.03-0.51-0.06-0.77-0.03c-0.4,0.29-0.86,0.51-1.27,0.02
			c-0.37-0.18-0.75-0.37-1.14-0.06l-0.02,0.01l-0.02-0.01c-0.18-0.07-0.36-0.05-0.54-0.03c-0.19,0.02-0.39,0.04-0.59-0.04
			c-0.12-0.01-0.24-0.06-0.35-0.11c-0.25-0.1-0.46-0.18-0.69,0.04c-0.27,0.2-0.53,0.12-0.76,0.05c-0.1-0.03-0.19-0.06-0.27-0.07
			c-0.32,0.05-0.63,0.01-0.92-0.04c-0.29-0.04-0.57-0.08-0.83-0.02c-0.28,0-0.53-0.03-0.76-0.05c-0.22-0.03-0.42-0.05-0.6-0.04
			c-0.53-0.06-0.92,0.17-1.11,0.28c-0.03,0.02-0.05,0.03-0.07,0.04C271.17,32.38,271.18,32.4,271.18,32.41z"/>
	</g>
</g>
<g id="Lines">
</g>
</svg>
</template>
<script>
export default {
	name: "SvgEarthworkFill2LaneMetalGuard2",
	props: {
		paramSelected: undefined,
	},
	};
</script>
<style scoped>
	.st_fill_0{fill:none;}
	.st_fill_1{fill:#8FCE57;stroke:#000000;stroke-width:0.75;stroke-miterlimit:10;}
	.st_fill_2{fill:#875116;stroke:#000000;stroke-width:0.75;stroke-miterlimit:10;}
	.st_fill_3{display:none;}
	.st_fill_4{display:inline;}
	.st_fill_5{fill:#02151E;stroke:#8ADB37;stroke-miterlimit:10;}
	.st_fill_6{fill:#A07220;}
	.st_fill_7{font-family:'Roboto-Bold';}
	.st_fill_8{font-size:18px;}
	.st_fill_9{fill:#FFFFFF;}
	.st_fill_10{fill:none;stroke:#000000;stroke-miterlimit:10;}
	.st_fill_11{fill:none;stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
	.st_fill_12{fill:none;stroke:#000000;stroke-width:0.5;stroke-miterlimit:10;}
	.st_fill_13{fill:#686868;}
	.st_fill_14{fill:#7C7C7C;}
	.st_fill_15{fill:#2D190F;}
	.st_fill_16{fill:#DD8F64;}
	.st_fill_17{fill:#7C503A;}
	.st_fill_18{fill:#3E2D00;stroke:#000000;stroke-width:0.75;stroke-miterlimit:10;}
	.st_fill_19{fill:#72502F;}
	.st_fill_20{display:inline;fill:#686868;}
	.st_fill_21{fill:#8FCE57;}
</style>