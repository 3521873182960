<template>
  <div style="overflow-x: hidden; overflow-y: hidden">
    <v-row>
      <v-col style="font-size: 20px; text-align: center"  id="ValuesParamsEarthSVG3Title">
        <strong> {{ selectedLanguage['earthworkCut1_component_text1'] }} </strong>
      </v-col>
    </v-row>
    <SvgEarthworkCut2LaneComponent v-if="params.central_barrier==this.selectedLanguage['earthworkCut1_component_text2'] && params.side_barrier==this.selectedLanguage['earthworkCut1_component_text2']" :paramSelected="paramSelected" id="ValuesParamsPlatformSVG1Platform2"/>
    <SvgEarthworkCut2LaneJersey v-else-if="params.central_barrier==this.selectedLanguage['earthworkCut1_component_text3'] && params.side_barrier==this.selectedLanguage['earthworkCut1_component_text2']" :paramSelected="paramSelected" id="ValuesParamsPlatformSVG1Platform2"/>
    <SvgEarthworkCut2LaneMetalGuard v-else-if="params.central_barrier==this.selectedLanguage['earthworkCut1_component_text4'] && params.side_barrier==this.selectedLanguage['earthworkCut1_component_text2']" :paramSelected="paramSelected" id="ValuesParamsPlatformSVG1Platform2"/>
    <SvgEarthworkCut2LaneJersey v-else-if="params.central_barrier==this.selectedLanguage['earthworkCut1_component_text3'] && params.side_barrier==this.selectedLanguage['earthworkCut1_component_text3']" :paramSelected="paramSelected" id="ValuesParamsPlatformSVG1Platform2"/>
    <SvgEarthworkCut2LaneMetalGuard v-else-if="params.central_barrier==this.selectedLanguage['earthworkCut1_component_text4'] && params.side_barrier==this.selectedLanguage['earthworkCut1_component_text4']" :paramSelected="paramSelected" id="ValuesParamsPlatformSVG1Platform2"/>
    <SvgEarthworkCut2LaneComponent v-else-if="params.central_barrier==this.selectedLanguage['earthworkCut1_component_text2'] && params.side_barrier==this.selectedLanguage['earthworkCut1_component_text3']" :paramSelected="paramSelected" id="ValuesParamsPlatformSVG1Platform2"/>
    <SvgEarthworkCut2LaneComponent v-else-if="params.central_barrier==this.selectedLanguage['earthworkCut1_component_text2'] && params.side_barrier==this.selectedLanguage['earthworkCut1_component_text4']" :paramSelected="paramSelected" id="ValuesParamsPlatformSVG1Platform2"/>
    <SvgEarthworkCut2LaneMetalGuard v-else-if="params.central_barrier==this.selectedLanguage['earthworkCut1_component_text4'] && params.side_barrier==this.selectedLanguage['earthworkCut1_component_text3']" :paramSelected="paramSelected" id="ValuesParamsPlatformSVG1Platform2"/>
    <SvgEarthworkCut2LaneJersey v-else-if="params.central_barrier==this.selectedLanguage['earthworkCut1_component_text3'] && params.side_barrier==this.selectedLanguage['earthworkCut1_component_text4']" :paramSelected="paramSelected" id="ValuesParamsPlatformSVG1Platform2"/>
    <v-row>
      <v-col class="formCardStyle">
        <v-row id="ValuesParamsEarthworkCutHeight">
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text5'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="paramSelected.values_params.earthwork.cut_height"
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkCutFactor">
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text6'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="paramSelected.values_params.earthwork.cut_factor"
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text7'] }} </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkCutEnbankmentSlope">
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text8'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                paramSelected.values_params.earthwork.cut_enbankment_slope
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> % </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkCutEnbankmentWidth">
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text9'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                paramSelected.values_params.earthwork.cut_enbankment_width
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkSecondCatRate">
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text10'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                paramSelected.values_params.earthwork.second_cat_rate
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text11'] }} </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkThirdCatRate">
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text12'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                paramSelected.values_params.earthwork.third_cat_rate
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> {{ selectedLanguage['earthworkCut1_component_text11'] }} </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SvgEarthworkCut2LaneComponent from "@/components/SvgComponents/roadwayDefaults/earthworkCut/SvgEarthworkCut2LaneComponent.vue";
import SvgEarthworkCut2LaneJersey from "@/components/SvgComponents/roadwayDefaults/earthworkCut/SvgEarthworkCut2LaneJersey.vue";
import SvgEarthworkCut2LaneMetalGuard from "@/components/SvgComponents/roadwayDefaults/earthworkCut/SvgEarthworkCut2LaneMetalGuard.vue";
import { portuguese } from "../../../../languages/pt-br";
import { english } from "../../../../languages/en";

export default {
  props: {
    params: {
      type: Object,
    }
  },
  components: {
    SvgEarthworkCut2LaneComponent,
    SvgEarthworkCut2LaneJersey,
    SvgEarthworkCut2LaneMetalGuard
  },
  computed: {
    ...mapGetters(["paramSelected","profile"]),
    selectedLanguage() {
        if (this.profile['language'] === 'en') {
          return english;
        } else {
          return portuguese;
        }
      },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped></style>
