<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	viewBox="0 0 1271.49 281.87" style="enable-background:new 0 0 1271.49 281.87;" xml:space="preserve">
<g id="Canteiro">
	<polygon class="st_structure_0" points="646.18,135.83 646.18,169.25 592.38,169.25 592.38,135.95 598.38,136.08 598.38,141.77 640.19,141.77 
		640.19,136.33 	"/>
	<g>
		<g>
			<line class="st_structure_1" x1="592.68" y1="91.61" x2="646.63" y2="91.61"/>
			<g>
				<rect x="592.18" y="88.36" width="1" height="6.5"/>
			</g>
			<g>
				<rect x="646.13" y="88.36" width="1" height="6.5"/>
			</g>
		</g>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 609.0412 85.8618)" class="st_structure_2 st_structure_3">1 m</text>
	</g>
	<polyline class="st_structure_4" points="61.12,120.25 61.12,128.25 82.93,128.25 	"/>
	<polyline class="st_structure_5" points="1154.59,128.25 1176.4,128.25 1176.4,120.25 	"/>
	<polygon class="st_structure_0" points="646.86,135.83 646.86,169.25 593.06,169.25 593.06,135.95 599.05,136.08 640.87,136.33 	"/>
</g>
<g id="Sub_x5F_base">
	<polygon class="st_structure_6" points="452.89,150.29 250.46,146.29 49.41,150.29 60.48,131.29 250.46,127.29 441.82,131.29 	"/>
	<polygon class="st_structure_7" points="463.83,169.29 250.46,165.29 38.47,169.29 49.13,150.29 250.46,146.29 453.16,150.29 	"/>
	<polygon class="st_structure_6" points="1188.61,150.29 986.18,146.29 785.13,150.29 796.2,131.29 986.18,127.29 1177.54,131.29 	"/>
	<polygon class="st_structure_7" points="1199.55,169.29 986.18,165.29 774.19,169.29 784.85,150.29 986.18,146.29 1188.88,150.29 	"/>
</g>
<g id="Land">
	<polygon class="st_structure_7" points="820.28,129.9 784.28,169.03 784.08,169.25 452.11,169.25 451.94,169.25 417.09,129.9 592.39,135.89 
		592.39,147.29 646.98,147.29 646.98,135.89 	"/>
</g>
<g id="Asphalt_x5F_and_components">
	<polygon points="417.42,125.72 250.18,121.72 82.93,125.72 82.93,130.72 250.18,126.72 417.42,130.72 	"/>
	<polygon points="1154.84,125.72 987.59,121.72 820.35,125.72 820.35,130.72 987.59,126.72 1154.84,130.72 	"/>
</g>
<g id="Metal_x5F_guard">
	<g class="st_structure_8">
		<g class="st_structure_9">
			<g>
				<path class="st_structure_10" d="M615.48,115.1h-13.53c-0.7,0-1.27-0.57-1.27-1.27v0c0-0.7,0.57-1.27,1.27-1.27h13.53
					c0.7,0,1.27,0.57,1.27,1.27v0C616.75,114.53,616.18,115.1,615.48,115.1z"/>
				<path d="M615.48,115.42h-13.53c-0.87,0-1.59-0.71-1.59-1.59c0-0.87,0.71-1.59,1.59-1.59h13.53c0.87,0,1.59,0.71,1.59,1.59
					C617.07,114.71,616.35,115.42,615.48,115.42z M601.95,112.88c-0.52,0-0.95,0.43-0.95,0.95c0,0.52,0.43,0.95,0.95,0.95h13.53
					c0.52,0,0.95-0.43,0.95-0.95c0-0.52-0.43-0.95-0.95-0.95H601.95z"/>
			</g>
			<g>
				<polygon class="st_structure_10" points="603.64,113.83 597.72,107.07 603.64,99.46 609.56,99.46 603.64,107.07 609.56,113.83 
					603.64,122.29 609.56,129.06 603.64,129.06 597.6,122.29 				"/>
				<path d="M610.26,129.37h-6.76l-6.3-7.06l6.04-8.46l-5.93-6.77l6.18-7.94h6.72l-6.16,7.92l5.91,6.76l-5.92,8.46L610.26,129.37z
					M603.78,128.74h5.08l-5.62-6.43l5.92-8.46l-5.93-6.78l5.68-7.31h-5.12l-5.66,7.28l5.91,6.76l-6.04,8.45L603.78,128.74z"/>
			</g>
			<g>
				<rect x="609.98" y="99.03" class="st_structure_11" width="4.23" height="36.37"/>
				<path d="M614.53,135.72h-4.86v-37h4.86V135.72z M610.3,135.08h3.59V99.35h-3.59V135.08z"/>
			</g>
		</g>
		<g class="st_structure_9">
			<g>
				<path class="st_structure_10" d="M622.78,115.1h13.53c0.7,0,1.27-0.57,1.27-1.27v0c0-0.7-0.57-1.27-1.27-1.27h-13.53
					c-0.7,0-1.27,0.57-1.27,1.27v0C621.51,114.53,622.08,115.1,622.78,115.1z"/>
				<path d="M621.19,113.83c0-0.87,0.71-1.59,1.59-1.59h13.53c0.87,0,1.59,0.71,1.59,1.59c0,0.87-0.71,1.59-1.59,1.59h-13.53
					C621.9,115.42,621.19,114.71,621.19,113.83z M622.78,112.88c-0.52,0-0.95,0.43-0.95,0.95c0,0.52,0.43,0.95,0.95,0.95h13.53
					c0.52,0,0.95-0.43,0.95-0.95c0-0.52-0.43-0.95-0.95-0.95H622.78z"/>
			</g>
			<g>
				<polygon class="st_structure_10" points="634.62,113.83 640.54,107.07 634.62,99.46 628.7,99.46 634.62,107.07 628.7,113.83 634.62,122.29 
					628.7,129.06 634.62,129.06 640.66,122.29 				"/>
				<path d="M634.22,122.27l-5.92-8.46l5.91-6.76l-6.16-7.92h6.72l6.18,7.94l-5.93,6.77l6.04,8.46l-6.3,7.06H628L634.22,122.27z
					M640.25,122.27l-6.04-8.45l5.91-6.76l-5.66-7.28h-5.12l5.68,7.31l-5.93,6.78l5.92,8.46l-5.62,6.43h5.08L640.25,122.27z"/>
			</g>
			<g>
				
					<rect x="624.05" y="99.03" transform="matrix(-1 -4.516755e-11 4.516755e-11 -1 1252.322 234.4321)" class="st_structure_11" width="4.23" height="36.37"/>
				<path d="M623.73,98.72h4.86v37h-4.86V98.72z M627.96,99.35h-3.59v35.73h3.59V99.35z"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<path class="st_structure_10" d="M58.83,110.1h13.53c0.7,0,1.27-0.57,1.27-1.27v0c0-0.7-0.57-1.27-1.27-1.27H58.83
				c-0.7,0-1.27,0.57-1.27,1.27v0C57.56,109.53,58.13,110.1,58.83,110.1z"/>
			<path d="M57.25,108.83c0-0.87,0.71-1.59,1.59-1.59h13.53c0.87,0,1.59,0.71,1.59,1.59c0,0.87-0.71,1.59-1.59,1.59H58.83
				C57.96,110.42,57.25,109.71,57.25,108.83z M58.83,107.88c-0.52,0-0.95,0.43-0.95,0.95c0,0.52,0.43,0.95,0.95,0.95h13.53
				c0.52,0,0.95-0.43,0.95-0.95c0-0.52-0.43-0.95-0.95-0.95H58.83z"/>
		</g>
		<g>
			<polygon class="st_structure_10" points="70.67,108.83 76.59,102.07 70.67,94.46 64.75,94.46 70.67,102.07 64.75,108.83 70.67,117.29 
				64.75,124.06 70.67,124.06 76.71,117.29 			"/>
			<path d="M70.27,117.27l-5.92-8.46l5.91-6.76l-6.16-7.92h6.72l6.18,7.94l-5.93,6.77l6.04,8.46l-6.3,7.06h-6.76L70.27,117.27z
				M76.31,117.27l-6.04-8.45l5.91-6.76l-5.66-7.28H65.4l5.68,7.31l-5.93,6.78l5.92,8.46l-5.62,6.43h5.08L76.31,117.27z"/>
		</g>
		<g>
			
				<rect x="60.1" y="94.03" transform="matrix(-1 -4.495261e-11 4.495261e-11 -1 124.4294 224.4321)" class="st_structure_11" width="4.23" height="36.37"/>
			<path d="M59.78,93.72h4.86v37h-4.86V93.72z M64.01,94.35h-3.59v35.73h3.59V94.35z"/>
		</g>
	</g>
	<g>
		<g>
			<path class="st_structure_10" d="M1178.67,110.1h-13.53c-0.7,0-1.27-0.57-1.27-1.27v0c0-0.7,0.57-1.27,1.27-1.27h13.53
				c0.7,0,1.27,0.57,1.27,1.27v0C1179.94,109.53,1179.37,110.1,1178.67,110.1z"/>
			<path d="M1178.67,110.42h-13.53c-0.87,0-1.59-0.71-1.59-1.59c0-0.87,0.71-1.59,1.59-1.59h13.53c0.87,0,1.59,0.71,1.59,1.59
				C1180.25,109.71,1179.54,110.42,1178.67,110.42z M1165.14,107.88c-0.52,0-0.95,0.43-0.95,0.95c0,0.52,0.43,0.95,0.95,0.95h13.53
				c0.52,0,0.95-0.43,0.95-0.95c0-0.52-0.43-0.95-0.95-0.95H1165.14z"/>
		</g>
		<g>
			<polygon class="st_structure_10" points="1166.83,108.83 1160.91,102.07 1166.83,94.46 1172.75,94.46 1166.83,102.07 1172.75,108.83 
				1166.83,117.29 1172.75,124.06 1166.83,124.06 1160.79,117.29 			"/>
			<path d="M1173.45,124.37h-6.76l-6.3-7.06l6.04-8.46l-5.93-6.77l6.18-7.94h6.72l-6.16,7.92l5.91,6.76l-5.92,8.46L1173.45,124.37z
				M1166.97,123.74h5.08l-5.62-6.43l5.92-8.46l-5.93-6.78l5.68-7.31h-5.12l-5.66,7.28l5.91,6.76l-6.04,8.45L1166.97,123.74z"/>
		</g>
		<g>
			<rect x="1173.17" y="94.03" class="st_structure_11" width="4.23" height="36.37"/>
			<path d="M1177.72,130.72h-4.86v-37h4.86V130.72z M1173.49,130.08h3.59V94.35h-3.59V130.08z"/>
		</g>
	</g>
	<g>
		<g>
			<path class="st_structure_10" d="M797.47,110.13H811c0.7,0,1.27-0.57,1.27-1.27l0,0c0-0.7-0.57-1.27-1.27-1.27h-13.53
				c-0.7,0-1.27,0.57-1.27,1.27l0,0C796.2,109.57,796.77,110.13,797.47,110.13z"/>
			<path d="M795.89,108.86c0-0.87,0.71-1.59,1.59-1.59H811c0.87,0,1.59,0.71,1.59,1.59s-0.71,1.59-1.59,1.59h-13.53
				C796.6,110.45,795.89,109.74,795.89,108.86z M797.47,107.91c-0.52,0-0.95,0.43-0.95,0.95s0.43,0.95,0.95,0.95H811
				c0.52,0,0.95-0.43,0.95-0.95s-0.43-0.95-0.95-0.95H797.47z"/>
		</g>
		<g>
			<polygon class="st_structure_10" points="809.31,108.86 815.23,102.1 809.31,94.49 803.39,94.49 809.31,102.1 803.39,108.86 809.31,117.32 
				803.39,124.09 809.31,124.09 815.35,117.32 			"/>
			<path d="M808.91,117.3l-5.92-8.46l5.91-6.76l-6.16-7.92h6.72l6.18,7.94l-5.93,6.77l6.04,8.46l-6.3,7.06h-6.76L808.91,117.3z
				M814.95,117.3l-6.04-8.45l5.91-6.76l-5.66-7.28h-5.12l5.68,7.31l-5.93,6.78l5.92,8.46l-5.62,6.43h5.08L814.95,117.3z"/>
		</g>
		<g>
			
				<rect x="798.74" y="94.06" transform="matrix(-1 -4.495261e-11 4.495261e-11 -1 1601.7108 224.495)" class="st_structure_11" width="4.23" height="36.37"/>
			<path d="M798.42,93.75h4.86v37h-4.86V93.75z M802.65,94.38h-3.59v35.73h3.59V94.38z"/>
		</g>
	</g>
	<g>
		<g>
			<path class="st_structure_10" d="M440.45,110.11h-13.53c-0.7,0-1.27-0.57-1.27-1.27v0c0-0.7,0.57-1.27,1.27-1.27h13.53
				c0.7,0,1.27,0.57,1.27,1.27v0C441.72,109.54,441.15,110.11,440.45,110.11z"/>
			<path d="M440.45,110.43h-13.53c-0.87,0-1.59-0.71-1.59-1.59c0-0.87,0.71-1.59,1.59-1.59h13.53c0.87,0,1.59,0.71,1.59,1.59
				C442.04,109.72,441.33,110.43,440.45,110.43z M426.92,107.89c-0.52,0-0.95,0.43-0.95,0.95c0,0.52,0.43,0.95,0.95,0.95h13.53
				c0.52,0,0.95-0.43,0.95-0.95c0-0.52-0.43-0.95-0.95-0.95H426.92z"/>
		</g>
		<g>
			<polygon class="st_structure_10" points="428.61,108.84 422.69,102.08 428.61,94.46 434.53,94.46 428.61,102.08 434.53,108.84 428.61,117.3 
				434.53,124.06 428.61,124.06 422.57,117.3 			"/>
			<path d="M435.23,124.38h-6.76l-6.3-7.06l6.04-8.46l-5.93-6.77l6.18-7.94h6.72l-6.16,7.92l5.91,6.76l-5.92,8.46L435.23,124.38z
				M428.75,123.75h5.08l-5.62-6.43l5.92-8.46l-5.93-6.78l5.68-7.31h-5.12l-5.66,7.28l5.91,6.76l-6.04,8.45L428.75,123.75z"/>
		</g>
		<g>
			<rect x="434.95" y="94.04" class="st_structure_11" width="4.23" height="36.37"/>
			<path d="M439.5,130.72h-4.86v-37h4.86V130.72z M435.27,130.09h3.59V94.36h-3.59V130.09z"/>
		</g>
	</g>
</g>
<g id="Jerseys" class="st_structure_8">
	<polygon class="st_structure_12" points="59.83,130.15 81.64,130.15 81.64,121.65 74.16,114.65 71.16,109.65 69.16,94.65 59.66,94.65 	"/>
	<polygon class="st_structure_12" points="1177.89,130.15 1156.08,130.15 1156.08,121.65 1163.55,114.65 1166.55,109.65 1168.55,94.65 
		1178.05,94.65 	"/>
	<polygon class="st_structure_12" points="640.5,126.65 640.5,135.15 598.53,135.15 598.53,126.65 606,119.65 609,114.65 611,99.65 
		628.03,99.65 630.03,114.65 633.03,119.65 	"/>
	<polygon class="st_structure_12" points="798.47,130.18 820.28,130.18 820.28,121.68 812.8,114.68 809.8,109.68 807.8,94.68 798.3,94.68 	"/>
	<polygon class="st_structure_12" points="439.46,130.16 417.64,130.16 417.64,121.66 425.12,114.66 428.12,109.66 430.12,94.66 439.62,94.66 	
		"/>
</g>
<g id="Arrows">
	<g>
		<g>
			<g>
				<line class="st_structure_13" x1="225.83" y1="112.4" x2="176.8" y2="113.38"/>
				<g>
					<polygon points="178.75,111.65 177.09,113.37 178.82,115.04 177.38,115.06 175.65,113.4 177.31,111.67 					"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<line class="st_structure_13" x1="255.09" y1="112.4" x2="304.13" y2="113.38"/>
				<g>
					<polygon points="302.1,115.04 303.83,113.37 302.17,111.65 303.61,111.67 305.27,113.4 303.54,115.06 					"/>
				</g>
			</g>
		</g>
	</g>
	<g>
		<g>
			<g>
				<line class="st_structure_13" x1="975.24" y1="112.4" x2="926.21" y2="113.38"/>
				<g>
					<polygon points="928.17,111.65 926.5,113.37 928.23,115.04 926.79,115.06 925.07,113.4 926.73,111.67 					"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<line class="st_structure_13" x1="1004.51" y1="112.4" x2="1053.54" y2="113.38"/>
				<g>
					<polygon points="1051.51,115.04 1053.24,113.37 1051.58,111.65 1053.02,111.67 1054.68,113.4 1052.95,115.06 					"/>
				</g>
			</g>
		</g>
	</g>
</g>
<g id="Text">
	<g>
		<text transform="matrix(1 0 0 1 1222.1713 129.2983)" class="st_structure_2 st_structure_3">{{ (Number(this.paramSelected.values_params.superstructure.first_asphalt_layer_depth)+Number(this.paramSelected.values_params.superstructure.second_asphalt_layer_depth)) }}m</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 1222.1713 144.2983)" class="st_structure_2 st_structure_3">{{ paramSelected.values_params.superstructure.base_height }}m</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 1222.1713 164.2983)" class="st_structure_2 st_structure_3">{{ paramSelected.values_params.superstructure.subbase_height }}m</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 118.7131 59.1373)" class="st_structure_2 st_structure_3">{{ paramSelected.values_params.platform.sholder }}m</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 278.1415 59.1373)" class="st_structure_2 st_structure_3">{{ (paramSelected.values_params.platform.lanes*(paramSelected.values_params.platform.lane)) }}m</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 200.6306 106.1262)" class="st_structure_2 st_structure_3">3 %</text>
		<text transform="matrix(1 0 0 1 264.6306 106.1262)" class="st_structure_2 st_structure_3">3 %</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 1092.7639 59.1373)" class="st_structure_2 st_structure_3">{{ paramSelected.values_params.platform.sholder }}m</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 932.2606 59.1373)" class="st_structure_2 st_structure_3">{{ (paramSelected.values_params.platform.lanes*(paramSelected.values_params.platform.lane)) }}m</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 950.0431 106.1262)" class="st_structure_2 st_structure_3">3 %</text>
		<text transform="matrix(1 0 0 1 1014.0431 106.1262)" class="st_structure_2 st_structure_3">3 %</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 607.3328 59.137)" class="st_structure_2 st_structure_3">{{ paramSelected.values_params.platform.median_width }}m</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 606.2336 23.7075)" class="st_structure_2 st_structure_3">{{ ((12)+(2)+(2*paramSelected.values_params.platform.sholder)+(4*(paramSelected.values_params.platform.lanes*(paramSelected.values_params.platform.lane)))) }}m</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 39.9789 120.7532)" class="st_structure_2 st_structure_3">1</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 12.3006 146.2852)" class="st_structure_2 st_structure_3">1.5</text>
	</g>
	<text transform="matrix(1 0 0 1 62.6849 59.1372)" class="st_structure_2 st_structure_3">1 m</text>
	<text transform="matrix(1 0 0 1 1150.9583 59.1372)" class="st_structure_2 st_structure_3">1 m</text>
	<text transform="matrix(1 0 0 1 85.2545 198.1508)" class="st_structure_2 st_structure_3">Acostamento</text>
	<text transform="matrix(1 0 0 1 1082.0897 198.151)" class="st_structure_2 st_structure_3">Acostamento</text>
	<g>
		<g>
			<polyline class="st_structure_14" points="199.08,125.12 199.08,219.43 208.08,219.43 			"/>
			<g>
				<polygon points="207.08,220.27 207.93,219.43 207.08,218.58 207.8,218.58 208.65,219.43 207.8,220.27 				"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<polyline class="st_structure_14" points="183.26,137.17 183.26,240.41 200.73,240.41 			"/>
			<g>
				<polygon points="199.73,241.26 200.58,240.41 199.73,239.56 200.45,239.56 201.3,240.41 200.45,241.26 				"/>
			</g>
		</g>
	</g>
	<text transform="matrix(1 0 0 1 215.0326 223.1505)" class="st_structure_2 st_structure_3">{{paramSelected.values_params.superstructure.revestment_density}} t/m³</text>
	<text transform="matrix(1 0 0 1 207.6822 244.1353)" class="st_structure_2 st_structure_3">2.4 t/m³</text>
	<g>
		<g>
			<line class="st_structure_14" x1="118.68" y1="125.7" x2="118.68" y2="184.76"/>
			<g>
				<polygon points="117.84,183.76 118.68,184.61 119.53,183.76 119.53,184.48 118.68,185.33 117.84,184.48 				"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<line class="st_structure_14" x1="1115.52" y1="124.7" x2="1115.52" y2="184.76"/>
			<g>
				<polygon points="1114.67,183.76 1115.52,184.61 1116.37,183.76 1116.37,184.48 1115.52,185.33 1114.67,184.48 				"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<polyline class="st_structure_14" points="167.04,158.2 167.04,261.49 184.51,261.49 			"/>
			<g>
				<polygon points="183.52,262.33 184.37,261.49 183.52,260.64 184.24,260.64 185.09,261.49 184.24,262.33 				"/>
			</g>
		</g>
	</g>
	<text transform="matrix(1 0 0 1 191.4692 265.209)" class="st_structure_2 st_structure_3">2.4 t/m³</text>
</g>
<g id="Grass">
	<path class="st_structure_15" d="M646.61,135.67l174.18-6.64c0,0-0.1-0.28,0.2-0.7c0.03,0.55-3.19-2.72-8.12-2.17
		c-2.48-0.12-5.45,0.67-8.89,0.64c-3.49-0.82-7.29,1-11.56,0.35c-2.11,0.21-4.24,1.93-6.61,0.16c-2.4-2.29-4.64,0.12-7.06,0.38
		c-2.38,1.07-4.95-0.47-7.46,0.46c-2.65-2.11-5.2-0.9-7.79,0.38c-2.51,3.02-5.26,1.87-8.08-0.2c-2.74-0.34-5.47,0.38-8.25,0.48
		c-2.84-1.08-5.62-0.72-8.39,0.27c-2.8,0.35-5.63,0.22-8.42,1.04c-2.76,1.32-5.69-0.69-8.41,1.2c-2.78,0.69-5.69-1.46-8.41-0.17
		c-2.78,0.14-5.58-0.67-8.28-0.17c-2.68,0.98-5.38,0.95-8.04,0.73c-5.32-0.36-10.49-1.29-15.24,1.01c-4.8,1.18-9.42,0.66-13.68,0.38
		c-4.28-0.91-8.1,0.57-11.55,0.56c-3.51-1.28-6.39,1.26-8.9,0.43c-4.92,0.57-7.77,0.02-7.76,0.37
		C635.82,136.13,646.61,135.67,646.61,135.67z"/>
	<path class="st_structure_15" d="M591.8,135.67l-174.18-6.64c0,0,0.1-0.28-0.2-0.7c-0.03,0.55,3.19-2.72,8.12-2.17
		c2.48-0.12,5.45,0.67,8.89,0.64c3.49-0.82,7.29,1,11.56,0.35c2.11,0.21,4.24,1.93,6.61,0.16c2.4-2.29,4.64,0.12,7.06,0.38
		c2.38,1.07,4.95-0.47,7.46,0.46c2.65-2.11,5.2-0.9,7.79,0.38c2.51,3.02,5.26,1.87,8.08-0.2c2.74-0.34,5.47,0.38,8.25,0.48
		c2.84-1.08,5.62-0.72,8.39,0.27c2.8,0.35,5.63,0.22,8.42,1.04c2.76,1.32,5.69-0.69,8.41,1.2c2.78,0.69,5.69-1.46,8.41-0.17
		c2.78,0.14,5.58-0.67,8.28-0.17c2.68,0.98,5.38,0.95,8.04,0.73c5.32-0.36,10.49-1.29,15.24,1.01c4.8,1.18,9.42,0.66,13.68,0.38
		c4.28-0.91,8.1,0.57,11.55,0.56c3.51-1.28,6.39,1.26,8.9,0.43c4.92,0.57,7.77,0.02,7.76,0.37
		C602.6,136.13,591.8,135.67,591.8,135.67z"/>
</g>
<g id="Lines">
	<g>
		<g>
			<line class="st_structure_1" x1="164.95" y1="68.53" x2="418.68" y2="68.53"/>
			<g>
				<rect x="164.45" y="65.28" width="1" height="6.5"/>
			</g>
			<g>
				<rect x="418.18" y="65.28" width="1" height="6.5"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<line class="st_structure_1" x1="820.85" y1="68.53" x2="1071.02" y2="68.53"/>
			<g>
				<rect x="820.35" y="65.28" width="1" height="6.5"/>
			</g>
			<g>
				<rect x="1070.52" y="65.28" width="1" height="6.5"/>
			</g>
		</g>
	</g>
	<g>
		<line class="st_structure_16" x1="1183.96" y1="126.27" x2="1213.96" y2="126.27"/>
		<line class="st_structure_16" x1="1183.96" y1="130.27" x2="1213.96" y2="130.27"/>
		<line class="st_structure_16" x1="1193.96" y1="149.27" x2="1213.96" y2="149.27"/>
		<line class="st_structure_16" x1="1205.96" y1="169.27" x2="1213.96" y2="169.27"/>
	</g>
	<g>
		<g>
			<line class="st_structure_1" x1="419.68" y1="68.53" x2="819.85" y2="68.53"/>
			<g>
				<rect x="419.18" y="65.28" width="1" height="6.5"/>
			</g>
			<g>
				<rect x="819.35" y="65.28" width="1" height="6.5"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<line class="st_structure_1" x1="38.28" y1="34.98" x2="1199.05" y2="34.98"/>
			<g>
				<rect x="37.78" y="31.73" width="1" height="6.5"/>
			</g>
			<g>
				<rect x="1198.55" y="31.73" width="1" height="6.5"/>
			</g>
		</g>
	</g>
	<polygon class="st_structure_1" points="35.6,130.28 51.17,130.28 34.83,158.31 	"/>
	<g>
		<g>
			<line class="st_structure_1" x1="58.63" y1="68.53" x2="82.43" y2="68.53"/>
			<g>
				<rect x="58.13" y="65.28" width="1" height="6.5"/>
			</g>
			<g>
				<rect x="81.93" y="65.28" width="1" height="6.5"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<line class="st_structure_1" x1="83.43" y1="68.53" x2="164.52" y2="68.53"/>
			<g>
				<rect x="82.93" y="65.28" width="1" height="6.5"/>
			</g>
			<g>
				<rect x="164.02" y="65.28" width="1" height="6.5"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<line class="st_structure_1" x1="1155.34" y1="68.53" x2="1177.71" y2="68.53"/>
			<g>
				<rect x="1154.84" y="65.28" width="1" height="6.5"/>
			</g>
			<g>
				<rect x="1177.21" y="65.28" width="1" height="6.5"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<line class="st_structure_1" x1="1072.02" y1="68.53" x2="1154.34" y2="68.53"/>
			<g>
				<rect x="1071.52" y="65.28" width="1" height="6.5"/>
			</g>
			<g>
				<rect x="1153.84" y="65.28" width="1" height="6.5"/>
			</g>
		</g>
	</g>
</g>
</svg>
</template>
<script>
export default {
	name: "SvgPlatormStructure1LaneMetalGuard",
	props: {
		paramSelected: undefined,
	},
};
</script>
<style scoped>
	.st_structure_0{fill:#686868;stroke:#000000;stroke-width:2;stroke-miterlimit:10;}
	.st_structure_1{fill:none;stroke:#000000;stroke-miterlimit:10;}
	.st_structure_2{font-family:'MyriadPro-Regular';}
	.st_structure_3{font-size:12px;}
	.st_structure_4{fill:none;stroke:#7C7C7C;stroke-width:4;stroke-miterlimit:10;}
	.st_structure_5{fill:#FFFFFF;stroke:#7C7C7C;stroke-width:4;stroke-miterlimit:10;}
	.st_structure_6{fill:#DD8F64;stroke:#000000;stroke-width:2;stroke-miterlimit:10;}
	.st_structure_7{fill:#7C503A;stroke:#000000;stroke-width:2;stroke-miterlimit:10;}
	.st_structure_8{display:none;}
	.st_structure_9{display:inline;}
	.st_structure_10{fill:#686868;}
	.st_structure_11{fill:#72502F;}
	.st_structure_12{display:inline;fill:#686868;stroke:#000000;stroke-width:2;stroke-miterlimit:10;}
	.st_structure_13{fill:none;stroke:#000000;stroke-linecap:round;stroke-miterlimit:10;}
	.st_structure_14{fill:none;stroke:#000000;stroke-width:0.5;stroke-miterlimit:10;}
	.st_structure_15{fill:#8FCE57;stroke:#000000;stroke-width:0.5;stroke-linecap:round;stroke-miterlimit:10;}
	.st_structure_16{fill:none;stroke:#000000;stroke-width:0.75;stroke-miterlimit:10;}
</style>