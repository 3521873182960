<template>
  <div style="overflow-x: hidden; overflow-y: hidden">
    <v-row>
      <v-col style="font-size: 20px; text-align: center;" id="ValuesParamsEarthSVG1Title">
        <strong> {{ selectedLanguage['earthWorkTransport_component_text1'] }} </strong>
      </v-col>
    </v-row>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 568.11 176.38" id="ValuesParamsEarthSVG1">
      <title>{{ selectedLanguage['earthWorkTransport_component_text2'] }}</title>
      <g id="Corte">
        <path
          class="cls-1"
          d="M53.27,28.23C56.54,29.38,60,30,63.28,31c8.56,2.71,15.79,8.49,23.94,12.25,10.61,4.89,22.54,6.28,33.29,10.86,3.89,1.66,7.82,4,9.71,7.72,2.73,5.43.42,12,1.42,18,.78,4.72,3.57,8.83,6.29,12.77l15.25,22.07c5.21,7.55,10.7,15.35,18.69,19.84,2,1.11,4.07,2,5.69,3.58s2.61,4,1.69,6.1-3.06,2.84-5.05,3.57c-38.06,14-75.22,32.79-115.59,36.54-10.47,1-21.29.86-31.12-2.85,2.77-3.72,2.93-8.79,2.09-13.34s-2.55-8.94-3.16-13.53c-1-7.71,1.12-15.54.46-23.28-.62-7.47-3.81-14.47-5.18-21.83-1.09-5.84-1-11.83-1.64-17.74-1.46-14.36-6.81-28.05-9-42.32C9.4,38.91,9.69,27.47,14.34,17.73c2.06-4.33,4.15-8.69,9.4-6.86,5,1.76,9.47,4.82,13.77,7.91C42.48,22.35,47.44,26.16,53.27,28.23Z"
          transform="translate(-9.13 -9.44)"
        />
      </g>
      <g id="Terreno">
        <path
          class="cls-2"
          d="M17,11l8.21,18.78,2.47,5.65L44.15,73.16"
          transform="translate(-9.13 -9.44)"
        />
        <path
          class="cls-2"
          d="M49.4,26.59c2.43,4,4.88,8.05,6.36,12.5.78,2.36,1.28,4.8,1.88,7.21A88.39,88.39,0,0,0,71.79,76.71"
          transform="translate(-9.13 -9.44)"
        />
        <path
          class="cls-2"
          d="M71.6,33.45l1.87,9c.68,3.26,1.42,6.66,3.48,9.28"
          transform="translate(-9.13 -9.44)"
        />
        <path
          class="cls-2"
          d="M78.44,55.94l4.6,14c.44,1.34.88,2.68,1.44,4,1.62,3.73,4.18,7,5.92,10.64"
          transform="translate(-9.13 -9.44)"
        />
        <path
          class="cls-2"
          d="M94.15,90.33a67.66,67.66,0,0,0,9.24,18.84,23,23,0,0,0,2.52,3.13c1.51,1.52,3.34,2.68,4.9,4.14,1.86,1.74,3.31,3.87,5,5.77,6.28,7,15.9,11,20.77,19"
          transform="translate(-9.13 -9.44)"
        />
        <path
          class="cls-2"
          d="M139.45,145.53c1.9,1.29,3.67,2.88,5.85,3.61a28.29,28.29,0,0,1,3.9,1.16c1.21.61,2.23,2,1.83,3.26"
          transform="translate(-9.13 -9.44)"
        />
        <path
          class="cls-2"
          d="M102.83,50.24c3.19,8.08,2.46,17.83,7.9,24.6,2,2.56,4.83,4.44,7,6.88"
          transform="translate(-9.13 -9.44)"
        />
        <path
          class="cls-2"
          d="M120.14,85.49l.29,2.29"
          transform="translate(-9.13 -9.44)"
        />
        <path
          class="cls-2"
          d="M133,103.45a10.83,10.83,0,0,1,4,4.23c.5.93.86,1.94,1.32,2.89,1.49,3.06,4.06,5.6,4.92,8.88"
          transform="translate(-9.13 -9.44)"
        />
        <path
          class="cls-2"
          d="M145.25,122.39C148,124.25,150,127,152,129.61l4.55,6.11c1.27,1.71,2.54,3.42,3.66,5.22a37.5,37.5,0,0,1,2,3.68"
          transform="translate(-9.13 -9.44)"
        />
        <path
          class="cls-2"
          d="M101.16,143.85c-1,.36-.47,1.79.16,2.58a62.4,62.4,0,0,0,10.53,10.14,50.59,50.59,0,0,0,13,7.4"
          transform="translate(-9.13 -9.44)"
        />
        <path
          class="cls-2"
          d="M66.49,147.89l6.61,5.19a10.63,10.63,0,0,1,2.31,2.23c.85,1.23,1.15,2.76,1.93,4a12.55,12.55,0,0,0,3.56,3.36l12.45,9a18.14,18.14,0,0,0,6.42,3.42"
          transform="translate(-9.13 -9.44)"
        />
        <path
          class="cls-3"
          d="M550,138.56c-6.44-4.25-8.29-12.81-13.2-18.76-2.12-2.56-4.94-4.89-5.45-8.17a184.31,184.31,0,0,0-41.4.74l-11.65,18.39a36.07,36.07,0,0,1-2.38,3.47,18.75,18.75,0,0,1-6.51,5.14l-5.05,2.3h88.05Z"
          transform="translate(-9.13 -9.44)"
        />
        <polyline
          class="cls-2"
          points="172.26 132.23 567.11 132.23 567.11 130.85"
        />
      </g>
      <g id="Caminhão">
        <polyline
          class="cls-2"
          points="286.56 117.09 315.8 117.22 315.8 117.22 315.8 94.28 255.03 94.28 255.03 117.78 255.03 118.01 267.64 118.01"
        />
        <path
          class="cls-2"
          d="M363.54,126.63h6.79l.46-.65V113.05h0c-5.72-2.92-12.46-4-17.46-8-.93-.75-1.74-1.74-2.73-2.41a24.89,24.89,0,0,1-2.63-2.3c-.21-.18-.41-.37-.63-.53s-.49-.3-.73-.44l-3.24-1.79a3.6,3.6,0,0,0-.87-.4,4.24,4.24,0,0,0-.88-.12c-2-.14-4.06-.07-6.08-.07-2.38,0-4.48.09-6.85.09v29.78h14.87"
          transform="translate(-9.13 -9.44)"
        />
        <path
          class="cls-3"
          d="M264.19,102.48a27.72,27.72,0,0,1,9.26-7.17c1.13-.54,2.29-1,3.46-1.47L281,92.22a20,20,0,0,1,10.48-1.88l9.84.24a11.25,11.25,0,0,1,3.17.39c.54.17,1.06.42,1.62.54a16,16,0,0,0,2.59,0c2.77.08,5,2.12,7.41,3.49s5.31,2.28,6.84,4.59a5.81,5.81,0,0,1,1,3.62Z"
          transform="translate(-9.13 -9.44)"
        />
        <polygon
          class="cls-2"
          points="322.33 115.32 332.54 115.39 332.54 115.39 332.54 90.49 322.33 90.48 322.33 90.48 322.33 115.32 322.33 115.32"
        />
        <line class="cls-2" x1="323.48" y1="96.91" x2="331.39" y2="96.91" />
        <ellipse class="cls-2" cx="276.28" cy="121.98" rx="4.05" ry="4.89" />
        <circle class="cls-2" cx="276.57" cy="121.74" r="9.99" />
        <circle class="cls-2" cx="345.62" cy="120.98" r="9.99" />
        <ellipse class="cls-2" cx="345.62" cy="121.35" rx="4.05" ry="4.89" />
      </g>
      <g id="Textos">
        <text class="cls-4" transform="translate(266.57 67.27)">{{ paramSelected.values_params.earthwork.perpolation_factor }} m³</text>
        <text class="cls-5" transform="translate(19.66 100.74)">{{ paramSelected.values_params.earthwork.shrinkage_factor }} m³</text>
        <text class="cls-4" transform="translate(484.1 90.03)">1 m³</text>
      </g>
      <g id="Fluxo">
        <rect class="cls-2" x="68.15" y="103.33" width="19.03" height="19.03" />
        <path
          class="cls-2"
          d="M92.32,111.56c-1.38-1.16-.22-3.38,1-4.72,10.17-11.29,21.9-21.34,35.41-28.27,12-6.13,26.67-8.34,39.76-10.54,15.66-2.63,48.31-1.38,63.31,5.13,9.37,4.06,12,6.57,16.34,10.14"
          transform="translate(-9.13 -9.44)"
        />
        <path
          d="M257.92,90.36c-5.83-1.64-13.64-2.89-19.41-2.39l8.38-5.66,2.83-9.71C251,78.25,254.58,85.31,257.92,90.36Z"
          transform="translate(-9.13 -9.44)"
        />
        <path class="cls-2" d="M237,80.59" transform="translate(-9.13 -9.44)" />
        <path
          class="cls-2"
          d="M175.78,69.85"
          transform="translate(-9.13 -9.44)"
        />
        <path
          class="cls-2"
          d="M317.28,90A50.09,50.09,0,0,1,341,71.26a81.67,81.67,0,0,1,12.31-3.33c40-8.21,88.52,7.21,124.26,29.17"
          transform="translate(-9.13 -9.44)"
        />
        <path
          d="M487.68,103.72c-5.91-1.32-13.78-2.12-19.52-1.3l8.06-6.12,2.27-9.85C480.09,92,484.06,98.87,487.68,103.72Z"
          transform="translate(-9.13 -9.44)"
        />
      </g>
    </svg>
    <v-row>
      <v-col class="formCardStyle">
        <v-row id="ValuesParamsEarthworkCleaningHeight">
          <v-col style="align-self: center"> {{ selectedLanguage['earthWorkTransport_component_text3'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                paramSelected.values_params.earthwork.cleaning_height
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> m </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkPerpolationFactor">
          <v-col style="align-self: center"> {{ selectedLanguage['earthWorkTransport_component_text4'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                paramSelected.values_params.earthwork.perpolation_factor
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> % </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkShrinkageFactor">
          <v-col style="align-self: center"> {{ selectedLanguage['earthWorkTransport_component_text5'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="
                paramSelected.values_params.earthwork.shrinkage_factor
              "
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> % </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkLoanMtd">
          <v-col style="align-self: center"> {{ selectedLanguage['earthWorkTransport_component_text6'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="paramSelected.values_params.earthwork.loan_mtd"
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> Km </v-col>
        </v-row>
        <v-row id="ValuesParamsEarthworkBootOutMtd">
          <v-col style="align-self: center"> {{ selectedLanguage['earthWorkTransport_component_text7'] }} </v-col>
          <v-col style="align-self: center">
            <v-text-field
              dense
              hide-details
              class="mb-2"
              clearable
              outlined
              v-model.number="paramSelected.values_params.earthwork.boot_out_mtd"
              style="border-radius: 10px !important"
            />
          </v-col>
          <v-col style="align-self: center"> Km </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { portuguese } from "../../../../languages/pt-br";
import { english } from "../../../../languages/en";

export default {
  computed: {
    ...mapGetters(["paramSelected","profile"]),
    selectedLanguage() {
        if (this.profile['language'] === 'en') {
          return english;
        } else {
          return portuguese;
        }
      },
  },
  methods: {},
};
</script>

<style scoped>
.cls-1 {
  fill: #3e2d00;
}
.cls-1,
.cls-2,
.cls-3 {
  stroke: #000;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}
.cls-2 {
  fill: none;
}
.cls-3 {
  fill: #875116;
}
.cls-4,
.cls-5 {
  font-size: 21px;
  font-family: MyriadPro-Regular, Myriad Pro;
}
.cls-5 {
  fill: #fff;
}
</style>
