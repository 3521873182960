<template>
  <v-dialog v-model="dialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" dark class="mt-3" v-bind="attrs" v-on="on">
        {{ buttonText }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5">{{ titleDialog }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <slot></slot>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="close"> Cancel </v-btn>
        <v-btn color="primary" text @click="handleSave"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    buttonText: {
      type: String,
      default: "Open Dialog",
    },
    titleDialog: {
      type: String,
      default: "Title Dialog",
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    close() {
      this.dialog = false;
    },
    handleSave() {
      this.$emit("save-item-dialog");
      this.dialog = false;
    },
  },
};
</script>
