<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 535.74 257.36" style="enable-background:new 0 0 535.74 257.36;" xml:space="preserve">
<g id="Canteiro">
</g>
<g id="Sub_x5F_base">
	<polygon class="st_structure_0" points="456.5,138.17 254.07,134.16 53.02,138.17 64.09,119.17 254.07,115.16 445.43,119.17 	"/>
	<polygon class="st_structure_1" points="467.44,157.17 254.07,153.16 42.08,157.17 52.74,138.17 254.07,134.16 456.77,138.17 	"/>
</g>
<g id="Asphalt_x5F_and_components">
	<polyline class="st_structure_2" points="421.31,116.17 443.12,116.17 443.12,108.17 	"/>
	<polyline class="st_structure_2" points="65.01,108.17 65.01,116.17 86.82,116.17 	"/>
	<polygon points="421.31,113.32 254.07,109.32 86.82,113.32 86.82,118.32 254.07,114.32 421.31,118.32 	"/>
	<text transform="matrix(1 0 0 1 80.2363 187.1135)" class="st_structure_3 st_structure_4">Acostamento</text>
	<text transform="matrix(1 0 0 1 350.6591 187.1137)" class="st_structure_3 st_structure_4">Acostamento</text>
</g>
<g id="Metal_x5F_guard" class="st_structure_5">
	<g class="st_structure_6">
		<g>
			<g>
				<path class="st_structure_7" d="M446.93,98.05H433.4c-0.7,0-1.27-0.57-1.27-1.27v0c0-0.7,0.57-1.27,1.27-1.27h13.53
					c0.7,0,1.27,0.57,1.27,1.27v0C448.2,97.48,447.63,98.05,446.93,98.05z"/>
				<path d="M446.93,98.37H433.4c-0.87,0-1.59-0.71-1.59-1.59c0-0.87,0.71-1.59,1.59-1.59h13.53c0.87,0,1.59,0.71,1.59,1.59
					C448.52,97.66,447.81,98.37,446.93,98.37z M433.4,95.83c-0.52,0-0.95,0.43-0.95,0.95c0,0.52,0.43,0.95,0.95,0.95h13.53
					c0.52,0,0.95-0.43,0.95-0.95c0-0.52-0.43-0.95-0.95-0.95H433.4z"/>
			</g>
			<g>
				<polygon class="st_structure_7" points="435.09,96.78 429.17,90.02 435.09,82.41 441.01,82.41 435.09,90.02 441.01,96.78 435.09,105.24 
					441.01,112.01 435.09,112.01 429.05,105.24 				"/>
				<path d="M441.71,112.32h-6.76l-6.3-7.06l6.04-8.46l-5.93-6.77l6.18-7.94h6.72L435.51,90l5.91,6.76l-5.92,8.46L441.71,112.32z
				M435.24,111.69h5.08l-5.62-6.43l5.92-8.46l-5.93-6.78l5.68-7.31h-5.12L429.59,90l5.91,6.76l-6.04,8.45L435.24,111.69z"/>
			</g>
			<g>
				<rect x="441.44" y="81.98" class="st_structure_8" width="4.23" height="36.37"/>
				<path d="M445.98,118.67h-4.86v-37h4.86V118.67z M441.75,118.03h3.59V82.3h-3.59V118.03z"/>
			</g>
		</g>
		<g>
			<g>
				<path class="st_structure_7" d="M61.48,98.05h13.53c0.7,0,1.27-0.57,1.27-1.27v0c0-0.7-0.57-1.27-1.27-1.27H61.48
					c-0.7,0-1.27,0.57-1.27,1.27v0C60.21,97.48,60.78,98.05,61.48,98.05z"/>
				<path d="M59.89,96.78c0-0.87,0.71-1.59,1.59-1.59h13.53c0.87,0,1.59,0.71,1.59,1.59c0,0.87-0.71,1.59-1.59,1.59H61.48
					C60.61,98.37,59.89,97.66,59.89,96.78z M61.48,95.83c-0.52,0-0.95,0.43-0.95,0.95c0,0.52,0.43,0.95,0.95,0.95h13.53
					c0.52,0,0.95-0.43,0.95-0.95c0-0.52-0.43-0.95-0.95-0.95H61.48z"/>
			</g>
			<g>
				<polygon class="st_structure_7" points="73.32,96.78 79.24,90.02 73.32,82.41 67.4,82.41 73.32,90.02 67.4,96.78 73.32,105.24 67.4,112.01 
					73.32,112.01 79.36,105.24 				"/>
				<path d="M72.92,105.22L67,96.76L72.91,90l-6.16-7.92h6.72l6.18,7.94l-5.93,6.77l6.04,8.46l-6.3,7.06H66.7L72.92,105.22z
				M78.96,105.22l-6.04-8.45L78.83,90l-5.66-7.28h-5.12l5.68,7.31L67.8,96.8l5.92,8.46l-5.62,6.43h5.08L78.96,105.22z"/>
			</g>
			<g>
				
					<rect x="62.75" y="81.98" transform="matrix(-1 -4.473722e-11 4.473722e-11 -1 129.7264 200.3302)" class="st_structure_8" width="4.23" height="36.37"/>
				<path d="M62.43,81.67h4.86v37h-4.86V81.67z M66.66,82.3h-3.59v35.73h3.59V82.3z"/>
			</g>
		</g>
	</g>
</g>
<g id="Jerseys" class="st_structure_5">
	<g class="st_structure_6">
		<polygon class="st_structure_9" points="64.24,117.92 86.05,117.92 86.05,109.42 78.58,102.42 75.58,97.42 73.58,82.42 64.08,82.42 		"/>
		<polygon class="st_structure_9" points="443.89,117.92 422.08,117.92 422.08,109.42 429.55,102.42 432.55,97.42 434.55,82.42 444.05,82.42 		
			"/>
	</g>
</g>
<g id="Arrows">
	<g>
		<g>
			<g>
				<line class="st_structure_10" x1="238.31" y1="100.17" x2="189.28" y2="101.14"/>
				<g>
					<polygon points="191.24,99.41 189.58,101.14 191.31,102.8 189.87,102.83 188.14,101.17 189.8,99.44 					"/>
				</g>
			</g>
		</g>
		<g>
			<g>
				<line class="st_structure_10" x1="267.58" y1="100.17" x2="316.61" y2="101.14"/>
				<g>
					<polygon points="314.59,102.8 316.32,101.14 314.66,99.41 316.09,99.44 317.76,101.17 316.03,102.83 					"/>
				</g>
			</g>
		</g>
	</g>
</g>
<g id="Text">
	<g>
		<g>
			<text transform="matrix(1 0 0 1 117.0941 49.8998)" class="st_structure_3 st_structure_4">{{ paramSelected.values_params.platform.sholder }}m</text>
			<text transform="matrix(1 0 0 1 366.0941 49.8998)" class="st_structure_3 st_structure_4">{{ paramSelected.values_params.platform.sholder }}m</text>
		</g>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 65.0089 49.8998)" class="st_structure_3 st_structure_4">1 m</text>
		<text transform="matrix(1 0 0 1 423.5475 49.8998)" class="st_structure_3 st_structure_4">1 m</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 206.2305 49.8998)" class="st_structure_3 st_structure_4">{{ (paramSelected.values_params.platform.lanes*(paramSelected.values_params.platform.lane)) }}m</text>
		<text transform="matrix(1 0 0 1 263.2304 49.8998)" class="st_structure_3 st_structure_4">{{ (paramSelected.values_params.platform.lanes*(paramSelected.values_params.platform.lane)) }}m</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 213.118 93.8887)" class="st_structure_3 st_structure_4">3 %</text>
		<text transform="matrix(1 0 0 1 277.118 93.8887)" class="st_structure_3 st_structure_4">3 %</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 490.1607 119.6973)" class="st_structure_3 st_structure_4">{{ (Number(this.paramSelected.values_params.superstructure.first_asphalt_layer_depth)+Number(this.paramSelected.values_params.superstructure.second_asphalt_layer_depth)) }}m</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 490.1607 134.6973)" class="st_structure_3 st_structure_4">{{ paramSelected.values_params.superstructure.base_height }}m</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 490.1607 154.6973)" class="st_structure_3 st_structure_4">{{ paramSelected.values_params.superstructure.subbase_height }}m</text>
	</g>
	<g>
		<text transform="matrix(1 0 0 1 241.6345 22.0636)" class="st_structure_3 st_structure_4">{{ ((2)+(2*paramSelected.values_params.platform.sholder)+(2*(paramSelected.values_params.platform.lanes*(paramSelected.values_params.platform.lane)))) }}m</text><!-- por a soma de todas as width -->
	</g>
	<g>
		<text transform="matrix(1 0 0 1 41.142 112.665)" class="st_structure_3 st_structure_4">1</text>
	</g>
	<g>
		<g>
			<polyline class="st_structure_11" points="194.07,114.08 194.07,200.39 203.06,200.39 			"/>
			<g>
				<polygon points="202.06,201.24 202.91,200.39 202.06,199.54 202.78,199.54 203.63,200.39 202.78,201.24 				"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<polyline class="st_structure_11" points="178.24,126.13 178.24,221.37 195.71,221.37 			"/>
			<g>
				<polygon points="194.71,222.22 195.56,221.37 194.71,220.53 195.43,220.53 196.28,221.37 195.43,222.22 				"/>
			</g>
		</g>
	</g>
	<text transform="matrix(1 0 0 1 210.0144 204.1132)" class="st_structure_3 st_structure_4">{{paramSelected.values_params.superstructure.revestment_density}} t/m³</text>
	<text transform="matrix(1 0 0 1 202.664 225.098)" class="st_structure_3 st_structure_4">2.4 t/m³</text>
	<g>
		<text transform="matrix(1 0 0 1 14.3537 132.6972)" class="st_structure_3 st_structure_4">1.5</text>
	</g>
	<g>
		<g>
			<line class="st_structure_11" x1="113.67" y1="114.66" x2="113.67" y2="173.72"/>
			<g>
				<polygon points="112.82,172.73 113.67,173.57 114.51,172.73 114.51,173.45 113.67,174.29 112.82,173.45 				"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<line class="st_structure_11" x1="384.09" y1="113.66" x2="384.09" y2="173.72"/>
			<g>
				<polygon points="383.24,172.73 384.09,173.57 384.94,172.73 384.94,173.45 384.09,174.29 383.24,173.45 				"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<polyline class="st_structure_11" points="162.03,147.16 162.03,242.45 179.5,242.45 			"/>
			<g>
				<polygon points="178.5,243.3 179.35,242.45 178.5,241.6 179.22,241.6 180.07,242.45 179.22,243.3 				"/>
			</g>
		</g>
	</g>
	<text transform="matrix(1 0 0 1 186.451 246.1717)" class="st_structure_3 st_structure_4">2.4 t/m³</text>
</g>
<g id="Lines">
	<g>
		<g>
			<line class="st_structure_12" x1="42.35" y1="29.68" x2="466.7" y2="29.68"/>
			<g>
				<rect x="41.85" y="26.43" width="1" height="6.5"/>
			</g>
			<g>
				<rect x="466.2" y="26.43" width="1" height="6.5"/>
			</g>
		</g>
	</g>
	<polygon class="st_structure_12" points="37.58,118.17 53.15,118.17 36.82,146.2 	"/>
	<g>
		<g>
			<line class="st_structure_12" x1="63.81" y1="59.29" x2="86.32" y2="59.29"/>
			<g>
				<rect x="63.31" y="56.04" width="1" height="6.5"/>
			</g>
			<g>
				<rect x="85.82" y="56.04" width="1" height="6.5"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<line class="st_structure_12" x1="421.81" y1="59.29" x2="444.6" y2="59.29"/>
			<g>
				<rect x="421.31" y="56.04" width="1" height="6.5"/>
			</g>
			<g>
				<rect x="444.1" y="56.04" width="1" height="6.5"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<line class="st_structure_12" x1="157.5" y1="59.29" x2="351.06" y2="59.29"/>
			<g>
				<rect x="157" y="56.04" width="1" height="6.5"/>
			</g>
			<g>
				<rect x="350.56" y="56.04" width="1" height="6.5"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<line class="st_structure_12" x1="351.07" y1="59.29" x2="420.81" y2="59.29"/>
			<g>
				<rect x="350.57" y="56.04" width="1" height="6.5"/>
			</g>
			<g>
				<rect x="420.31" y="56.04" width="1" height="6.5"/>
			</g>
		</g>
	</g>
	<line class="st_structure_12" x1="248.4" y1="54.78" x2="248.4" y2="63.8"/>
	<g>
		<g>
			<line class="st_structure_12" x1="87.32" y1="59.29" x2="156.96" y2="59.29"/>
			<g>
				<rect x="86.82" y="56.04" width="1" height="6.5"/>
			</g>
			<g>
				<rect x="156.46" y="56.04" width="1" height="6.5"/>
			</g>
		</g>
	</g>
	<g>
		<line class="st_structure_13" x1="451.33" y1="114.16" x2="481.33" y2="114.16"/>
		<line class="st_structure_13" x1="451.33" y1="118.16" x2="481.33" y2="118.16"/>
		<line class="st_structure_13" x1="461.33" y1="137.16" x2="481.33" y2="137.16"/>
		<line class="st_structure_13" x1="473.33" y1="157.16" x2="481.33" y2="157.16"/>
	</g>
</g>
</svg>

</template>
<script>
export default {
	name: "SvgPlatormStructure1Lane",
	props: {
    paramSelected: undefined,
	},
	// mounted(){
	// 	var first = Number(this.paramSelected.values_params.superstructure.first_asphalt_layer_depth)
	// 	var second = Number(this.paramSelected.values_params.superstructure.second_asphalt_layer_depth)
	// 	console.log(first,second)
	// }
};
</script>
<style scoped>
	.st_structure_0{fill:#DD8F64;stroke:#000000;stroke-width:2;stroke-miterlimit:10;}
	.st_structure_1{fill:#7C503A;stroke:#000000;stroke-width:2;stroke-miterlimit:10;}
	.st_structure_2{fill:none;stroke:#7C7C7C;stroke-width:4;stroke-miterlimit:10;}
	.st_structure_3{font-family:'MyriadPro-Regular';}
	.st_structure_4{font-size:12px;}
	.st_structure_5{display:none;}
	.st_structure_6{display:inline;}
	.st_structure_7{fill:#686868;}
	.st_structure_8{fill:#72502F;}
	.st_structure_9{fill:#686868;stroke:#000000;stroke-width:2;stroke-miterlimit:10;}
	.st_structure_10{fill:none;stroke:#000000;stroke-linecap:round;stroke-miterlimit:10;}
	.st_structure_11{fill:none;stroke:#000000;stroke-width:0.5;stroke-miterlimit:10;}
	.st_structure_12{fill:none;stroke:#000000;stroke-miterlimit:10;}
	.st_structure_13{fill:none;stroke:#000000;stroke-width:0.75;stroke-miterlimit:10;}
</style>
