<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 402.35 218.16" style="enable-background:new 0 0 402.35 218.16;" xml:space="preserve">
<g id="Aterro">
	<path class="st_fill_0" d="M403.93,149.92c0-0.87-0.02-1.33-0.06-0.08c-0.04,0.18-0.11,0.81-0.19-0.23c-0.08-0.63-0.19,0.47-0.31-0.38
		c-0.12-0.22-0.27-0.24-0.43-0.53c-0.16,1.31-0.34-0.04-0.54-0.67c-0.2,0.05-0.42,0.47-0.65-0.8c-0.24-0.15-0.49-0.87-0.76-0.93
		c-0.27,0.62-0.56,0.48-0.86-1.06c-0.3-0.06-0.62-0.86-0.96-1.18c-0.34-0.87-0.69-0.29-1.06-1.29c-0.37,0.46-2.75-3.31-3.15-4.4
		c-0.4-0.08-0.81-0.48-1.23-1.51c-0.42-0.77-0.86-0.71-1.31-1.61c-0.45-0.45-0.92-1.49-1.39-1.7c-0.48-0.03-0.97-0.84-1.47-1.79
		c-0.5-0.87-1.01-1.53-1.54-1.88c-0.52-0.34-1.06-1.2-1.6-1.96c-0.41-0.29-0.82-1.21-1.24-1.52c-0.42-0.13-0.85-1.28-1.28-1.56
		c-0.43-0.31-0.87-0.2-1.31-1.6c-0.44,0.41-0.89-0.63-1.34-1.64c-0.45-0.74-0.91-1.38-1.37-1.67c-0.46,0-0.92-0.48-1.39-1.7
		c-0.23-0.4-0.47,0.04-0.71-0.99c-0.24-0.26-0.47-0.35-0.71-0.74c-0.24,0.04-0.48-0.83-0.72-0.8c-0.24-0.46-0.48-0.97-0.72-0.97
		c-0.16,0.65-0.32-0.09-0.49-0.46c-0.16-0.72-0.32-0.18-0.49-0.73c-0.16,0.05-0.33-0.37-0.49-0.68c-0.16-0.78-0.33,0.08-0.49-0.52
		c-0.16-0.82-0.33-1.2-0.49-0.65c-0.17-0.1-0.33-0.53-0.5-0.48c-0.17-0.08-0.33-0.58-0.5-0.52c-0.17-0.95-0.33-0.03-0.5-0.78
		c-0.17-0.55-0.33-0.28-0.5-0.62c-0.17-0.63-0.34-0.12-0.5-0.59c-0.17,0.19-0.34-0.73-0.51-0.62c-0.17-0.13-0.34-1.23-0.51-0.64
		c-0.17,0.58-0.34-0.49-0.51-0.52c-0.17-0.3-0.34-0.41-0.51-0.66c-0.17-0.04-0.34-0.47-0.51-0.73c-0.17,0.15-0.34-0.7-0.51-0.59
		c-0.34-0.23-0.69-1.5-1.03-1.18c-0.34-0.54-0.69-1.06-1.04-1.36c-0.35-0.23-0.69-1.78-1.04-1.33c-0.17-0.59-0.35-0.25-0.52-0.61
		c-0.17-0.79-0.35-0.92-0.52-0.62c-0.27,0-0.53-0.59-0.8-0.93c-0.27-0.21-0.53-0.63-0.8-1.02c-0.27-0.18-0.53-0.08-0.8-1.03
		c-0.27-0.58-0.54-0.04-0.8-0.93c-0.27-0.58-0.54-0.49-0.81-1.01c-0.27-0.28-0.54-1.12-0.81-0.97c-0.27,0.05-0.54-0.47-0.81-1.02
		c-0.27,0.04-0.54-1.01-0.81-0.95c-0.27-0.38-0.54,0.08-0.81-0.99c-0.27,0.08-0.54-1.04-0.81-0.99c-0.27,0.23-0.54-0.43-0.81-0.9
		c-0.27-0.67-0.54-0.97-0.81-1.08c-0.27,0.2-0.54-0.54-0.81-0.91c-0.27-0.55-0.54-1.02-0.81-1.06c-0.27-0.83-0.54-1.42-0.81-1.05
		c-0.27,0.07-0.54-1.78-0.8-0.92c-0.27,0.11-0.54-1.32-0.8-0.84c-0.27-0.01-0.53-1.15-0.8-1.12c-0.27-0.41-0.53-0.59-0.8-0.98
		c-0.27-0.92-0.53-0.72-0.8-0.98c-0.27-0.1-0.53-1.51-0.79-1.02c-0.26-0.52-0.53-0.57-0.79-0.92c-0.26,0.37-0.53-0.13-0.79-0.78
		c-0.26-1.54-0.52-1.32-0.79-1.15c-0.26-2.09-0.52-1.53-0.78-1c-0.26-0.59-0.52-0.78-0.78-0.91c-0.26-0.04-0.52-1.85-0.78-0.95
		c-0.26,0.05-0.52-0.99-0.77-0.95c-0.26-0.95-0.51-0.34-0.77-0.94c-0.26-0.62-0.51-0.5-0.76-0.93c-0.25-1.12-0.51-0.9-0.76-0.88
		c-0.25,0.52-0.5-0.4-0.75-0.97c-0.25-0.47-0.5,1.18-0.75-0.95c-0.25-0.41-0.5-0.84-0.74-0.88c-0.25-0.33-0.49-1.4-0.74-1.04
		c-0.25-0.22-0.49-1.47-0.73-0.76c-0.49-0.39-0.97-1.85-1.45-1.77c-0.48-0.82-0.95-1.06-1.42-1.74c-0.47-0.48-0.94-1.51-1.4-1.71
		c-0.3-0.46-0.6,0.54-0.9-1.07c-0.3-1.09-0.59-1.41-0.89-1.12c-0.29-0.03-0.59-0.77-0.88-1c-0.29,0.09-0.58-1.23-0.86-1.13
		c-0.28,0.12-0.57-0.79-0.85-1.04c-0.28-0.71-0.56,0.66-0.83-1.02c-0.27-0.78-0.55-1.37-0.82-0.98c-0.27-0.02-0.54-0.92-0.8-1
		c-0.26-0.56-0.53-1.56-0.78-0.92c-0.26-0.36-0.51-0.78-0.77-0.98c-0.25-0.14-0.5-0.92-0.75-0.88c-0.25-0.5-0.49-0.89-0.73-0.93
		c-0.24,0.23-0.48-0.55-0.71-0.9c-0.24-1.1-0.47-0.84-0.7-0.83c-0.46-0.6-0.9-0.09-1.33-1.63c-0.43-0.26-0.85-1.19-1.25-1.53
		c-0.23,0.97-0.45-1.39-0.67-0.82c-0.22-1.44-0.43,0.13-0.64-0.78c-0.21,0.49-0.41-1.83-0.61-0.79c-0.2-1.03-0.39-0.92-0.58-0.67
		c-0.19,0.09-0.37-0.19-0.55-0.51c-0.18-1.22-0.35-0.72-0.52-0.8c-0.34-0.36-0.65-0.47-0.95-1.16c-0.3-0.17-0.57-1.38-0.82-1
		c-0.25-0.47-4.05-5.06-4.25-5.59c-0.2,0.55-0.38,0.64-0.54-0.66c-0.16,1.32-0.29-1.15-0.39-0.48c-0.11,0.15-0.19-0.97-0.24-0.29
		c-0.05-0.41-0.08-0.91-0.08-0.1c0-0.82-0.06,1.58-0.18,0c-0.12,0.5-0.3,0.3-0.53,0c-0.23,1.54-0.52-0.13-0.86,0
		c-0.34-0.44-0.13,2.78-0.57,2.71c-0.44,0.09-0.93-2.09-1.46,0c-0.53-0.26,1.86,2.63,1.24,2.04c-0.62,1.13-1.29-0.56-1.99,0
		c-0.71-0.1-1.45-0.17-2.23,0c-0.78,0.22-1.6-0.05-2.45,0c-0.43-0.08-0.86,0.14-1.3-0.18c-0.44-0.12-0.89,0.02-1.35,0.18
		c-0.46,0.19-0.92,0.31-1.4,0.23c-0.47,0.2-0.95-0.85-1.44-0.23c-0.49-0.08-0.98,0.3-1.48-0.16c-0.5,0.01-1.01-0.59-1.52,0.16
		c-0.51,0.27-1.03-0.79-1.56-0.06c-0.52-0.06-1.06,0.55-1.59,0.06c-0.54,0.5-1.08,0.09-1.62,0.17c-0.55,0.22-1.1-0.41-1.65-0.17
		c-0.56-0.15-1.12,0.19-1.68-0.31c-0.56,0.17-1.13-0.29-1.71,0.31c-0.57,0.61-1.15,0.18-1.73-0.15c-0.58,0.04-1.16,0.01-1.75,0.15
		c-0.59-0.46-1.18,1-1.77,0.06c-0.59-0.13-1.19-0.4-1.78-0.06c-0.6,0.95-1.2,0.69-1.8,0.03c-0.6-0.79-1.2-0.63-1.81-0.03
		c-0.6,0.63-1.21-0.23-1.82,0.08c-0.61-0.07-1.22,0.16-1.82-0.08c-0.61,0.46-1.22,0.29-1.83-0.13c-0.61,0.24-1.22-0.34-1.83,0.13
		c-0.61,0.08-1.22,0.54-1.83,0.32c-0.61,0.1-1.22-0.14-1.83-0.32c-0.61-0.83-1.22,0.05-1.82-0.27c-0.61-0.25-1.21,0.39-1.82,0.27
		c-0.6-0.58-1.21-0.02-1.81-0.13c-0.6-0.31-1.2,0.2-1.8,0.13c-0.6-0.38-1.19-0.01-1.78-0.2c-0.59,0.24-1.18-0.12-1.77,0.2
		c-0.59,0.46-1.17,0.49-1.75,0.24c-0.58-0.57-1.16,0.35-1.73-0.24c-0.57-0.47-1.14-0.47-1.71-0.13c-0.56-0.17-1.12-0.13-1.68,0.13
		c-0.56-0.44-1.11-1.02-1.65-0.36c-0.55,1.31-1.09-0.97-1.62,0.36c-0.54,0.56-1.07,0.15-1.59,0.15c-0.52,0.28-1.04,0.25-1.56-0.15
		c-0.51-0.25-1.02,0.85-1.52-0.34c-0.5,0.43-0.99-0.71-1.48,0.34c-0.49,0.6-0.97,1.14-1.44,0.1c-0.47-0.1-0.94-0.03-1.4-0.1
		c-0.46-0.97-0.91-0.49-1.35,0.06c-0.44-0.74-0.88,0.22-1.3-0.06c-0.43-1.15-0.84-0.34-1.25-0.22c-0.41,0.57-0.81,0.15-1.2,0.22
		c-0.39-0.26-0.77-0.74-1.14-0.62c-0.37,0.49-0.73,0.23-1.09,0.62c-0.35-0.66-0.7-0.81-1.03-0.78c-0.33-0.01-0.65-0.14-0.97,0.78
		c-0.62-0.33-1.2,0.4-1.74,0c-0.53-1.07,1.86-3.99,1.42-4.44c-0.22,0.74-0.43,0.11-0.62,0.3c-0.2,0.43-0.38-0.15-0.55-0.3
		c-0.34,0.4-0.63-1.22-0.86,0c-0.23-0.35-0.41-0.8-0.53,0c-0.12-0.63-0.18-0.53-0.18,0c0-0.72-0.15,0.8-0.44,0.52
		c-0.14,0.05-0.32,1.18-0.53,0.74c-0.21,0.37-0.45,1.18-0.73,0.75c-0.28,0.96-3.46,3.85-3.8,5.64c-0.34-0.38-0.7,0.2-1.1,1.18
		c-0.17,1.48-0.34-1.05-0.52,0.62c-0.18-0.47-0.36,1.2-0.55,0.65c-0.19,0.4-0.38,1.15-0.58,0.75c-0.2-0.97-0.4,0.63-0.61,0.66
		c-0.21-0.08-0.42,0.81-0.64,0.74c-0.22,0.01-0.44,0.38-0.67,0.81c-0.23,1.37-0.46,0.79-0.7,0.76c-0.24-2.5-0.48,1.17-0.72,0.92
		c-0.25,2.44-0.5,0.32-0.75,0.94c-0.25,0.59-0.51,1.47-0.77,0.86c-0.13-1.62-0.26,0.4-0.4,0.51c-0.13-1.66-0.27-0.11-0.4,0.49
		c-0.27,0.37-0.54,1.36-0.82,0.92c-0.28-1.38-0.56,0.77-0.85,0.78c-0.29,0.43-0.58,0.3-0.87,1.24c-0.29-0.52-0.59,0.54-0.89,1.27
		c-0.3,0.8-0.6,0.45-0.91,0.86c-0.31-0.32-0.62,0.81-0.93,0.62c-0.31,0.33-0.63,1.94-0.95,1.61c-0.32-0.06-0.64,1.67-0.97,0.8
		c-0.33-0.03-0.66-0.03-0.99,1.51c-0.33,0.51-0.67,1.09-1,1.27c-0.34-0.36-0.68,0.97-1.02,1.13c-0.34,1.2-0.69,0.75-1.04,1.31
		c-0.35-0.42-0.7,1.19-1.05,1.16c-0.35,0.75-0.71,0.58-1.07,1.1c-0.36-0.35-0.72-0.22-1.08,1.44c-0.36,0.96-0.73-0.29-1.09,1.44
		c-0.37,1.22-0.74,2.04-1.11,1.16c-0.37,0.23-0.74,0.91-1.12,1.22c-0.37,1.01-0.75,0.23-1.13,1.44c-0.38,0.41-0.76,0.39-1.14,1.26
		c-0.38,0.13-0.77,0.84-1.15,1.45c-0.39,1.26-0.77,0.1-1.16,1.15c-0.39,0.95-0.78,0.5-1.17,1.6c-0.39,0.65-0.78,2.3-1.18,1.59
		c-0.39-0.55-0.79,0.79-1.18,1.2c-0.4,0.96-0.79-0.79-1.19,1.55c-0.4,0.1-0.8,0.54-1.19,1.27c-0.4-1.05-0.8,1.23-1.2,1.28
		c-0.4,0.17-0.8,0.58-1.2,1.56c-0.4-0.03-0.8,1.57-1.21,1.57c-0.4,0.93-0.81,0.74-1.21,1.29c-0.4,0.14-0.81,0.99-1.21,1.41
		c-0.4,0.27-0.81,0.5-1.21,1.46c-0.4,0.96-0.81,1.03-1.21,1.22c-0.4,0.34-0.81,0.29-1.21,1.65c-0.4-0.05-0.81,1.36-1.21,1.35
		c-0.4,1.33-0.81,1.14-1.21,1.52c-0.4,1.03-0.81,0.8-1.21,1.76c-0.4-1.49-0.81,1.11-1.21,1.09c-0.4-0.81-0.8,2.29-1.2,1.46
		c-0.2-0.14-0.4,0.56-0.6,1.78c-0.2-0.08-0.4-0.61-0.6-0.4c-0.4,0.49-0.8,0.06-1.19,1.36c-0.4-0.39-0.79,0.17-1.19,1.46
		c-0.4,0.8-0.79,0.7-1.18,1.64c-0.39,0.31-0.79,1.27-1.18,1.15c-0.39-0.46-0.78,0.81-1.17,1.02c-0.39,0.78-0.77,1.63-1.16,1.73
		c-0.39,1.93-0.77,2.18-1.15,1.66c-0.38,0.31-0.76,0.86-1.14,1.05c-0.38,0.59-0.76,0.96-1.13,1.03c-0.37,0.63-0.75,0.56-1.12,1.63
		c-0.37,0.92-0.74,0.96-1.11,1.13c-0.37,0.05-0.73-0.19-1.09,1.47c-0.36,0.73-0.72,1.15-1.08,1.7c-0.36,0.17-0.71,0.53-1.07,0.84
		c-0.35-0.55-0.7,0.6-1.05,1.29c-0.35,0.61-0.69,0.2-1.04,1.18c-0.34,0.1-0.68-0.41-1.02,0.94c-0.34,0.76-0.67,0.97-1,1.45
		c-0.33,0.85-0.66,1.62-0.99,1.47c-0.33,0.62-0.65,0.55-0.97,0.85c-0.32,0.42-0.64,1.34-0.95,1.52c-0.31,0.68-0.62,1.35-0.93,0.7
		c-0.31,1.52-0.61,0.49-0.91,1.09c-0.3,1.4-0.6,0.61-0.89,1.03c-0.29-0.37-0.58,0.1-0.87,0.89c-0.29,0.93-0.57,0.92-0.85,1.13
		c-0.56,0.08-1.1,1.5-1.62,1.92c-0.52-0.24-1.03,0.54-1.52,1.8c-0.49-0.22-0.97,1.47-1.42,1.68c-0.46,0.24-0.89,1.89-1.31,1.55
		c-0.42-0.44-0.82,0.06-1.2,1.42c-0.38-0.48-0.74,1.57-1.08,1.27c-0.17,0.75-0.33-0.15-0.49,0.78c-0.16,0.78-0.31,0.29-0.46,0.34
		c-0.15,0.46-0.29-0.66-0.43,0.5c-0.14-0.09-0.27,0.31-0.39,0.47c-0.25-0.59-0.48,0.23-0.68,0.81c-0.41,0.37-0.72,1.01-0.94,1.11
		c-0.21,1.22-0.32,0.72-0.32,0.38c0,0.39-0.11-0.47-0.33-0.02c-0.22-0.21-0.54-0.23-0.95-0.06c-0.41-0.71-0.92,0.7-1.5-0.1
		c-0.59-0.32-1.25-0.7-1.99-0.13c-0.74,0.19-1.55,0.27-2.41-0.16c-0.43,0.18-0.88,0.44-1.34,0.02c-0.46,0.13-0.94-0.11-1.43-0.21
		c-0.49,0.27-0.99-0.93-1.5-0.25c-0.51,0.39-1.03,0.91-1.56,0.05c-0.53,0.17-1.07,0.21-1.62,0.06c-0.55-0.44-1.11-0.5-1.67-0.28
		c-0.56-0.28-1.13-0.41-1.71-0.36c-0.58-0.67-1.16-0.1-1.74,0.14c-0.59,0.15-1.18-0.3-1.77-0.04c-0.59-0.13-1.19-0.32-1.78-0.2
		c-0.6-0.49-1.19-0.16-1.79,0.12c-0.6-0.28-1.2-0.2-1.79-0.35c-0.6-0.58-1.19,1.33-1.78,0.04c-0.59,0.13-1.18,0.23-1.77-0.27
		c-0.59-1.93-1.17-0.33-1.74-0.23c-0.58-1.05-1.15-0.54-1.71,0c-0.56-0.45-1.12,0.31-1.67-0.3c-0.55,0.85-1.09-1.38-1.62,0.09
		c-0.53-0.04-1.05-0.22-1.56-0.37c-0.51,0.42-1.01-0.66-1.5,0.17c-0.49,0.22-0.96-0.28-1.43,0.05c-0.46-0.23-0.91-0.94-1.34-0.23
		c-0.87-0.81-1.68-1.83-2.41-0.16c-0.37-0.54-0.72-0.88-1.05-0.49c-0.33,1.33-0.65,0.28-0.94,0.36c-0.29,0.62-0.57-0.76-0.82,0.03
		c-0.25,0.54-0.48,0.41-0.69-0.13c-0.41-0.57-0.73,0.55-0.95-0.06c-0.22,0.18-0.33,1.26-0.33-0.02c0,0.67-0.04,1.69-0.12,0.13
		c-0.08-0.6-0.2,0.54-0.36,0.38c-0.16,0.25-0.35-0.49-0.58,0.61c-0.23,0.82-0.49-0.06-0.79,0.84c-0.15-0.63-0.31-0.27-0.47,0.55
		c-0.17-0.54-0.34-0.6-0.52,0.5c-0.18,1.86-0.37,0.58-0.57,0.81c-0.2,0.33-0.4-0.77-0.61,0.44c-0.21,0.19-0.43-0.95-0.66,0.8
		c-0.23-0.24-0.46,1.26-0.7,0.63c-0.24,1.05-0.49-0.16-0.74,0.71c-0.25,1.89-0.51,2.28-0.78,0.89c-0.27,0.81-0.54,1.17-0.81,0.74
		c-0.28-0.16-0.56-0.37-0.85,1.02c-0.29-0.39-0.58,1.28-0.89,0.91c-0.3,0.62-0.61,0.54-0.92,1c-0.55,0.85-1.12-0.01-1.7,1.8
		c-0.29,0.49-0.59,0.69-0.89,0.82c-0.3,1.27-0.6,0.63-0.91,1.08c-0.31,0.67-0.62,0.23-0.93,0.87c-0.31,0.49-0.63,0.12-0.95,1.12
		c-0.32,0-0.64-0.74-0.97,1.08c-0.33,1.71-0.65,1.77-0.99,0.98c-0.33,1.2-0.67,0.04-1,1.13c-0.34,0.3-0.68,1.84-1.02,1
		c-0.34,1.19-0.69,0.18-1.03,0.96c-0.35-1.4-0.69,1.13-1.04,1.23c-0.35,0.26-0.7,1.32-1.06,1.04c-0.35,1.93-0.71,0.92-1.07,1.21
		c-0.36,0.34-0.72,0.15-1.08,1.16c-0.36,0.65-0.72,0.91-1.09,1.12c-0.36,1.03-0.73,0.62-1.1,1.01c-0.37-0.39-0.73-0.06-1.1,1.31
		c-0.37,0.66-0.74,0.52-1.11,1.29c-0.37,0.98-0.74,2.35-1.11,1.05c-0.37,0.85-0.74,1.74-1.12,1.34c-0.37,1.57-0.75,1.18-1.12,1.02
		c-0.37-0.63-0.75,2.49-1.12,1.2c-0.37,1.22-0.75-0.32-1.12,1.17c-0.37-0.48-0.75,0.8-1.12,1.12c-0.37,0.61-0.75-0.3-1.12,1.25
		c-0.37-0.23-0.75-0.02-1.12,1.21c-0.37,0.01-0.74,0.37-1.12,1.15c-0.37-0.29-0.74-0.68-1.11,1.16c-0.37,1.02-0.74,1.1-1.11,1.18
		c-0.37,0.93-0.73,0.83-1.1,1.21c-0.36-0.2-0.73,0.21-1.09,1.1c-0.18,1.93-0.36,0.17-0.54,0.64c-0.18,1.6-0.36,1.51-0.54,0.45
		c-0.18-0.35-0.36,1.29-0.54,0.65c-0.18,0.71-0.36,0.1-0.54,0.53c-0.18,0.77-0.36,0.25-0.53,0.55c-0.18-0.38-0.35-0.14-0.53,0.44
		c-0.18,0.51-0.35,0.03-0.53,0.52c-0.18,0.07-0.35-0.11-0.52,0.72c-0.17,0.85-0.35-0.45-0.52,0.53c-0.17,0.9-0.35,0.94-0.52,0.56
		c-0.17-0.04-0.34,0.82-0.51,0.62c-0.17,0.31-0.34,1.13-0.51,0.47c-0.17,0.29-0.34,0.1-0.51,0.5c-0.17-0.03-0.34,0.77-0.5,0.41
		c-0.17,0.93-0.33,0.3-0.5,0.41c-0.17,0.15-0.33,1.09-0.49,0.8c-0.16,0.82-0.33-0.74-0.49,0.46c-0.16,0.35-0.32-0.38-0.49,0.44
		c-0.16,0.59-0.32-0.19-0.48,0.57c-0.16,0.34-0.32-0.18-0.48,0.57c-0.16,0.43-0.32,0.08-0.47,0.58c-0.16,0.31-0.31-0.66-0.47,0.49
		c-0.15-0.06-0.31-0.19-0.46,0.54c-0.15,0.09-0.31-0.07-0.46,0.34c-0.15-0.17-0.3-1.26-0.45,0.05c-0.15-0.26-0.3,0.81-0.45,0.67
		c-0.15-0.22-0.29-0.09-0.44,0.35c-0.15-1.03-0.29-0.26-0.43,0.8c-0.14,0.5-0.29-1.21-0.43,0.09c-0.14-0.18-0.28,0.72-0.42,0.81
		c-0.28,0.56-0.56,0.58-0.83,0.87c-0.21,0.41-0.42,0.73-0.63,1.05c-0.21-0.03-0.41,0.01-0.62,0.46c-0.2,1.16-0.4,0.48-0.6,0.57
		c-0.2-0.88-0.39-0.03-0.58,0.49c-0.38-0.51-0.75,1.38-1.11,1.27c-0.36,0.85-0.7,2-1.03,0.99c-0.33-0.67-0.65,0.4-0.95,0.9
		c-0.3,0.21-0.6,1.34-0.87,1.03c-0.28,1.24-0.54,1.72-0.78,0.85c-0.25,0.81-0.48,1.06-0.69,0.71c-0.22,0.34-0.41,0.9-0.6,0.27
		c-0.18,0.69-0.35,0.7-0.5,0.88c-0.3-0.08-0.53,0.24-0.68,0.72c-0.15,0.21-0.23,0.04-0.23,0.25c0,0.54,0.02,0.14,0.04-0.01
		c0.03,0.21,0.07-0.47,0.13-0.02c0.06,0.33,0.13-0.41,0.22-0.04c0.09-0.27,0.19-0.17,0.31-0.05c0.12-0.42,0.25,0.31,0.4-0.07
		c0.15-0.14,0.31,0.15,0.48-0.08c0.17,0.14,0.36-0.36,0.56-0.1c0.2-0.18,0.42,0.05,0.65-0.11c0.23,0.18,0.47-0.23,0.73-0.13
		c0.26-0.06,0.53-0.28,0.81-0.14c0.28-0.14,0.58-0.1,0.89-0.15c43.49-7.5,347.17-56.53,395.31-64.84c0.32-0.06,0.63-0.11,0.93-0.16
		c0.3-0.08,0.59,0.11,0.87-0.15c0.28-0.08,0.54-0.07,0.79-0.14c0.25-0.91,0.49-0.1,0.72-0.12c0.23,0.75,0.45,0.26,0.65-0.11
		c0.2,0.36,0.4,0.32,0.58-0.1c0.18,0.35,0.35-0.63,0.5-0.09c0.16-1.05,0.3-0.26,0.43-0.07c0.13,1.25,0.25,1,0.35-0.06
		c0.1,0.47,0.2,0.32,0.28-0.05c0.08,1.14,0.15-0.98,0.2-0.03c0.05-1.44,0.09,0.28,0.12-0.02
		C403.92,149.79,403.93,150.53,403.93,149.92z"/>
	<polyline class="st_fill_1" points="-1.46,216.87 67.41,145.16 117.3,148.42 212.22,37.77 309.3,37.77 401.93,150.05 	"/>
</g>
<g id="Terreno">
	<polyline class="st_fill_2" points="-8.07,218.05 405.42,218.05 405.42,148.71 -8.07,218.05 	"/>
</g>
<g id="Canaleta" class="st_fill_3">
	<g class="st_fill_4">
		<rect x="224.16" y="26.76" class="st_fill_5" width="6.44" height="3.7"/>
	</g>
	<g class="st_fill_4">
		<polygon class="st_fill_6" points="231.05,26.76 231.05,30.98 223.82,30.98 223.82,26.76 224.59,26.76 224.59,30.2 230.28,30.2 
			230.28,26.76 		"/>
	</g>
	<g class="st_fill_4">
		<rect x="292.37" y="26.76" class="st_fill_5" width="6.44" height="3.7"/>
	</g>
	<g class="st_fill_4">
		<polygon class="st_fill_6" points="299.2,26.76 299.2,30.98 291.97,30.98 291.97,26.76 292.74,26.76 292.74,30.2 298.43,30.2 
			298.43,26.76 		"/>
	</g>
</g>
<g id="Medidas">
	<text transform="matrix(1 0 0 1 25.2161 97.0225)" class="st_fill_7 st_fill_8">{{ paramSelected.values_params.earthwork.fill_height }}m</text>
	<text transform="matrix(1 0 0 1 82.9207 171.1367)" class="st_fill_9 st_fill_7 st_fill_8">{{ paramSelected.values_params.earthwork.fill_enbankment_width }}m</text>
	<text transform="matrix(1 0 0 1 353.158 64.0215)" class="st_fill_7 st_fill_8">{{ Math.round(
              (1 / paramSelected.values_params.earthwork.fill_factor) * 10
            ) / 10 }}</text>
	<text transform="matrix(1 0 0 1 371.3669 87.791)" class="st_fill_7 st_fill_8">1.5</text>
	<text transform="matrix(1 0 0 1 81.1589 134.8301)" class="st_fill_7 st_fill_8">{{ paramSelected.values_params.earthwork.fill_enbankment_slope }}%</text>
	<g>
		<path class="st_fill_10" d="M368.36,96.17V68.78l-21.15-0.05L368.36,96.17z"/>
	</g>
	<g>
		<rect x="53.12" y="149.19" width="6.69" height="0.46"/>
	</g>
	<g>
		<rect x="53.12" y="38.54" width="6.69" height="0.46"/>
	</g>
	<g>
		<g>
			<rect x="68.82" y="140.96" width="48.96" height="0.46"/>
		</g>
		<g>
			<g>
				<polygon points="116.86,141.97 117.64,141.19 116.86,140.4 117.52,140.4 118.3,141.19 117.52,141.97 				"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<line class="st_fill_11" x1="70.34" y1="152.48" x2="117.36" y2="152.55"/>
			<g>
				<polygon class="st_fill_9" points="71.34,153.33 70.49,152.48 71.34,151.63 70.62,151.63 69.77,152.48 70.62,153.33 				"/>
			</g>
			<g>
				<polygon class="st_fill_9" points="116.36,153.4 117.21,152.55 116.37,151.7 117.09,151.7 117.93,152.55 117.08,153.4 				"/>
			</g>
		</g>
	</g>
	<g>
		<g>
			<line class="st_fill_12" x1="56.43" y1="148.48" x2="56.43" y2="39.62"/>
			<g>
				<polygon points="57.28,147.48 56.43,148.33 55.58,147.48 55.58,148.2 56.43,149.05 57.28,148.2 				"/>
			</g>
			<g>
				<polygon points="57.28,40.62 56.43,39.77 55.58,40.62 55.58,39.9 56.43,39.05 57.28,39.9 				"/>
			</g>
		</g>
	</g>
	<g>
		<rect x="68.85" y="149.13" class="st_fill_9" width="0.46" height="6.69"/>
	</g>
	<g>
		<rect x="117.85" y="149.13" class="st_fill_9" width="0.46" height="6.69"/>
	</g>
</g>
<g id="Sub_x5F_base">
	<g>
		<polygon class="st_fill_13" points="307.22,34.43 260.49,33.51 214.08,34.43 216.64,30.04 260.49,29.12 304.67,30.04 		"/>
		<path d="M213.67,34.67l2.83-4.85l43.99-0.93l44.31,0.93l2.83,4.85l-47.14-0.93L213.67,34.67z M260.49,33.27l46.33,0.92l-2.28-3.92
			l-44.05-0.92l-43.72,0.92l-2.28,3.92L260.49,33.27z"/>
	</g>
	<g>
		<polygon class="st_fill_14" points="309.75,38.82 260.49,37.89 211.56,38.82 214.02,34.43 260.49,33.51 307.29,34.43 		"/>
		<path d="M211.16,39.05l2.72-4.85l0.13,0l46.48-0.92l46.93,0.93l2.73,4.85l-0.4-0.01l-49.26-0.92L211.16,39.05z M260.49,37.66
			l48.86,0.92l-2.2-3.92l-46.66-0.92l-46.33,0.92l-2.2,3.92L260.49,37.66z"/>
	</g>
</g>
<g id="Asphalt">
</g>
<g id="Layer_1">
</g>
<g id="Asphalt_x5F_and_components">
	<g>
		<polygon points="299.15,28.93 260.51,27.92 222.06,28.93 222.06,29.94 260.51,28.93 299.15,29.94 		"/>
	</g>
	<g>
		<polygon class="st_fill_15" points="304.64,29.78 299.15,29.78 299.15,28.86 303.72,28.86 303.72,27.47 304.64,27.47 		"/>
	</g>
	<g>
		<polygon class="st_fill_15" points="222.06,29.78 216.57,29.78 216.57,27.47 217.5,27.47 217.5,28.86 222.06,28.86 		"/>
	</g>
</g>
<g id="Jersey">
	<g>
		<g>
			<polygon class="st_fill_16" points="217.11,29.58 221.36,29.58 221.36,27.92 219.91,26.56 219.32,25.58 218.93,22.66 217.08,22.66 			
				"/>
			<path d="M221.56,29.78h-4.64l-0.03-7.31h2.22l0.41,3.05l0.55,0.92l1.5,1.4V29.78z M217.3,29.39h3.86v-1.38l-1.43-1.35l-0.61-1.01
				l-0.37-2.8h-1.49L217.3,29.39z"/>
		</g>
		<g>
			<polygon class="st_fill_16" points="304.19,29.58 299.94,29.58 299.94,27.92 301.4,26.56 301.98,25.58 302.37,22.66 304.23,22.66 			
				"/>
			<path d="M304.39,29.78h-4.64v-1.94l1.5-1.4l0.55-0.92l0.41-3.05h2.22L304.39,29.78z M300.14,29.39H304l0.03-6.53h-1.49l-0.37,2.8
				l-0.64,1.05l-1.4,1.31V29.39z"/>
		</g>
	</g>
</g>
<g id="Metal_x5F_guard" class="st_fill_3">
	<g class="st_fill_4">
		<g>
			<path class="st_fill_16" d="M216.82,25.78h2.65c0.14,0,0.24-0.11,0.24-0.24v-0.01c0-0.14-0.11-0.24-0.24-0.24h-2.65
				c-0.14,0-0.24,0.11-0.24,0.24v0.01C216.57,25.67,216.68,25.78,216.82,25.78z"/>
			<path d="M216.51,25.54c0-0.17,0.14-0.31,0.31-0.31h2.64c0.17,0,0.31,0.14,0.31,0.31c0,0.17-0.14,0.31-0.31,0.31h-2.64
				C216.65,25.85,216.51,25.71,216.51,25.54z M216.82,25.35c-0.1,0-0.19,0.08-0.19,0.19c0,0.1,0.08,0.19,0.19,0.19h2.64
				c0.1,0,0.19-0.08,0.19-0.19c0-0.1-0.08-0.19-0.19-0.19H216.82z"/>
		</g>
		<g>
			<polygon class="st_fill_16" points="219.13,25.54 220.29,24.22 219.13,22.73 217.98,22.73 219.13,24.22 217.98,25.54 219.13,27.19 
				217.98,28.51 219.13,28.51 220.31,27.19 			"/>
			<path d="M219.05,27.18l-1.16-1.65l1.15-1.32l-1.2-1.55h1.31l1.21,1.55l-1.16,1.32l1.18,1.65l-1.23,1.38h-1.32L219.05,27.18z
			M220.23,27.18l-1.18-1.65l1.15-1.32l-1.11-1.42h-1l1.11,1.43l-1.16,1.32l1.16,1.65l-1.1,1.26h0.99L220.23,27.18z"/>
		</g>
		<g>
			
				<rect x="217.07" y="22.65" transform="matrix(-1 -4.405244e-11 4.405244e-11 -1 434.96 52.3944)" class="st_fill_17" width="0.83" height="7.1"/>
			<path d="M217.01,22.58h0.95v7.23h-0.95V22.58z M217.83,22.71h-0.7v6.98h0.7V22.71z"/>
		</g>
	</g>
	<g class="st_fill_4">
		<g>
			<path class="st_fill_16" d="M304.67,25.78h-2.65c-0.14,0-0.24-0.11-0.24-0.24v-0.01c0-0.14,0.11-0.24,0.24-0.24h2.65
				c0.14,0,0.24,0.11,0.24,0.24v0.01C304.92,25.67,304.81,25.78,304.67,25.78z"/>
			<path d="M304.67,25.85h-2.64c-0.17,0-0.31-0.14-0.31-0.31c0-0.17,0.14-0.31,0.31-0.31h2.64c0.17,0,0.31,0.14,0.31,0.31
				C304.98,25.71,304.84,25.85,304.67,25.85z M302.03,25.35c-0.1,0-0.19,0.08-0.19,0.19c0,0.1,0.08,0.19,0.19,0.19h2.64
				c0.1,0,0.19-0.08,0.19-0.19c0-0.1-0.08-0.19-0.19-0.19H302.03z"/>
		</g>
		<g>
			<polygon class="st_fill_16" points="302.36,25.54 301.2,24.22 302.36,22.73 303.51,22.73 302.36,24.22 303.51,25.54 302.36,27.19 
				303.51,28.51 302.36,28.51 301.18,27.19 			"/>
			<path d="M303.65,28.57h-1.32l-1.23-1.38l1.18-1.65l-1.16-1.32l1.21-1.55h1.31l-1.2,1.55l1.15,1.32l-1.16,1.65L303.65,28.57z
			M302.39,28.45h0.99l-1.1-1.26l1.16-1.65l-1.16-1.32l1.11-1.43h-1l-1.11,1.42l1.15,1.32l-1.18,1.65L302.39,28.45z"/>
		</g>
		<g>
			<rect x="303.6" y="22.65" class="st_fill_17" width="0.83" height="7.1"/>
			<path d="M304.48,29.81h-0.95v-7.23h0.95V29.81z M303.66,29.69h0.7v-6.98h-0.7V29.69z"/>
		</g>
	</g>
</g>
</svg>

</template>
<script>
export default {
  name: "SvgEarthworkFill1LaneJersey",
  props: {
    paramSelected: undefined,
  },
};
</script>
<style scoped>
	.st_fill_0{fill:#8FCE57;stroke:#000000;stroke-width:0.25;stroke-miterlimit:10;}
	.st_fill_1{fill:#875116;stroke:#000000;stroke-width:0.75;stroke-miterlimit:10;}
	.st_fill_2{fill:#3E2D00;stroke:#000000;stroke-width:0.75;stroke-miterlimit:10;}
	.st_fill_3{display:none;}
	.st_fill_4{display:inline;}
	.st_fill_5{fill:#02151E;stroke:#8ADB37;stroke-miterlimit:10;}
	.st_fill_6{fill:#A07220;}
	.st_fill_7{font-family:'Roboto-Bold';}
	.st_fill_8{font-size:18px;}
	.st_fill_9{fill:#FFFFFF;}
	.st_fill_10{fill:none;stroke:#000000;stroke-miterlimit:10;}
	.st_fill_11{fill:none;stroke:#FFFFFF;stroke-width:0.5;stroke-miterlimit:10;}
	.st_fill_12{fill:none;stroke:#000000;stroke-width:0.5;stroke-miterlimit:10;}
	.st_fill_13{fill:#DD8F64;}
	.st_fill_14{fill:#7C503A;}
	.st_fill_15{fill:#7C7C7C;}
	.st_fill_16{fill:#686868;}
	.st_fill_17{fill:#72502F;}
</style>
